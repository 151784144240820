import styled from "@emotion/styled";
export const ApiDocContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;

  .api-doc-main-container{
    display:flex; 
    flex-direction: row; 
    justify-content: center; 
    // align-items: center;
    // margin:auto;
    // padding-left: 0.2rem;
    // padding-right: 0.2rem;
  }
  .api-doc-main-container > div {
    gap: 2rem;
    // margin:auto;

    padding-left: 0.2rem;
    padding-right: 0.2rem;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
  }
  .api-doc-left-menu-container {
    max-width: 330px;
    // min-width: 310px;
    align: left;
    text-align: left;
    position: left;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
    .dialog-size {
      width: 50%;
    }

    .api-doc-left-menu-opener-btn {
      background-color: #00a3ff;
      border-radius: 6px;
      border-style: solid;
      border-color: #00a3ff;
      border-width: thin;
      margin-top:0.2rem;
      margin-bottom:0.2rem;
      min-width: 162px;
    }  

    .api-doc-left-menu-item {
      border-radius: 6px;
      border-style: solid;
      border-color: #00a3ff;
      border-width: thin;
      padding: 0.25rem;
      width: 320px;
      .api-doc-left-menu-item-btn{
        background-color: rgba(214, 214, 214, 0.5 );
        max-width: 310px;
        width: 310px;
        text-align:left;
        color: #00a3ff;
        border-radius: 6px;
        border-style: solid;
        border-color: #fff;
        border-width: thin;
        // padding: 1rem;
        padding-top:1rem;
      }
    }
  }



  .api-doc-content-container {
    min-width: 250px !important ;
    max-width: 500px !important;
    width: 500px;
  }
  .api-doc-requests-container {
    min-width: 250px !important ;
    max-width: 370px !important;
    width: 370px !important;
  }



  .toSwaggerBtn {
    
    background-color: #382c7c;
    border-radius: 6px;
    border-style: solid;
    border-color: #382c7c;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;