import styled from "@emotion/styled";
export const ServicesContainer = styled.div`
  width: 100%;
  // max-width: 1600px !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align:left;
  /* margin-left: 3.75rem; */


  .description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
  }
  .service-card-container {
    display: grid;
    gap: 1rem;
    width: 100%;
    padding: 1.5rem;
    grid-template-columns: repeat(4, 1fr); 
}
  .role-dt{
    // border-radius: 1px;
    // border-style: solid;
    // border-color: #00a3ff;
  }

  @media (max-width: 1200px) {
    .service-card-container {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media (max-width: 900px) {
    .service-card-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 600px) {
    .service-card-container {
        grid-template-columns: repeat(1, 1fr); 
    }
}
`;
export const AllServicesContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  // gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align:left;



  .description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
  }
  .service-card-container {
    display: grid;
    gap: 1rem;
    width: 100%;
    padding: 1.5rem;
    grid-template-columns: repeat(4, 1fr); 
}
  .role-dt{
    // border-radius: 1px;
    // border-style: solid;
    // border-color: #00a3ff;
  }

  @media (max-width: 1200px) {
    .service-card-container {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media (max-width: 900px) {
    .service-card-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 600px) {
    .service-card-container {
        grid-template-columns: repeat(1, 1fr); 
    }
}
`;

export const CreateServicesDialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
    
    .description-textarea{
      resize:none;
      min-height: 120px;
    }
    .shortdescription-textarea{
      resize:none;
      min-height: 90px;
    }

    .input-container{
      width: 100%;
      margin-bottom: 1.5rem;
      .endpoint-button{
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        max-width: 25%;
        background-color: #00a3ff;
        border-radius: 6px;
        border-style: solid;
        border-color: #00a3ff;
        border-width: thin;
        &:hover{
          background-color: #f72685;
          border-radius: 6px;
          border-style: solid;
          border-color: #f72685;
          border-width: thin;
        }
      }
      .endpoint-button-wip{
        margin-left: 2rem;
        max-width: 25%;
        background-color: #00a3ff;
        border-radius: 6px;
        border-style: solid;
        border-color: #00a3ff;
        border-width: thin;
        &:hover{
          background-color: rgb(249, 163, 101);
          border-radius: 6px;
          border-style: solid;
          border-color: rgb(249, 163, 101);
          border-width: thin;
        }
      }
      .endpoint-container {
        width: 100%;
        margin: auto;

        .endpoint-container-item {
          display:flex; 
          flex-direction:row;
          .endpoint-item {
              display: flex;
              flex-flow: row;
              justify-content: center;
          }
        }

      }
      
      .platformColor{
        // color: #f72685;
        // color: #382c7c;
        color: #00a3ff;
        
      }
      
    }
      .submit-button{
        background-color: #00a3ff;
        border-radius: 6px;
        border-style: solid;
        border-color: #00a3ff;
        border-width: thin;
        &:hover{
          background-color: #f72685;
          border-radius: 6px;
          border-style: solid;
          border-color: #f72685;
          border-width: thin;
        }
      }

`;
export const UpdateEndpointContainer = styled.div`

  .endpoint-container-item {
    display:flex; 
    flex-direction:row;
    .endpoint-item {
        display: flex;
        flex-flow: row;
        justify-content: center;
    }
  }
`;


export const ViewServicesDialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
   
    .input-container{
      width: 100%;
      margin-bottom: 1.5rem;
      
      .platformColor{
        color: #00a3ff;
      }
      .view-container{
        color: #382c7c;
        width: 50%;
      }
      .chip-title{
        color: #00a3ff;
        font-size: 13px;
      }
      .chip-holder{
        margin-top: 1rem;
      }
      .platformColor-roles{
        color: #382c7c;
        margin-top: 1rem;
      }  
    }

`;