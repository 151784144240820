import { TOKEN_USER } from "../common/token-check";
import config from "../../config";


export default function headerMDC() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if(!config.cartifApiUrl.includes("https://")){
      return {...headers, "Upgrade-Insecure-Requests": 1}
    } else {
      return headers;
    }
  }