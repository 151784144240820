export const serviceTypesList = [
    {"serviceTypeName": "IFRAME", "serviceTypeCode" : 1},
    {"serviceTypeName": "API", "serviceTypeCode" : 2},
];
export const providerTypesList = [
    // {"providerTypeName" : "Dpo", "providerTypeCode": 1},
    {"providerTypeName" : "Dpp", "providerTypeCode": 2},
];
export const statusTypesList = [
    // {"statusTypeName": "PENDING", "statusTypeCode" : 1},
    {"statusTypeName": "ACTIVE", "statusTypeCode" : 2},
    {"statusTypeName": "DISABLED", "statusTypeCode" : 3},
];
export const testProductModels = [
    {"name": "a", "brandName" : "a", "description":"a", "ean":"", "imageUri" :"https://www.elityavru.com/images/irk-bilgileri/kopek-irki/pembroke-welsh-corgi.webp", "id" :"1"},
    {"name": "b", "brandName" : "b", "description":"b", "imageUri" :"https://i.ytimg.com/vi/6PQxJkLqc6c/sddefault.jpg","id" :"2"}

];
export const methodTypes = [
    {"name": "GET", "code" : 1},
    {"name": "POST", "code" : 2},
    {"name": "PUT", "code" : 3},
    {"name": "DELETE", "code": 4},
];

export const apiTextData = {
    login: {
        title: "loginTitle",
        shortDesc: "loginShortDesc",
        detailedDesc: "",
        authentication: "",
    },
    changePassword: {
        title: "changePasswordTitle",
        shortDesc: "changePasswordShortDesc",
        detailedDesc: "",
        authentication: "tokenInfo",
    },
    updateProductModel: {
        title: "updateProductModelTitle", 
        shortDesc: "updateProductModelShortDesc",
        detailedDesc: "",
        authentication: "tokenInfo",
    },
    addProductModel: {
        title: "addProductModelTitle", 
        shortDesc: "addProductModelShortDesc",
        detailedDesc: "",
        authentication: "tokenInfo",
    },
    getProductModelsByOrganisationId: {
        title: "getProductModelsByOrganisationIdTitle", 
        shortDesc: "getProductModelsByOrganisationIdShortDesc",
        detailedDesc: "",
        authentication: "tokenInfo",
    },
    getProductModelById: {
        title: "getProductModelByIdTitle", 
        shortDesc: "getProductModelByIdShortDesc",
        detailedDesc: "",
        authentication: "",
    },
    searchProductModel: {
        title: "searchProductModelTitle", 
        shortDesc: "searchProductModelShortDesc",
        detailedDesc: "",
        authentication: "",
    },
    getProductModelByCode: {
        title: "getProductModelByCodeTitle", 
        shortDesc: "getProductModelByCodeShortDesc",
        detailedDesc: "",
        authentication: "",
    },
    deleteProductModelById: {
        title: "deleteProductModelByIdTitle", 
        shortDesc: "deleteProductModelByIdShortDesc",
        detailedDesc: "",
        authentication: "tokenInfo",
    },
    getRegisteredServices: {
        title: "getRegisteredServicesTitle", 
        shortDesc: "getRegisteredServicesShortDesc",
        detailedDesc: "",
        authentication: "",
    },
    getRegisteredServiceById: {
        title: "getRegisteredServiceByIdTitle", 
        shortDesc: "getRegisteredServiceByIdShortDesc",
        detailedDesc: "",
        authentication: "",
    }
};


export const apiRequestCodeData = {
    login: {
        shell: `curl --request POST \\ \n  --url [DOMAIN]/api/auth/login \\\n  --header 'content-type: application/json' \\\n  --data '\n{\n  "username": "",\n  "password": "",\n }'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/auth/login"\n\npayload = {\n\t"username": "",\n\t"password": "",\n\t"captcha": ""\n}\nheaders = {"content-type": "application/json"}\nresponse = requests.post(url, json=payload, headers=headers)\n\nprint(response.json())`,
        go: `package main\nimport (\n\t"fmt"\n\t"strings"\n\t"net/http"\n\t"io"\n)\nfunc main() {\n\n\turl := "[DOMAIN]/api/auth/login"\n\n\tpayload := strings.NewReader("\n{\n  \"username\": \"\",\n  \"password\": \"\",\n  \"captcha\": \"\"\n}\n\n")\n\n\treq, _ := http.NewRequest("POST", url, payload)\n\n\treq.Header.Add("content-type", "application/json")\n\n\tres, _ := http.DefaultClient.Do(req)\n\n\tdefer res.Body.Close()\n\tbody, _ := io.ReadAll(res.Body)\n\n\tfmt.Println(res)\n\tfmt.Println(string(body))\n\n}`,
        rust :  `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("content-type", "application/json".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.post("[DOMAIN]/api/auth/login")\n\t\t.headers(headers)\n\t\t.body(r#"\n{\n\t"username": "",\n\t"password": "",\n\t"captcha": ""\n}\n"#\n\t\t)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,
        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpRequest.BodyPublishers;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/auth/login"))\n\t.POST(BodyPublishers.ofString("\n\t{\n\t\t"username\": \"\",\n\t\t"password\": \"\",\n\t\t"captcha\": \"\"\n\t}"))\n\t.setHeader("content-type", "application/json")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,
        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'POST',\n\turl: '[DOMAIN]/api/auth/login',\n\theaders: {'content-type': 'application/json'},\n\tdata: {username: '', password: '', captcha: ''}\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,
    },
    changePassword: {
        shell: `curl --request POST \\ \n  --url [DOMAIN]/api/auth/changePassword \\ \n  --header 'content-type: application/json' \\ \n  --data '\n{\n  "username": "",\n  "oldPassword": "",\n  "newPassword": "",\n}'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/auth/changePassword"\nheaders = {"content-type": "application/json"}\n\njson_data = {\n\t'username': '',\n\t'oldPassword': '',\n\t'newPassword': '',\n}\nresponse = requests.post(url, headers=headers, json=json_data)\n\nprint(response.json())`,
        go: `package main\nimport (\n\t"fmt"\n\t"strings"\n\t"net/http"\n\t"io"\n)\nfunc main() {\n\n\turl := "[DOMAIN]/api/auth/changePassword"\n\n\tpayload := strings.NewReader("\n{\n  \"username\": \"\",\n  \"oldPassword\": \"\",\n  \"newPassword\": \"\"\n}\n\n")\n\n\treq, _ := http.NewRequest("POST", url, payload)\n\treq.Header.Add("content-type", "application/json")\n\n\tres, _ := http.DefaultClient.Do(req)\n\n\tdefer res.Body.Close()\n\tbody, _ := io.ReadAll(res.Body)\n\n\tfmt.Println(res)\n\tfmt.Println(string(body))\n\n}`,
        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("content-type", "application/json".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\t\tlet res = client.post("[DOMAIN]/api/auth/changePassword")\n\t\t.headers(headers)\n\t\t.body(r#"\n{\n\t"username": "",\n\t"oldPassword": "",\n\t"newPassword": ""\n}\n"#\n\t\t)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,
        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpRequest.BodyPublishers;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/auth/changePassword"))\n\t.POST(BodyPublishers.ofString("\n\t{\n\t\t"username\": \"\",\n\t\t"oldPassword\": \"\",\n\t\t"newPassword\": \"\"\n\t}"))\n\t.setHeader("content-type", "application/json")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,
        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'POST',\n\turl: '[DOMAIN]/api/auth/changePassword',\n\theaders: {'content-type': 'application/json'},\n\tdata: {username: '', oldPassword: '', newPassword: ''}\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,
    },
    updateProductModel: {
        shell:`curl --request PUT \\ \n  --url [DOMAIN]/api/sys/updateProductModel \\ \n  --header 'Authorization: Bearer [TOKEN]' \\ \n  --header 'content-type: application/json' \\ \n  --data '{\n  "productModelDTO": {\n\t"name": "",\n\t"gtin": "",\n\t"brandName": "",\n\t"description": "",\n\t"imageUri": "",\n\t"organisation": {\n\t  "id": ""\n\t  }\n\t}\n}'`,
        python:`import requests\n\nurl = '[DOMAIN]/api/sys/updateProductModel'\nheaders = {\n\t'Authorization': 'Bearer [TOKEN]',\n\t'content-type': 'application/json',\n}\n\njson_data = {\n\t'productModelDTO': {\n\t\t'name': '',\n\t\t'gtin': '',\n\t\t'brandName': '',\n\t\t'description': '',\n\t\t'imageUri': '',\n\t\t'organisation': {\n\t\t\t'id': '',\n\t\t\t},\n\t\t},\n}\nresponse = requests.put(url, headers=headers, json=json_data)\n\nprint(response.json())`,
        go:`package main\nimport (\n\t"fmt"\n\t"strings"\n\t"net/http"\n\t"io"\n)\nfunc main() {\n\n\turl := "[DOMAIN]/api/sys/updateProductModel"\n\n\tpayload := strings.NewReader("{\n  \"productModelDTO\": {\n    \"name\": \"\",\n    \"gtin\": \"\",\n    \"brandName\": \"\",\n    \"description\": \"\",\n    \"imageUri\": \"\",\n    \"organisation\": {\n      \"id\": \n    }\n  }\n}")\n\n\treq, _ := http.NewRequest("PUT", url, payload)\n\n\treq.Header.Add("Authorization", "Bearer [TOKEN]")\n\treq.Header.Add("content-type", "application/json")\n\n\tres, _ := http.DefaultClient.Do(req)\n\n\tdefer res.Body.Close()\n\tbody, _ := io.ReadAll(res.Body)\n\n\tfmt.Println(res)\n\tfmt.Println(string(body))\n\n}`,
        rust:`extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("Authorization", "Bearer [TOKEN]".parse().unwrap());\n\theaders.insert("content-type", "application/json".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.put("[DOMAIN]/api/sys/updateProductModel")\n\t\t.headers(headers)\n\t\t.body(r#"\n\t\t{\n"productModelDTO": {\n\t"name": "",\n\t"gtin": "",\n\t"brandName": "",\n\t"description": "",\n\t"imageUri": "",\n\t"organisation": {\n\t\t"id": ""\n\t\t\t}\n\t}\n}\n"#\n\t\t)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,
        
        java:`import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpRequest.BodyPublishers;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/sys/updateProductModel"))\n\t.PUT(BodyPublishers.ofString("{\n  \t\t"productModelDTO\": {\n\t\t\t"name\": \"\",\n\t\t\t"gtin\": \"\",\n\t\t\t"brandName\": \"\",\n\t\t\t"description\": \"\",\n\t\t\t"imageUri\": \"\",\n\t\t\t"organisation\": {"id\": \"\" }\n \t\t }\n\t}"))\n\t.setHeader("Authorization", "Bearer [TOKEN]")\n\t.setHeader("content-type", "application/json")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'PUT',\n\turl: '[DOMAIN]/api/sys/updateProductModel',\n\theaders: {\n\t\tAuthorization: 'Bearer [TOKEN]',\n\t\t'content-type': 'application/json',\n\t},\n\tdata: {\n\t\tproductModelDTO : {\n\t\t\tname: '',\n\t\t\tgtin: '',\n\t\t\tbrandName: '',\n\t\t\tdescription: '',\n\t\t\torganisation: {id : ''},\n\t\t}}\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,

    },
    addProductModel: {
        shell:`curl --request POST \\ \n  --url [DOMAIN]/api/sys/addProductModel \\ \n  --header 'Authorization: Bearer [TOKEN]' \\ \n  --header 'content-type: application/json' \\ \n  --data '{\n  "productModelDTO": {\n\t"name": "",\n\t"gtin": "",\n\t"brandName": "",\n\t"description": "",\n\t"imageUri": "",\n\t"organisation": {\n\t  "id": ""\n\t  }\n\t}\n}'`,
        python:`import requests\n\nurl = '[DOMAIN]/api/sys/addProductModel'\nheaders = {\n\t'Authorization': 'Bearer [TOKEN]',\n\t'content-type': 'application/json',\n}\n\njson_data = {\n\t'productModelDTO': {\n\t\t'name': '',\n\t\t'gtin': '',\n\t\t'brandName': '',\n\t\t'description': '',\n\t\t'imageUri': '',\n\t\t'organisation': {\n\t\t\t'id': '',\n\t\t\t},\n\t\t},\n}\nresponse = requests.post(url, headers=headers, json=json_data)\n\nprint(response.json())`,
       
        go:`package main\nimport (\n\t"fmt"\n\t"strings"\n\t"net/http"\n\t"io"\n)\nfunc main() {\n\n\turl := "[DOMAIN]/api/sys/addProductModel"\n\n\tpayload := strings.NewReader("{\n  \"productModelDTO\": {\n    \"name\": \"\",\n    \"gtin\": \"\",\n    \"brandName\": \"\",\n    \"description\": \"\",\n    \"imageUri\": \"\",\n    \"organisation\": {\n      \"id\": \n    }\n  }\n}")\n\n\treq, _ := http.NewRequest("POST", url, payload)\n\n\treq.Header.Add("Authorization", "Bearer [TOKEN]")\n\treq.Header.Add("content-type", "application/json")\n\n\tres, _ := http.DefaultClient.Do(req)\n\n\tdefer res.Body.Close()\n\tbody, _ := io.ReadAll(res.Body)\n\n\tfmt.Println(res)\n\tfmt.Println(string(body))\n\n}`,
        rust:`extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("Authorization", "Bearer [TOKEN]".parse().unwrap());\n\theaders.insert("content-type", "application/json".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.post("[DOMAIN]/api/sys/addProductModel")\n\t\t.headers(headers)\n\t\t.body(r#"\n\t\t{\n"productModelDTO": {\n\t"name": "",\n\t"gtin": "",\n\t"brandName": "",\n\t"description": "",\n\t"imageUri": "",\n\t"organisation": {\n\t\t"id": ""\n\t\t\t}\n\t}\n}\n"#\n\t\t)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,
        
        java:`import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpRequest.BodyPublishers;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/sys/addProductModel"))\n\t.POST(BodyPublishers.ofString("{\n  \t\t"productModelDTO\": {\n\t\t\t"name\": \"\",\n\t\t\t"gtin\": \"\",\n\t\t\t"brandName\": \"\",\n\t\t\t"description\": \"\",\n\t\t\t"imageUri\": \"\",\n\t\t\t"organisation\": {"id\": \"\" }\n \t\t }\n\t}"))\n\t.setHeader("Authorization", "Bearer [TOKEN]")\n\t.setHeader("content-type", "application/json")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,
        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'POST',\n\turl: '[DOMAIN]/api/sys/addProductModel',\n\theaders: {\n\t\tAuthorization: 'Bearer [TOKEN]',\n\t\t'content-type': 'application/json',\n\t},\n\tdata: {\n\t\tproductModelDTO : {\n\t\t\tname: '',\n\t\t\tgtin: '',\n\t\t\tbrandName: '',\n\t\t\tdescription: '',\n\t\t\torganisation: {id : ''},\n\t\t}}\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,


    },
    getProductModelsByOrganisationId: {
        shell: `curl --request GET \\ \n  --url '[DOMAIN]/api/sys/getProductModelsByOrganisationId?id=' \\ \n  --header 'Authorization: Bearer [TOKEN]' \\ \n  --header 'content-type: application/json'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/sys/getProductModelsByOrganisationId"\nheaders = {\n\t'Authorization': 'Bearer [TOKEN]',\n\t'content-type': 'application/json',\n}\n\nparams = {\n\t'id': '',\n}\nresponse = requests.get(url, headers=headers, params=params)\n\nprint(response.json())`,

        go: `package main\nimport (\n\t"fmt"\n\t"strings"\n\t"net/http"\n\t"io"\n)\nfunc main() {\n\n\turl := "[DOMAIN]/api/sys/getProductModelsByOrganisationId?id="\n\n\treq, _ := http.NewRequest("GET", url, nil)\n\n\treq.Header.Add("Authorization", "Bearer [TOKEN]")\n\treq.Header.Add("content-type", "application/json")\n\n\tres, _ := http.DefaultClient.Do(req)\n\n\tdefer res.Body.Close()\n\tbody, _ := io.ReadAll(res.Body)\n\n\tfmt.Println(res)\n\tfmt.Println(string(body))\n}`,

        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("Authorization", "Bearer [TOKEN]".parse().unwrap());\n\theaders.insert("content-type", "application/json".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.get("[DOMAIN]/api/sys/getProductModelsByOrganisationId?id=")\n\t\t.headers(headers)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,

        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/sys/getProductModelsByOrganisationId?id="))\n\t.GET()\n\t.setHeader("Authorization", "Bearer [TOKEN]")\n\t.setHeader("content-type", "application/json")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'GET',\n\turl: '[DOMAIN]/api/sys/getProductModelsByOrganisationId',\n\tparams:{id : '',}\n\theaders: {\n\t\tAuthorization: 'Bearer [TOKEN]',\n\t\t'content-type': 'application/json',\n\t},\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,

    },
    getProductModelById: {
        shell: `curl --request GET \\ \n  --url '[DOMAIN]/api/sys/getProductModelById?id=' \\ \n  --header 'Authorization: Bearer [TOKEN]' \\ \n  --header 'content-type: application/json'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/sys/getProductModelById"\nheaders = {\n\t'Authorization': 'Bearer [TOKEN]',\n\t'content-type': 'application/json',\n}\n\nparams = {\n\t'id': '',\n}\nresponse = requests.get(url, headers=headers, params=params)\n\nprint(response.json())`,

        go: `package main\nimport (\n\t"fmt"\n\t"io"\n\t"net/http"\n\t"log"\n)\nfunc main() {\n\n\tclient := &http.Client{}\n\treq, err := http.NewRequest("GET", "[DOMAIN]/api/sys/getProductModelById?id=", nil)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\treq.Header.Set("Authorization", "Bearer [TOKEN]")\n\treq.Header.Set("content-type", "application/json")\n\tresp, err := client.Do(req)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tdefer resp.Body.Close()\n\tbodyText, err := io.ReadAll(resp.Body)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tfmt.Printf("%s", bodyText)\n}`,

        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("Authorization", "Bearer [TOKEN]".parse().unwrap());\n\theaders.insert("content-type", "application/json".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.get("[DOMAIN]/api/sys/getProductModelById?id=")\n\t\t.headers(headers)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,

        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/sys/getProductModelById?id="))\n\t.GET()\n\t.setHeader("Authorization", "Bearer [TOKEN]")\n\t.setHeader("content-type", "application/json")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'GET',\n\turl: '[DOMAIN]/api/sys/getProductModelById',\n\tparams:{id : '',}\n\theaders: {\n\t\tAuthorization: 'Bearer [TOKEN]',\n\t\t'content-type': 'application/json',\n\t},\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,

    },
    searchProductModel: {
        shell: `curl -X 'GET' \\ \n  '[DOMAIN]/api/pub/searchProductModel?keyword=' \\ \n  --header 'accept: */*'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/pub/searchProductModel"\nheaders = {\n\t'accept': '*/*',\n}\n\nparams = {\n\t'keyword': '',\n}\nresponse = requests.get(url, headers=headers, params=params)\n\nprint(response.json())`,

        go: `package main\nimport (\n\t"fmt"\n\t"io"\n\t"net/http"\n\t"log"\n)\nfunc main() {\n\n\tclient := &http.Client{}\n\treq, err := http.NewRequest("GET", "[DOMAIN]/api/pub/searchProductModel?keyword=", nil)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\treq.Header.Set("accept", "*/*")\n\tresp, err := client.Do(req)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tdefer resp.Body.Close()\n\tbodyText, err := io.ReadAll(resp.Body)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tfmt.Printf("%s", bodyText)\n}`,

        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("accept", "*/*".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.get("[DOMAIN]/api/pub/searchProductModel?keyword=")\n\t\t.headers(headers)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,

        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/pub/searchProductModel?keyword="))\n\t.GET()\n\t.setHeader("accept", "*/*")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'GET',\n\turl: '[DOMAIN]/api/pub/searchProductModel',\n\tparams:{keyword : '',}\n\theaders: { 'content-type': 'application/json',},\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,

    },
    getProductModelByCode: {
        shell: `curl -X 'GET' \\ \n  --url '[DOMAIN]/api/pub/getProductModelByCode?code=' \\ \n  --header 'accept: */*'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/pub/getProductModelByCode"\nheaders = {\n\t'Authorization': 'Bearer [TOKEN]',\n\t'content-type': 'application/json',\n}\n\nparams = {\n\t'code': '',\n}\nresponse = requests.get(url, headers=headers, params=params)\n\nprint(response.json())`,

        go: `package main\nimport (\n\t"fmt"\n\t"io"\n\t"net/http"\n\t"log"\n)\nfunc main() {\n\n\tclient := &http.Client{}\n\treq, err := http.NewRequest("GET", "[DOMAIN]/api/pub/getProductModelByCode?code=", nil)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\treq.Header.Set("accept", "*/*")\n\tresp, err := client.Do(req)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tdefer resp.Body.Close()\n\tbodyText, err := io.ReadAll(resp.Body)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tfmt.Printf("%s", bodyText)\n}`,

        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("accept", "*/*".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.get("[DOMAIN]/api/pub/getProductModelByCode?code=")\n\t\t.headers(headers)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,

        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/pub/getProductModelByCode?code="))\n\t.GET()\n\t.setHeader("accept", "*/*")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'GET',\n\turl: '[DOMAIN]/api/pub/getProductModelByCode',\n\tparams:{keyword : '', code: ''}\n\theaders: { 'content-type': 'application/json',},\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,

    },
    deleteProductModelById: {
        shell: `curl --request DELETE \\ \n  --url '[DOMAIN]/api/sys/deleteProductModelById?id=' \\ \n  --header 'Authorization: Bearer [TOKEN]'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/sys/deleteProductModelById"\nheaders = {\n\t'Authorization': 'Bearer [TOKEN]',\n\t'content-type': 'application/json',\n}\n\nparams = {\n\t'id': '',\n}\nresponse = requests.delete(url, headers=headers, params=params)\n\nprint(response.json())`,

        go: `package main\n\nimport (\n\t"fmt"\n\t"io"\n\t"log"\n\t"net/http"\n)\n\nfunc main() {\n\tclient := &http.Client{}\n\treq, err := http.NewRequest("DELETE", "[DOMAIN]/api/sys/deleteProductModelById?id=", nil)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\treq.Header.Set("Authorization", "Bearer [TOKEN]")\n\tresp, err := client.Do(req)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tdefer resp.Body.Close()\n\tbodyText, err := io.ReadAll(resp.Body)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tfmt.Printf("%s", bodyText)\n}`,
        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("Authorization", "Bearer [TOKEN]".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.delete("[DOMAIN]/api/sys/deleteProductModelById?id=")\n\t\t.headers(headers)\n\t\t.send()?\n\t\t.text()?;\n\tprintln!("{}", res);\n\n\tOk(())\n}`,
        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/sys/deleteProductModelById?id="))\n\t.DELETE()\n\t.setHeader("Authorization", "Bearer [TOKEN]")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'DELETE',\n\turl: '[DOMAIN]/api/sys/deleteProductModelById',\n\tparams:{id : '',}\n\theaders: {\n\t\tAuthorization: 'Bearer [TOKEN]',\n\t\t'content-type': 'application/json',\n\t},\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,

    },
    getRegisteredServices: {
        shell: `curl --request GET \\ \n  --url [DOMAIN]/api/pub/getRegisteredServices \\ \n  --header 'accept: */*`,
        python: `import requests\n\nurl = "[DOMAIN]/api/pub/getRegisteredServices"\nheaders = {\t'accept': '*/*',}\nresponse = requests.get(url, headers=headers)\n\nprint(response.json())`,

        go: `package main\n\nimport (\n\t"fmt"\n\t"io"\n\t"log"\n\t"net/http"\n)\n\nfunc main() {\n\n\tclient := &http.Client{}\n\treq, err := http.NewRequest("GET", "[DOMAIN]/api/pub/getRegisteredServices", nil)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\treq.Header.Set("accept", "*/*")\n\tresp, err := client.Do(req)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tdefer resp.Body.Close()\n\tbodyText, err := io.ReadAll(resp.Body)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tfmt.Printf("%s", bodyText)\n}`,

        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("accept", "*/*".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.get("[DOMAIN]/api/pub/getRegisteredServices")\n\t\t.headers(headers)\n\t\t.send()?\n\t\t.text()?;\n\n\tprintln!("{}", res);\n\n\tOk(())\n\n}`,

        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/pub/getRegisteredServices"))\n\t.GET()\n\t.setHeader("accept", "*/*")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'GET',\n\turl: '[DOMAIN]/api/pub/getRegisteredServices',\n\theaders: { 'content-type': 'application/json',},\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,

    },
    getRegisteredServiceById: {
        shell: `curl --request GET \\ \n  --url '[DOMAIN]/api/pub/getRegisteredServiceById?id=' \\ \n  --header 'accept: */*'`,
        python: `import requests\n\nurl = "[DOMAIN]/api/pub/getRegisteredServiceById"\nheaders = {\t'accept': '*/*',}\nparams = {\t'id': '',}\nresponse = requests.get(url, headers=headers,params=params)\n\nprint(response.json())`,

        go: `package main\n\nimport (\n\t"fmt"\n\t"io"\n\t"log"\n\t"net/http"\n)\n\nfunc main() {\n\n\tclient := &http.Client{}\n\treq, err := http.NewRequest("GET", "[DOMAIN]/api/pub/getRegisteredServiceById?id=", nil)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\treq.Header.Set("accept", "*/*")\n\tresp, err := client.Do(req)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tdefer resp.Body.Close()\n\tbodyText, err := io.ReadAll(resp.Body)\n\tif err != nil {\n\t\tlog.Fatal(err)\n\t}\n\tfmt.Printf("%s", bodyText)\n}`,
        rust : `extern crate reqwest;\nuse reqwest::header;\n\nfn main() -> Result<(), Box<dyn std::error::Error>> {\n\n\tlet mut headers = header::HeaderMap::new();\n\theaders.insert("accept", "*/*".parse().unwrap());\n\n\tlet client = reqwest::blocking::Client::builder()\n\t\t.redirect(reqwest::redirect::Policy::none())\n\t\t.build()\n\t\t.unwrap();\n\n\tlet res = client.get("[DOMAIN]/api/pub/getRegisteredServiceById?id=")\n\t\t.headers(headers)\n\t\t.send()?\n\t\t.text()?;\n\n\tprintln!("{}", res);\n\n\tOk(())\n\n}`,
        java: `import java.io.IOException;\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\nHttpClient client = HttpClient.newHttpClient();\n\nHttpRequest request = HttpRequest.newBuilder()\n\t.uri(URI.create("[DOMAIN]/api/pub/getRegisteredServiceById?id="))\n\t.GET()\n\t.setHeader("accept", "*/*")\n\t.build();\n\nHttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());`,

        js : `import axios from 'axios';\nconst options = {\n\tmethod: 'GET',\n\turl: '[DOMAIN]/api/pub/getRegisteredServicesById',\n\tparams:{id : '',}\n\theaders: { 'content-type': 'application/json',},\n};\ntry {\n\tconst { data } = await axios.request(options);\n\tconsole.log(data);\n} catch (error) {\n\tconsole.error(error);\n}`,
    
    }
};