import { ProductModelFieldContainer } from "./assets/ProductModelFieldContainer.style";
import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Slide, ToastContainer, toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import GeneralFunctions from "../functionalities/common/generalFunctions";

const ProductModelField = ({
  t,
  selectedProductModel,
  selectedKeys,
  selectedValues,
}) => {
  const [selectedKey, setSelectedKey] = useState("");
  
  const handleKeys = () => {
    const _fKeys = [];
    selectedKeys.forEach((k,i) => {
      if(!(k === "id" || k === "mdcUrl" || k === "mdcField" || k === "mdcDescription")){
        
        _fKeys.push(k);
      }
    })
    return _fKeys;
  }

  return (
    <ProductModelFieldContainer>
      {selectedKeys.length > 0 &&
        selectedProductModel !== undefined &&
        selectedProductModel !== null &&
        selectedProductModel !== "" && (
          <div>
            <Dropdown
              className="selected-pm-data"
              options={handleKeys()}
              value={selectedKey}
              onChange={(e) => {
                setSelectedKey(e.target.value);
              }}
              optionLabel=""
            />
            <br />
            <div className="selected-pm-info">
              <InputText
                id={"key_"}
                name={"key_"}
                type="text"
                className="p-inputtext-sm w-full selected-pm-info-data"
                value={selectedProductModel[selectedKey]}
                disabled
              />
              <Button
                className="selected-pm-info-data-btn"
                icon="pi pi-copy"
                tooltip={t("copyToClipboard")}
                onClick={() => {
                  if (GeneralFunctions.copyToClipboard(selectedProductModel[selectedKey])) {
                    toast.info(<Trans i18nKey={"infoCopiedToClipboard"} />);
                  } else {
                    toast.error(<Trans i18nKey={"failCopiedToClipboard"} />);
                  }
                }}
              />
            </div>
          </div>
        )}

      <ToastContainer
        autoClose={1000}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ProductModelFieldContainer>
  );
};

export default withTranslation()(ProductModelField);
