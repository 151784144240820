import styled from "@emotion/styled";
export const VerifiableCredentialsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align: left;
`;

export const CreateVerifiableCredentialDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 0 1rem;

  .input-container {
    width: 100%;
    margin-bottom: 1.5rem;

    .platformColor {
      color: #00a3ff;
    }
  }
  .submit-button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;
export const ViewVerifiableCredentialDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  padding: 0 1rem 0 1rem;

  .input-container {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0rem;

    .platformColor {
      color: #00a3ff;
    }
    .platformColor-roles {
      color: #f72685;
    }
    .chip-title {
      color: #00a3ff;
      font-size: 12px;
    }
    .roles-div {
      margin-right: 1rem;
    }
  }
`;
export const EditVerifiableCredentialDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  padding: 0 1rem 0 1rem;

  .platformColor-lbl {
    color: #f72685;
  }

  .input-container {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0rem;

    .platformColor {
      color: #00a3ff;
    }
    .platformColor-roles {
      color: #f72685;
    }
  }

  .submit-button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }

  .p-datascroller-list > li {
    border: none !important;
  }

  .data-scrollItem-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #f4f4f4;
    border-radius: 6px;

    .left-part {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
    }
  }
`;