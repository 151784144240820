import styled from "@emotion/styled";
export const MDCDialogContainer = styled.div`


.button-wip{
        margin-left: 2rem;
        max-width: 25%;
        background-color: #00a3ff;
        border-radius: 6px;
        border-style: solid;
        border-color: #00a3ff;
        border-width: thin;
        &:hover{
          background-color: rgb(249, 163, 101);
          border-radius: 6px;
          border-style: solid;
          border-color: rgb(249, 163, 101);
          border-width: thin;
        }
      }
`;