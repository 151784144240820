import React, {useState, useEffect} from "react";
import {
  isCTAdmin,
  isCTManufacturer,
} from "../functionalities/common/token-check";
import SubMenu from "./SubMenu";
import ErionIcon from "./ErionIcon";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as CgIcons from "react-icons/cg";
import * as Fa6Icons from "react-icons/fa6";
import { IoDocumentTextOutline } from "react-icons/io5";
import { TbCircleLetterE } from "react-icons/tb";
import { MdOutlineEmojiTransportation } from "react-icons/md";

import { MdOutlineDocumentScanner } from "react-icons/md";
import APIService from "../functionalities/services/api.service";
import config from "../config";

const LeftBar = () => {

  const SidebarDataIcons = [
    {
      visible: isCTAdmin() || !isCTAdmin(),
      path: "/landing",
      icon: <AiIcons.AiFillHome />,
    },
    {
      visible: isCTAdmin(),
      path: "/allOrganisations",
      icon: <CgIcons.CgOrganisation />,
    },
    {
      visible: isCTAdmin(),
      path: "/manageRoles",
      icon: <Fa6Icons.FaLockOpen />,
    },
    {
      visible: !isCTAdmin(),
      path: "/allServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      path: "/manageServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      path: "/allProviders",
      icon: <IoIcons.IoIosCloudDownload />,
    },
    {
      visible: !isCTAdmin() && isCTManufacturer(),
      path: "/allProductModels",
      icon: <IoIcons.IoIosBarcode />,
    },
    {
      visible: !isCTAdmin(),
      path: "/erion-service",
      icon: <ErionIcon  />,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      path: "/editProfile",
      icon: <Fa6Icons.FaUserGear />,
    },
    {
      visible: true,
      path: "/tutorials",
      icon: <IoDocumentTextOutline />,
    },
    {
      visible: true,
      path: "/documentation",
      icon: <MdOutlineDocumentScanner /> ,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      path: "/",
      isLogout: true,
      icon: <IoIcons.IoIosLogOut style={{ transform: "rotate(180deg)" }} />,
    },
  ];
  return (
    <div
      style={{
        backgroundColor: "#382c7c",
        width: "60px",
        minHeight: "calc(100vh - 178px)",
        color: "#fff",
      }}
    >
      {SidebarDataIcons.map((item, index) => {
        return <SubMenu item={item} key={index} />;
      })}
    </div>
  );
};

export default LeftBar;
