import React, { useEffect, useRef, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { apiTextData } from "../data/ct-data";
import { ApiPageContentContainer } from "./assets/ApiPageContentContainer.style";

const ApiPageContent = ({ t, menuData , parameterData, requestBodyData }) => {
  const [relatedData, setRelatedData] = useState({});
  const [rqbKeys, setRqbKeys] = useState([]);
  const [rqbProps, setRqbProps] = useState({});
  

  const handleAuthText = () => {
    const _tempTxt = t(relatedData?.authentication);
    if(_tempTxt !== undefined && _tempTxt !== null ){
      const _index =_tempTxt.indexOf("Bearer")
      const _index_last = _tempTxt.indexOf(">")
      const _fTxt = _tempTxt.slice(0,_index)
      const _lTxt = _tempTxt.slice(_index_last+1);
      return (
        <p> {_fTxt}
          <span style={{backgroundColor: " rgba(214, 214, 214,  0.5)",fontFamily:"monospace"}}>{_tempTxt.slice(_index,_index_last+1)}</span>
          {_lTxt}
        </p>
      );
    }

  }
  // console.log(requestBodyData)
  // console.log(parameterData)

  const handleRequiredCheck = (data) => {
    if (data) {
      return (
        <Chip
          label={t("yes")}
          className="required-check-chip"
          style={{
            backgroundColor: "#49cc90",
            marginRight: "0.7rem",
            marginBottom: "0.7rem",
            fontSize: "14px",
            color: "#fff",
          }}
        />
      );
    } else {
      return (
        <Chip
          label={t("no")}
          className="required-check-chip"
          style={{
            backgroundColor: "#ff2c2c",
            marginRight: "0.7rem",
            marginBottom: "0.7rem",
            fontSize: "14px",
            color: "#fff",
          }}
        />
      );
    }
  };

  useEffect(()=>{
    setRelatedData(apiTextData[menuData.slice(menuData.indexOf('/')+1)])
    if(requestBodyData !== undefined && requestBodyData !== null){
      setRqbProps(requestBodyData?.properties);
      setRqbKeys(Object.keys(requestBodyData?.properties));
    }
    },[menuData]);
  return (
    <>
      {menuData !== undefined && menuData !== null && menuData !== "" && (
        <ApiPageContentContainer>
          <h2 className="api-content-api-title">{t(relatedData?.title)}</h2>
          <div className="api-content-api-shortDesc">
            {t(relatedData?.shortDesc)}
          </div>

          {relatedData?.authentication !== "" && (
            <div className="api-content-auth-container">
              <h2 className="api-content-auth">{t("authenticationTitle")}</h2>
              <div>{t("authNeedInfo")}</div>
              <h3 className="api-content-auth">
                {t("authenticationAccessTokenTitle")}
              </h3>

              <div className="api-content-auth-content">
                {/* <p>{t(relatedData?.authentication)}</p>  */}
                {handleAuthText()}
              </div>
              <div className="api-content-auth-content-warning">
                <i
                  class="pi pi-exclamation-triangle"
                  style={{ color: "#FF5F15" }}
                >
                  {t("tokenWarningTitle")}
                </i>
                <p>{t("tokenWarning")}</p>
              </div>
            </div>
          )}
          <div className="api-content-api-detailedDesc">
            {parameterData !== undefined && parameterData !== null && (
              <div className="api-content-parameters">
                <h2 className="api-content-parameters-title">
                  {t("parameterTitle")}
                </h2>
                <p>
                  <span className="api-content-parameter-span">
                    {t("parameter")}
                    {" : "}
                  </span>
                  {parameterData[0]?.name}
                  {" ("}
                  {parameterData[0]?.schema?.type}
                  {")"}
                </p>
                <p>
                  <span className="api-content-parameter-span">
                    {t("requiredCheck")}
                    {" : "}
                  </span>
                  {handleRequiredCheck(parameterData[0]?.required)}
                </p>
              </div>
            )}
            {requestBodyData !== undefined &&
              requestBodyData !== null &&
              Object.keys(requestBodyData).length !== 0 && (
                <div className="api-content-request-body">
                  <h2 className="api-content-request-body-title">
                    {t("requestBodyTitle")}
                  </h2>
                  {rqbKeys.length > 0 &&
                    rqbKeys.map((e) => (
                      <div>
                        <p className="api-content-request-body-key">
                          <strong>{e}</strong>
                          {" ("}
                          {rqbProps[e]["type"]}
                          {")"}
                        </p>
                      </div>
                    ))}
                </div>
              )}
          </div>
        </ApiPageContentContainer>
      )}
    </>
  );
};

export default withTranslation()(ApiPageContent);
