import apiosMDC from "../common/apiosMDC";
import authHeader from "./auth-header";
import headerMDC from "./auth-header-mdc";


class MdcService {

    //Catalogue functions
    getCatalogueHeader(accessToken,p_idCatalogo) {
        return apiosMDC.get("/getCatalogueHeader",{
            params: {
                accessToken: accessToken,
                p_idCatalogo: p_idCatalogo,
            },
            headers: headerMDC(),
        });
    }
    getCatalogueData(accessToken,p_idCatalogo) {
        return apiosMDC.get("/getCatalogueData",{
            params: {
                accessToken: accessToken,
                p_idCatalogo: p_idCatalogo,
            },
            headers: headerMDC(),
        });
    }
    getCatalogueList(accessToken) {
        return apiosMDC.get("/getCatalogueList",{
            params: {
                accessToken: accessToken,
            },
            headers: headerMDC(),
        });
    }
    postCatalogueComplete(postCatalogueCompleteParameters) {
        return apiosMDC.post("/postCatalogueComplete", {
            params: {
                accessToken: postCatalogueCompleteParameters.accessToken,
                idMetaData: postCatalogueCompleteParameters.idMetaData,
                "oNames.catalogName": postCatalogueCompleteParameters.catalogName,
                "oNames.description": postCatalogueCompleteParameters.description,
                "oNames.responsableName": postCatalogueCompleteParameters.responsableName,
            },
            headers: headerMDC(),
        });
    }
    postCatalogueHeader(postCatalogueHeaderParameters) {
        return apiosMDC.post("/postCatalogueHeader",{
            params: {
                accessToken: postCatalogueHeaderParameters.accessToken,
                idCatalogue: postCatalogueHeaderParameters.idCatalogue,
                catalogueName: postCatalogueHeaderParameters.catalogueName,
                catalogueDescription: postCatalogueHeaderParameters.catalogueDescription,
                catalogueResponsable: postCatalogueHeaderParameters.catalogueResponsable,

            },
            headers: headerMDC(),
        });
    }
    postCatalogueData(postCatalogueDataParameters) {
        return apiosMDC.post("/postCatalogueData",{
            params: {
                accessToken: postCatalogueDataParameters.accessToken,
                idDataCatalogue: postCatalogueDataParameters.idDataCatalogue,
                idCatalogue: postCatalogueDataParameters.idCatalogue,
                idMetaData: postCatalogueDataParameters.idMetaData,
            },
            headers: headerMDC(),
        });
    }

    //Excel functions
    postImportrExcel(token,path) {
        return apiosMDC.post("/postImportrExcel",{
            params: {
                token: token,
                path: path,
            },
            headers: headerMDC(),
        });
    }
    //file upload?
    postUploadExcel(accessToken) {}

    //Metadata functions
    getAllMetaData(accessToken) {
        return apiosMDC.get("/getAllMetaData",{
            params: {
                accessToken: accessToken,
            },
            headers: headerMDC(),
        });
    }

    //Model functions
    getModelList(accessToken) {
        return apiosMDC.get("/getModelList",{
            params: {
                accessToken: accessToken,
            },
            headers: headerMDC(),
        });
    }
    getModelListAndHisCatalogue(accessToken) {
        return apiosMDC.get("/getModelListAndHisCatalogue",{
            params: {
                accessToken: accessToken,
            },
            headers: headerMDC(),
        });
    }
    getModelListByCatalogue(accessToken,p_idCatalogue) {
        return apiosMDC.get("/getModelListByCatalogue",{
            params: {
                accessToken: accessToken,
                p_idCatalogue: p_idCatalogue,
            },
            headers: headerMDC(),
        });
    }
    getModelData(accessToken,p_idCatalogue) {
        return apiosMDC.get("/getModelData",{
            params: {
                accessToken: accessToken,
                p_idCatalogue: p_idCatalogue,
            },
            headers: headerMDC(),
        });
    }
    getIdDataCatalogue(accessToken,idCatalogue,idMetaData) {
        return apiosMDC.get("/getIdDataCatalogue",{
            params: {
                accessToken: accessToken,
                idCatalogue: idCatalogue,
                idMetaData: idMetaData,
            },
            headers: headerMDC(),
        });
    }
    postModelComplete(postModelCompleteParameters) {
        return apiosMDC.post("/postModelComplete",{
            params: {
                accessToken: postModelCompleteParameters.accessToken,
                idModel: postModelCompleteParameters.idModel,
                valores: postModelCompleteParameters.valores,
                "description.idCatalogue" : postModelCompleteParameters.descriptionIdCatalogue,
                "description.name" : postModelCompleteParameters.descriptionName,
                "description.responsable" : postModelCompleteParameters.descriptionResponsable,
            },
            headers: headerMDC(),
        });
    }
    postModelEditData(postModelEditDataParameters) {
        return apiosMDC.post("/postModelEditData",{
            params: {
                accessToken: postModelEditDataParameters.accessToken,
                idModel: postModelEditDataParameters.idModel,
                valores: postModelEditDataParameters.valores,
                "description.idCatalogue" : postModelEditDataParameters.descriptionIdCatalogue,
                "description.name" : postModelEditDataParameters.descriptionName,
                "description.responsable" : postModelEditDataParameters.descriptionResponsable,
            },
            headers: headerMDC(),
        });
    }
    postEditModelData(postEditModelDataParameters) {
        return apiosMDC.post("/postEditModelData",{
            params: {
                accessToken: postEditModelDataParameters.accessToken,
                idModel: postEditModelDataParameters.idModel,
                valores: postEditModelDataParameters.valores,
                "description.idCatalogue" : postEditModelDataParameters.descriptionIdCatalogue,
                "description.name" : postEditModelDataParameters.descriptionName,
                "description.responsable" : postEditModelDataParameters.descriptionResponsable,
            },
            headers: headerMDC(),
        });
    }
    postEditModelDataPMR(postEditModelDataPMRParameters) {
        return apiosMDC.post("/postEditModelDataPMR",{
            params: {
                accessToken: postEditModelDataPMRParameters.accessToken,
                idModel: postEditModelDataPMRParameters.idModel,
                valores: postEditModelDataPMRParameters.valores,
                "description.idCatalogue" : postEditModelDataPMRParameters.descriptionIdCatalogue,
                "description.name" : postEditModelDataPMRParameters.descriptionName,
                "description.responsable" : postEditModelDataPMRParameters.descriptionResponsable,
            },
            headers: headerMDC(),
        });
    }
    

}
export default new MdcService();
