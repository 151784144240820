import apios from "../common/apios";
import authHeader from "./auth-header";


class VcService {

  /* Organisation methods */
  getOrganisations() {
    return apios.get("sys/getOrganisations", {
      headers: authHeader(),
    });
  }

    /* Organisation methods */
    getVerifiableCredentials() {
        return apios.get("vc/getVerifiableCredentials", {
          headers: authHeader(),
        });
    }

    getVerifiableCredentialById(vcid) {
        return apios.get("vc/getVerifiableCredentialById", {
            params: { id : vcid },
            headers: authHeader(),
        });
    }
    getVerifiableCredentialByProviderName(providerName) {
        return apios.get("vc/getVerifiableCredentialByProviderName", {
            params: { providerName: providerName },
            headers: authHeader(),
        });
    }
    addVerifiableCredential(verifiableCredentialData) {
        return apios.post("vc/addVerifiableCredential",
            verifiableCredentialData,
            { headers: authHeader(),
        });
    }
    updateVerifiableCredential(verifiableCredentialData) {
        return apios.put("vc/updateVerifiableCredential", 
            verifiableCredentialData,
            { headers: authHeader(),
        });
    }
    deleteVerifiableCredentialById(vcid) {
        return apios.delete("vc/deleteVerifiableCredentialById", {
            params: {id: vcid},
            headers: authHeader(),
        })
    }
}

export default new VcService();
