import React, { useEffect, useRef, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { ApiPageRequestsContainer } from "./assets/ApiPageRequestsContainer.style";
import config from "../config";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { apiRequestCodeData } from "../data/ct-data";
import CodeTextArea from "./CodeTextArea";
import { Stepper } from "react-form-stepper";
import { Steps } from 'primereact/steps';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from "primereact/tabview";

import { Dialog } from "primereact/dialog";
import {MDCDialogContainer} from "./assets/MDCDialogContainer.style"
import { isLoggedIn } from "../functionalities/common/token-check";
import SysService from "../functionalities/services/sys.service";
import MdcService from "../functionalities/services/mdc.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import WaitDialog from "../components/WaitDialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GrCloudDownload } from "react-icons/gr";
import { FaCloudDownloadAlt } from "react-icons/fa";

const MDCDialog = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [mdcAccessToken, setMdcAccessToken] = useState("");
  const [existingCatalogues, setExistingCatalogues] = useState([]);
  const [existingModels, setExistingModels] = useState([]);
  const [existingModelsWCat, setExistingModelsWCat] = useState([]);
  const [selectedCatalogue, setSelectedCatalogue] = useState({});

  const [activeIndex, setActiveIndex] = useState(0);

  const getExistingMDCCatalogues = (token) => {
    MdcService.getCatalogueList(token).then(
          (response) => {
            setExistingCatalogues(response?.data);
          },
          (error) => {
            const errorMessage =
              (error.response &&
                error.response.data &&
                error.response.data.responseCode) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString();
    
            toast.error(<Trans i18nKey={errorMessage} />);
            setLoading(false);
          }
        );
  }
  const getExistingMDCModels = (token) => {
    MdcService.getModelList(token).then(
      (response) => {
        console.log(response);
        setExistingModels(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(<Trans i18nKey={errorMessage} />);
        setLoading(false);
      }
    );
  }
  const getExistingMDCModelsWithCatalogueInfo = (token) => {
    MdcService.getModelListAndHisCatalogue(token).then(
      (response) => {
        console.log(response);
        setExistingModelsWCat(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(<Trans i18nKey={errorMessage} />);
        setLoading(false);
      }
    );
  }
  const getMDCModelFromSelectedCatalogue = (token,modelId) => {

  }
  const uploadExcel = (token) => {
    MdcService.postUploadExcel(token).then(
      (response) => {
        console.log(response)
      },
      (error)=> {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(<Trans i18nKey={errorMessage} />);
      }
    )
  }
  const mdcCatalogueActionTemplate = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-check-square"
          tooltip={t("select")}
          onClick={()=>{
            console.log(data)
            const selectedMDCCatalogue = {
              
            }
          }}
        />
      </div>
    )
  }

  return (
    <>
      {/* <Stepper
        steps={[{ label: t("mdcCatalogues") }, { label: t("mdcModels") }]}
        activeStep={activeIndex}
        />
        {activeIndex === 0 && (<>
        
        </>)} */}
      <MDCDialogContainer>
        <div className="mdc-token-input">
          <Button
            className="button-wip"
            icon="pi pi-exclamation-circle"
            tooltip={t("warnWorkInProgressBelow")}
          />
          <InputText
            style={{width:"250px"}}
            value={mdcAccessToken}
            placeholder={t("setYourMDCAccessToken")}
            onChange={(e) => setMdcAccessToken(e.target.value)}
          />
        </div>
        <br />
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => {
            setActiveIndex(e.index);
          }}
        >
          <TabPanel header={t("mdcCatalogues")}>
            <div className="mdc-catalogue-container">
              <Button
                className="mdc-button"
                // tooltip={t("submit")}
                // icon="pi pi-send"
                icon={<FaCloudDownloadAlt/>}
                iconPos="right"
                tooltip={t("getExistingMDCCatalogues")}
                onClick={() => getExistingMDCCatalogues(mdcAccessToken)}
              ></Button>
              {existingCatalogues !== undefined &&
                 (
                  <DataTable
                    size="small"
                    showGridlines
                    value={existingCatalogues?.catalogueHeader}
                    dataKey="id"
                    responsiveLayout="scroll"
                    paginator
                    rows={5}
                    loading={loading}
                  >
                    <Column field="idCatalogue" header={t("catalogueIdCatalogue")}></Column>
                    <Column field="catalogueName" header={t("catalogueName")}></Column>
                    <Column field="catalogueDescription" header={t("catalogueDescription")}></Column>
                    <Column field="catalogueResponsable" header={t("catalogueResponsable")}></Column>
                    <Column header={t("action")} style={{ textAlign: "center", width: "8em" }}body={mdcCatalogueActionTemplate}></Column>
                  </DataTable>
                )}
            </div>
          </TabPanel>
          <TabPanel header={t("mdcModels")}>
            <div className="mdc-model-container">
              <Button
                className="mdc-button"
                // tooltip={t("submit")}
                // icon="pi pi-send"
                icon={<FaCloudDownloadAlt/>}
                iconPos="right"
                label={t("getExistingMDCModels")}
                onClick={() => getExistingMDCModels(mdcAccessToken)}
              ></Button>
              <br />
              <br />
              <Button
                className="mdc-button"
                // tooltip={t("submit")}
                // icon="pi pi-send"
                icon={<FaCloudDownloadAlt/>}
                iconPos="right"
                label={t("getExistingMDCModelsWithCatalogueInfo")}
                onClick={() => getExistingMDCModelsWithCatalogueInfo(mdcAccessToken)}
              ></Button>
            </div>
          </TabPanel>
          <TabPanel>
          <div className="mdc-model-container">
              <Button
                className="mdc-button"
                // tooltip={t("submit")}
                // icon="pi pi-send"
                icon={<FaCloudDownloadAlt/>}
                iconPos="right"
                label={t("uploadExcel")}
                onClick={()=>uploadExcel(mdcAccessToken)}
              ></Button>
              
            </div>
          </TabPanel>
        </TabView>

        <WaitDialog loading={loading} lang={t} />

        <ToastContainer
          autoClose={2200}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          theme="dark"
          pauseOnFocusLoss={true}
          draggable={false}
          pauseOnHover={true}
          transition={Slide}
        />
      </MDCDialogContainer>
    </>
  );
};


export default withTranslation()(MDCDialog);
