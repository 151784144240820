import React, { useEffect, useRef, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Chip } from "primereact/chip";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { ApiPageRequestsContainer } from "./assets/ApiPageRequestsContainer.style";
import config from "../config";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { apiRequestCodeData } from "../data/ct-data";
import CodeTextArea from "./CodeTextArea";
import PrismCodeBlock from "./PrismCodeBlock";
// // Importing Prism.js
// import Prism from 'prismjs';

// // Importing a language
// import 'prismjs/components/prism-javascript';

// // Importing a theme
// import 'prismjs/themes/prism-tomorrow.css';


const ApiPageRequests = ({ t, requestData, requestPick }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [codeBlock, setCodeBlock] = useState({});


  const colorCodes = {
    stringColour : "",
    importPackageColour : "",
    fnColour : "",
    bracketColour : "",
    newColour : "",
    ConstructorColour : "",
  }

  const handleDomain = (data) => {
    if(data !== undefined && data !== null && data !== ""){
      const keys = Object.keys(data);
      for(let k = 0; k<keys.length; k++){
        data[keys[k]] = data[keys[k]].replaceAll('[DOMAIN]',config.backendApiUrl);
      }
      return data;
    }else {return ""}
  }

  useEffect(()=>{
    // console.log(requestData)
    setCodeBlock(apiRequestCodeData[requestData.slice(requestData.indexOf('/')+1)]); 
    // console.log(apiRequestCodeData[requestData.slice(requestData.indexOf('/')+1)])
    setCodeBlock(handleDomain(apiRequestCodeData[requestData.slice(requestData.indexOf('/')+1)]));
  },[requestData]);
  
  return (
    <ApiPageRequestsContainer
      requestBgColor={GeneralFunctions.reformRGB(
        GeneralFunctions.hexToRGB(requestPick?.props?.style?.backgroundColor)
      )}
      // requestBgColor={GeneralFunctions.reformRGB(GeneralFunctions.hexToRGB("#303b42"))}
    >
      {requestData !== undefined &&
        requestData !== null &&
        requestData !== "" && (
          <>
            <div className="request-body-container">
              <div className="request-container">
                {requestPick}
                {"/"}
                {requestData}
              </div>
              <div className="request-body-container-langs">
                <div className="flex mb-2 gap-2 justify-content-end">
                  {/* <Button onClick={() => setActiveIndex(0)} className="w-2rem h-2rem p-0" rounded outlined={activeIndex !== 0} label="1" />
                          <Button onClick={() => setActiveIndex(1)} className="w-2rem h-2rem p-0" rounded outlined={activeIndex !== 1} label="2" />
                          <Button onClick={() => setActiveIndex(2)} className="w-2rem h-2rem p-0" rounded outlined={activeIndex !== 2} label="3" /> */}
                </div>
                {/* {requestData.includes("sys/") && (
                  <>
                  <div className="request-container-blank">
                      {t("credentials")} <br /> 
                  </div>
                  <p>{t("headerBearerToken")}</p>
                  </>
                )} */}

                <div className="request-container-blank">
                    {t("url")} <br /> 
                </div>
                <div className="request-endpoint">
                  {config.backendApiUrl+'/api/'+requestData}
                </div>
                {/* <p className="request-endpoint">{config.backendApiUrl+'/api/'+requestData}</p> */}
                {/* <br /> */}
                <div className="request-container-blank">
                    {t("programmingLanguage")} 
                </div>
                <TabView
                  className="lang-tabs"
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  <TabPanel
                    className="lang-selection"
                    style={{ fontSize: "10px" }}
                    header="Shell"
                  >
                    {codeBlock !== undefined && (
                      // <CodeTextArea
                      //   value={codeBlock["shell"]}
                      // />
                      <>
                      
                      {/* <p style={{backgroundColor:"#303b42",color:"#fff"}}>{codeBlock["shell"]}</p> */}
                      {/* <pre style={{backgroundColor:"#303b42",color:"#fff"}}>{codeBlock["shell"]}</pre> */}
                      <PrismCodeBlock code={codeBlock["shell"]} language={"bash"}></PrismCodeBlock>
                      </>
                      //   <textarea
                      //   className="code-block-area"
                      //   defaultValue={codeBlock["shell"]}
                      //   name=""
                      //   id=""
                      //   disabled
                      //   rows={50}
                      //   cols={65}
                      //   style={{resize:"none",maxWidth:"365px", maxHeight:"400px"}}
                      // ></textarea>
                    )}

                  </TabPanel>
                  <TabPanel
                    className="lang-selection"
                    style={{ fontSize: "10px" }}
                    header="Python"
                  >
                    {codeBlock !== undefined && (
                      <PrismCodeBlock code={codeBlock["python"]} language={"python"}></PrismCodeBlock>
                    )}
                  </TabPanel>
                  <TabPanel
                    className="lang-selection"
                    style={{ fontSize: "10px" }}
                    header="Go"
                  >
                    {codeBlock !== undefined && (
                      // <CodeTextArea
                      //   value={codeBlock["go"]}
                      // />
                      <PrismCodeBlock code={codeBlock["go"]} language={"go"}></PrismCodeBlock>
                    )}
                  </TabPanel>
                  <TabPanel
                    className="lang-selection"
                    style={{ fontSize: "10px" }}
                    header="Rust"
                  >
                    {codeBlock !== undefined && (
                      // <CodeTextArea
                      //   value={codeBlock["rust"]}
                      // />
                      <PrismCodeBlock code={codeBlock["rust"]} language={"rust"}></PrismCodeBlock>
                    )}
                  </TabPanel>
                  <TabPanel
                    className="lang-selection"
                    style={{ fontSize: "10px" }}
                    header="Java"
                  >
                    {codeBlock !== undefined && (
                      // <CodeTextArea
                      //   value={codeBlock["java"]}
                      // />
                      <PrismCodeBlock code={codeBlock["java"]} language={"java"}></PrismCodeBlock>
                    )}
                  </TabPanel>
                  <TabPanel
                    className="lang-selection"
                    style={{ fontSize: "10px" }}
                    header="JS"
                  >
                    {codeBlock !== undefined && (
                      // <CodeTextArea
                      //   value={codeBlock["js"]}
                      // />
                      <PrismCodeBlock code={codeBlock["js"]} language={"javascript"}></PrismCodeBlock>
                    )}
                  </TabPanel>
                </TabView>
        
              </div>
            </div>
            <div className="response-body-container"></div>
          </>
        )}
    </ApiPageRequestsContainer>
  );
};

export default withTranslation()(ApiPageRequests);
