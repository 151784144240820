import jwt_decode from "jwt-decode";

export const TOKEN_USER = "circthread-user";

export const isLoggedIn = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));
    if (user) {
      const decodedJwt = jwt_decode(user.token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  return false;
};
export const giveUsername = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));
    if (user) {
      const decodedJwt = jwt_decode(user.token);
      return decodedJwt.email;
      
    } else {
      return null;
    }
  }
  else{
    return null;
  }
}


export const isCTAdmin = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
     

      if (decodedJwt.realm_access.roles.includes("CT_ADMIN")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isCTManufacturer = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
     

      if (decodedJwt.realm_access.roles.includes("CT_MANUFACTURER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isCTProvider = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
     

      if (decodedJwt.realm_access.roles.includes("CT_PROVIDER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isCTRepairer = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
     

      if (decodedJwt.realm_access.roles.includes("CT_REPAIRER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const getMyRoles = () => {
  let myRoles = [];
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return myRoles;
      }
      if (decodedJwt.realm_access.roles.length > 0) {
        return filterKCroles(decodedJwt.realm_access.roles);
      }
      return myRoles;
    } else {
      return myRoles;
    }
  }

  return myRoles;
};

const filterKCroles = (allRoles) => {
  const _tbr = ["default-roles-master","offline_access","uma_authorization"];
  function removeItem(array, itemToRemove) {
    return array.filter(
    	item => item !== itemToRemove);
  }
  let _mut = allRoles;
  for(let r = 0; r<_tbr.length; r++){
    _mut = removeItem(_mut,_tbr[r])
  }
  return _mut
}

// export const serviceApiRequest = (url, header,methodType, params) =>{

//   let fetchResult = "";
//   async function getData() {
//     const url = "https://example.org/products.json";
//     try {
//       const response = await fetch(url);
//       if (!response.ok) {
//         throw new Error(`Response status: ${response.status}`);
//       }
  
//       const json = await response.json();
//       console.log(json);
//     } catch (error) {
//       console.error(error.message);
//     }
//   }
// }