import styled from "@emotion/styled";
export const ApiPageContentContainer = styled.div`
    // width: fit-content;
    // display:flex; 
    // flex-direction:row;
    // max-width: 200px !important;
    // border-radius: 6px;
    // border-style: solid;
    // border-color: #00a3ff;
    // border-width: thin;
    text-align: left;
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    .api-content-api-title {
        border-radius: 6px;
        border-style: solid;
        border-color: #00a3ff;
        border-width: thin;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    }


    .api-content-auth-content-warning {
        //background-color: #d6d6d6;
        color: #000;
        border-radius: 6px;
        border-style: solid;
        border-color: #ff0000;
        border-width: thin;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        padding-top: 0.4rem;
        margin-top: 0.4rem;
    }
    .api-content-parameter-span{
        font-weight: bold;
    }    
`