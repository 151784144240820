import apios from "../common/apios";
import authHeader from "./auth-header";

class SysService {
  /* Organisation methods */
  getOrganisations() {
    return apios.get("sys/getOrganisations", {
      headers: authHeader(),
    });
  }
  getOrganisationById(oid) {
    return apios.get("sys/getOrganisationById", {
      params: {
        id: oid,
      },
      headers: authHeader(),
    });
  }
  addOrganisation(organisationData) {
    return apios.post("sys/addOrganisation", organisationData, {
      headers: authHeader(),
    });
  }
  updateOrganisation(organisationData) {
    return apios.put("sys/updateOrganisation", organisationData, {
      headers: authHeader(),
    });
  }

  deleteOrganisationById(oid) {
    return apios.delete("sys/deleteOrganisationById", {
      params: { id: oid },
      headers: authHeader(),
    });
  }

  /* Users methods */
  addUser(userInfo) {
    return apios.post("sys/addUser", userInfo, { headers: authHeader() });
  }
  getUserById(uid) {
    return apios.get("sys/getUserById", {
      params: {
        id: uid,
      },
      headers: authHeader(),
    });
  }
  getUsersByOrganisationId(oid) {
    return apios.get("sys/getUsersByOrganisationId", {
      params: {
        id: oid,
      },
      headers: authHeader(),
    });
  }
  updateUser(userInfo) {
    return apios.put(
      "sys/updateUser",
      userInfo,
      // body : {body: userInfo},
      { headers: authHeader() }
    );
  }

  deleteUserById(uid) {
    return apios.delete("sys/deleteUserById", {
      params: { id: uid },
      headers: authHeader(),
    });
  }
  getOrganisationByUsername(username) {
    return apios.get("sys/getOrganisationByUsername", {
      params: { username: username },
      headers: authHeader(),
    });
  }

  /* Services methods */
  getRegisteredServices() {
    return apios.get("sys/getRegisteredServices", { headers: authHeader() });
  }
  getRegisteredServiceById(sid) {
    return apios.get("sys/getRegisteredServiceById", {
      params: {
        id: sid,
      },
      headers: authHeader(),
    });
  }
  addRegisteredService(serviceData) {
    return apios.post("sys/addRegisteredService", serviceData, {
      headers: authHeader(),
    });
  }
  updateRegisteredService(serviceData) {
    return apios.put("sys/updateRegisteredService", serviceData, {
      headers: authHeader(),
    });
  }

  /* Service Role Mapping methods */
  updateRegisteredServiceRoleMapping(serviceData) {
    return apios.post("sys/updateRegisteredServiceRoleMapping", serviceData, {
      headers: authHeader(),
    });
  }

  /* Save service result as record*/
  createRegisteredServiceResultRecord(serviceResultData) {
    return apios.post(
      "sys/createRegisteredServiceResultRecord",
      serviceResultData,
      {
        headers: authHeader(),
      }
    );
  }
  getRegisteredServiceResultRecords(username, rsid) {
    return apios.get("sys/getRegisteredServiceResultRecords", {
      params: {
        username: username,
        rsid: rsid,
      },
      headers: authHeader(),
    });
  }
  getRegisteredServiceResultRecordsByPM(username, pmid) {
    return apios.get("sys/getRegisteredServiceResultRecordsByPM", {
      params: {
        username: username,
        pmid: pmid,
      },
      headers: authHeader(),
    })
  }
  /* Save and receive saved credentials and token for service */
  getRegisteredServiceUserInfo(username, rsid) {
    return apios.get("sys/getRegisteredServiceUserInfo", {
      params: {
        username: username,
        rsid: rsid,
      },
      headers: authHeader(),
    });
  }

  addRegisteredServiceUserInfo(registeredServiceUserInfoData) {
    return apios.post(
      "sys/addRegisteredServiceUserInfo",
      registeredServiceUserInfoData,
      {
        headers: authHeader(),
      }
    );
  }

  /* DPP methods */
  /* DPP Providers methods */
  getProviders() {
    return apios.get("sys/getProviders", { headers: authHeader() });
  }
  getProviderById(pid) {
    return apios.get("sys/getProviderById", {
      params: {
        id: pid,
      },
      headers: authHeader(),
    });
  }
  addProvider(providerData) {
    return apios.post("sys/addProvider", providerData, {
      headers: authHeader(),
    });
  }
  updateProvider(providerData) {
    return apios.put("sys/updateProvider", providerData, {
      headers: authHeader(),
    });
  }
  deleteProviderById(pid) {
    return apios.delete("sys/deleteProviderById", {
      params: { id: pid },
      headers: authHeader(),
    });
  }

  /* Product Models methods */
  getProductModelsByOrganisationId(oid) {
    return apios.get("sys/getProductModelsByOrganisationId", {
      params: { id: oid },
      headers: authHeader(),
    });
  }
  getProductModelById(pid) {
    return apios.get("sys/getProductModelById", {
      params: { id: pid },
      headers: authHeader(),
    });
  }
  getProductModelByCode(code) {
    return apios.get("sys/getProductModelByCode", {
      params: {
        code: code,
      },
      headers: authHeader(),
    });
  }
  addProductModel(productModelData) {
    return apios.post("sys/addProductModel", productModelData, {
      headers: authHeader(),
    });
  }
  updateProductModel(productModelData) {
    return apios.put("sys/updateProductModel", productModelData, {
      headers: authHeader(),
    });
  }
  deleteProductModelById(pid) {
    return apios.delete("sys/deleteProductModelById", {
      params: { id: pid },
      headers: authHeader(),
    });
  }

  /* Search methods */
  /* Support methods */

  /* Role methods*/
  getRoles() {
    return apios.get("sys/getRoles", {
      headers: authHeader(),
    });
  }
  addRole(roleData) {
    return apios.post("sys/addRole", roleData, { headers: authHeader() });
  }
  updateRole(roleData) {
    return apios.put("sys/updateRole", roleData, { headers: authHeader() });
  }
}

export default new SysService();
