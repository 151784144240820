import { format } from 'date-fns';
import jsPDF from "jspdf";
import "jspdf-autotable";

class GeneralFunctions {
  randomColor() {
  return "#" + Math.floor(Math.random() * 0xffffff).toString(16);
}
  copyToClipboard = async (data) =>{
    try{
      await window.navigator.clipboard.writeText(data);
      return true;
      // toast.info(<Trans i18nKey={"infoCopiedToClipboard"}/>);
    }
    catch(error){
      return false;
      // toast.error(<Trans i18nKey={"failCopiedToClipboard"} />)
    }
  }
 handleDownloadDocument(rowData) {
  var a = document.createElement("a");
  a.target = "_blank";
  a.href = rowData.documentURL;
  a.setAttribute("download", rowData.documentName);
  a.click();
}

  diffMinutes(dt1Str) {
  var dt1 = dt1Str.split(" ");

  let ymd = dt1[0].split("-");
  let hms = dt1[1].split(":");

  var dt1 = new Date(ymd[0], ymd[1] - 1, ymd[2], hms[0], hms[1], hms[2], 0);

  return parseInt((new Date(Date.now()) - dt1) / 60000);
}

 editorFormats = [
  //'background',
  "bold",
  "color",
  "font",
  //'code',
  "italic",
  "link",
  "size",
  "strike",
  //'script',
  "underline",
  //'blockquote',
  "header",
  "indent",
  "list",
  "align",
  "direction",
  //'code-block',
  //'formula'
  // 'image'
  // 'video'
];
  saveDataAsTextFile = (data,txtName) => {
    const link = document.createElement("a");
    const file = new Blob([data], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = txtName+".txt";
    link.click();
    URL.revokeObjectURL(link.href);
  }
  saveDataAsJson = (data,jsonName) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${jsonName}.json`;
    a.click();
    URL.revokeObjectURL(url);
  }
  saveDataAsPdfFile = (data, pdfName) => {
    const jspdf = new jsPDF("p", "pt", "letter");
    const margin = { top: 30, right: 30, bottom: 30, left: 30 };
    // var pageHeight = jspdf.internal.pageSize.getHeight();
    // console.log(pageHeight)
    // const dataBody = jspdf.splitTextToSize(data, 250)
    // console.log(dataBody.length)

    // var y = 15;
    // for (let i = 0; i < dataBody.length; i++) {
    //   if (y+10 > pageHeight) {
    //     y = 15;
    //     jspdf.addPage();
    //   }
    //   jspdf.text(10, y, dataBody[i]);
    //   y = y + 7;
    // } 
    jspdf.text(data, margin.left, margin.top, { align: "left", maxWidth: 500 });
    jspdf.save(pdfName+".pdf");
    // jspdf.output('dataurlnewwindow');

  }
  saveTxtDataAsPdfFile = (data, pdfName) => {
      // const doc = new jsPDF();

      // let yPosition = 20;
      // const lineHeight = 10;
      // const sectionSpacing = 15;
      // doc.setFontSize(16);
      // doc.setFont("helvetica", "bold");
      // doc.text(pdfName, 105, yPosition, {
      //   align: "center",
      // });
      // yPosition += sectionSpacing;

      const fields = [
        // {
        //   label: "MetaData catalog description",
        //   value: data.mdcDescription,
        // },
        // {
        //   label: "MetaData catalog field",
        //   value: data.mdcField,
        // },
        // { label: "MetaData catalog url", value: data?.mdcUrl },
      ];

      // doc.setFontSize(12);
      // doc.setFont("helvetica", "normal");

      // fields.forEach((field) => {
      //   if (field.value) {
      //     doc.text(`${field.label}:`, 20, yPosition);
      //     doc.text(`${field.value}`, 100, yPosition); 
      //     yPosition += lineHeight + 2; 
      //   }
      // });
      // const margin = { top: 30, right: 30, bottom: 30, left: 30 };

      // doc.text(data, margin.left, margin.top, { align: "left", maxWidth: 500 });

      // doc.setDrawColor(0);
      // doc.setLineWidth(0.5);
      // doc.rect(15, 10, 180, yPosition - 10);
      // doc.save(data.name+"-MetaData-Catalog-"+getDateTemplateV4(Date.now())+".pdf");
      // doc.save(pdfName+".pdf");

  }

  getDateTemplateV1 = (data) => {
  if (data !== undefined && data !== null) {
    const d = new Date(data);
    return format(d, "dd/MM/yyyy HH:mm");
  }
};

 getDateTemplateV2 = (data) => {
    if (data !== undefined && data !== null) {
      const d = new Date(data);
      return format(d, "dd/MM/yyyy HH:mm:ss");
    }
  };
  getDateTemplateV3 = (data) => {
    if (data !== undefined && data !== null) {
      const d = new Date(data);
      return format(d, "dd/MM/yyyy");
    }
  };
  getDateTemplateV4 = (data) => {
    if (data !== undefined && data !== null) {
      const d = new Date(data);
      return format(d, "dd-MM-yyyy--HH-mm-ss");
    }
  }
  hexToRGB = (hex) => {
    if(hex !== undefined && hex !== null){
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      
      // return {r, g, b} 
      return { r, g, b };
    }

  }

  reformRGB = (rgb) => {
    return rgb?.r+","+rgb?.g+","+rgb?.b;
  }
  
  handlePaste = (e, targetValue, setData) => {
  e.preventDefault();

  const pastedText = (e.clipboardData || window.clipboardData).getData(
    "text/plain"
  );

  setData((prevData) => ({
    ...prevData,
    [targetValue]: pastedText,
  }));
};
}

export default new GeneralFunctions();
