import styled from "@emotion/styled";
export const TutorialPageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 720px;

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;

.image-gallery {
    width: 350px;
    height: auto;
}

   .image-gallery-image {
        cursor: default !important;
        max-height: 350px;
        width: 100%;
        border-radius: 6px;
      }
  .image-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;

    // .gallery {
    //   flex: 0 0 calc(50% - 1rem);

    //   .image-gallery-image {
    //     cursor: default !important;
    //     max-height: 350px;
    //     width: 100%;
    //     border-radius: 6px;
    //   }
    //   .image-gallery-svg {
    //     cursor: default !important;
    //     height: 60px;
    //     width: 30px;
    //   }

    //   .image-gallery-fullscreen-button {
    //     padding: 0 0.5rem 0 0;
    //   }
    // }

    // .gallery-w-fullscreen {
    //   flex: 0 0 calc(50% - 1rem);
    // }
  }
`;
