import apios from "../common/apios";
import axios from "axios";

class APIService {
  generateAPIKey() {
    var d = new Date().getTime();

    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    var apikey = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return apikey;
  }
  generateAPIKeyV2() {
    return crypto.randomUUID();
  }
  generateAPISecret(length) {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const randomArray = new Uint8Array(length);
    crypto.getRandomValues(randomArray);
    randomArray.forEach((number) => {
      result += chars[number % chars.length];
    });
    return result;
  }
  getAPIEndPoints = (serverAdress) => {
    // console.log(serverAdress+"/v3/api-docs")

    const apiDocs = axios.create({
      baseURL: serverAdress+"/v3/api-docs",
      timeout: 30000,
      timeoutErrorMessage: "Connection timeout. Please try again",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    });
    return apiDocs.get(serverAdress+"/v3/api-docs");
  }

  providerPassportRequest = (providerApiEndpoint,passportCode) => {
    const apiPassport = axios.create({
      baseURL: providerApiEndpoint+"passport/"+passportCode,
      timeout: 30000,
      timeoutErrorMessage: "Connection timeout. Please try again",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    });
    // return apiPassport.get("",{params:{passportCode:passportCode}});
    return apiPassport.get("");

  }
  providerAddEventRequest = (providerApiEndpoint, eventBody) => {
    const apiEvent = axios.create({
      baseURL: providerApiEndpoint+"addNewEventToPassport",
      timeout: 30000,
      timeoutErrorMessage: "Connection timeout. Please try again",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    });

    return apiEvent.post("",eventBody)
  }

  tracidItemLevelSearch = (passportCode) => {

    const apiILS = axios.create({
      baseURL: "https://demo.tracid.net/api/int/passport/"+passportCode,
      timeout: 30000,
      timeoutErrorMessage: "Connection timeout. Please try again",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    
    });

    return apiILS.get("");
  }

  serviceTokenRequest(serviceAuthUrl, authReqBody) {
    console.log(serviceAuthUrl)
    const apiAS = axios.create({
      baseURL: serviceAuthUrl,
      timeout: 30000,
      timeoutErrorMessage: "Connection timeout. Please try again",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    });

    // return apiAS.post({
    //   authReqBody
    // }).then((response) => {
    //       return response.data;
    // });
    return apiAS.post("", authReqBody);
  }

  serviceApiRequest = (url,methodType,reqEndPoint,reqParams,reqDataBody,token) => {
    const apiRS = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Connection timeout. Please try again",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
    });

    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      // "Upgrade-Insecure-Requests": 1
    };
    console.log(token)
    if(token !== undefined && token !== null && token !== "" && token?.tokenData !== undefined && token?.tokenData !== null && token?.tokenData !== ""){
      headers = {...headers, Authorization: 'Bearer '+token?.tokenData}
    }
    if(!url.includes("https://")){
      headers = {...headers, "Upgrade-Insecure-Requests": 1}
    }
    // const params = {}
    // if(reqParams !== undefined && ){}
    console.log(headers)
    console.log(reqEndPoint)
    console.log(reqParams)
    console.log(reqDataBody)

    if(reqEndPoint.charAt((reqEndPoint.length -1) === '/')){
      reqEndPoint = reqEndPoint.slice(0, -1);
    }
    switch(methodType){
      case 'get':    
        return apiRS.get(reqEndPoint,{
          params: reqParams,
          headers: headers
        })
      case 'post':
        console.log(reqEndPoint)
        console.log(reqDataBody)
        return apiRS.post(reqEndPoint, reqDataBody, {
          // reqDataBody,
          // params: reqParams,
          headers:headers
        })
      case 'put':
        return apiRS.put(reqEndPoint, {
          reqDataBody,
          // params: reqParams,
          headers:headers
        })
      case 'delete':
        return apiRS.delete(reqEndPoint,{
          params: reqParams,
          headers: headers
        })
    }

  }

  // serviceApiRequest = (url,header,methodType,params) => {
  //   try{
  //     const response = fetch()
  //   }
  // }
}
export default new APIService();
