import {ProductModelSelectContainer} from "./assets/ProductModelSelectContainer.style"
import React, { useEffect, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Slide, ToastContainer, toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import WaitDialog from "../components/WaitDialog";
import { giveUsername } from "../functionalities/common/token-check";
import SysService from "../functionalities/services/sys.service";
import ProductModelField from "./ProductModelField";


const ProductModelSelect = ({ t, productModels, setProductModels, selectedPM, setSelectedPM }) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [oid, setOid] = useState({});
  // const [selectedPM, setSelectedPM] = useState({});
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);


  useEffect(() => setUsername(giveUsername()), []);
  useEffect(()=>{
    if (username !== null && username !== undefined && username !== "") {
      SysService.getOrganisationByUsername(username).then(
        (response) => {
        
          if (response.status === 200) {
            // console.log(response?.data)
            setOid(response?.data);
          } else {
            toast.error(response.status);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
        
          toast.error(errorMessage);
        }
      );
    }  },[username]);
    useEffect(() => {
      if (oid.id !== null && oid.id !== undefined && oid.id !== "") {
        SysService.getProductModelsByOrganisationId(oid.id).then(
          (response) => {
          
            if (response.status === 200) {
              // console.log(response?.data)
              setProductModels(response?.data);
            } else {
              toast.error(response.status);
            }
          },
          (error) => {
            const errorMessage =
              (error.response &&
                error.response?.data &&
                error.response?.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString();
        
            toast.error(error.response.status + " " + error.response?.data?.message);
          }
        );
      }
    }, [oid]);

  useEffect(()=> { 
    if(selectedPM !== undefined && selectedPM !== null) {
      setKeys(Object.keys(selectedPM)); setValues(Object.values(selectedPM)) 
      }},[selectedPM]);
  return (
    <ProductModelSelectContainer>
      <div className="pm-selection-container">
        {productModels !== undefined && productModels !== null && productModels.length> 0 ? (
          <>
          <p className="pm-selection-text">{t("youCanSelectFromAvailablePMs")}</p>
          <Dropdown
            className="pm-selection-dropdown"
            options={productModels}
            optionLabel="name"
            // optionValue="name"
            value={selectedPM}
            onChange={(e) => { setSelectedPM(e.target.value) }}

          /></>
        ):(
          <p className="no-data-warning">{t("warnNoProductModelDataToChooseFrom")}</p>
        )}

        <ProductModelField selectedProductModel={selectedPM} selectedKeys={keys} selectedValues={values}/>
      </div>
      {/* <div className="pm-data-container"></div> */}

      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ProductModelSelectContainer>
  );
};

export default withTranslation()(ProductModelSelect);
