import {
  ProductModelsContainer,
  ViewProductModelContainer,
  EditProductModelContainer,
  AddProductModelContainer,
} from "./assets/ProductModelsContainer.style";
import { statusTypesList } from "../data/ct-data";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import { giveUsername } from "../functionalities/common/token-check";
import { useEffect, useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import MDCDialog from "../components/MDCDialog"
import {
  isLoggedIn,
  isCTManufacturer,
} from "../functionalities/common/token-check";
import WaitDialog from "../components/WaitDialog";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

const emptyProductModelData = {
  name: "",
  brandName: "",
  ean: "",
  mpn: "",
  code: "",
  gtin: "",
  description : "",
  imageUri : "",
  sector : "",
  productCategory : "",
  productType : "",
}

const ProductModels = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [oid, setOid] = useState({});
  const [productModels, setProductModels] = useState([]);
  const [productModel, setProductModel] = useState(emptyProductModelData);
  const [singularProductModel, setSingularProductModel] = useState({});
  const [createProductModel, setCreateProductModel] = useState(false);
  const [editProductModel, setEditProductModel] = useState(false);
  const [viewProductModel, setViewProductModel] = useState(false);
  const [mdcVisible, setMDCVisible] = useState(false);
  const navigate = useNavigate();

  const submitProductModelCreation = () => {
    setLoading(true);
  
    if (
      productModel?.name === "" ||
      productModel?.brandName === "" ||
      productModel?.code === "" ||
      // productModel.gtin === "" ||
      // productModel.imageUri === "" ||
      productModel?.description === "" || 
      productModel?.sector === "" ||
      productModel?.productCategory === "" ||
      productModel?.productType === ""
      // productModel?.manufacturer === ""
      // productModel?.country === "" 
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    const productModelDTO = {
      productModelDTO: { ...productModel },
    };
 
    setLoading(false);
    SysService.addProductModel(productModelDTO).then(
      (response) => {
        if (response.status === 200) {
          toast.success(<Trans i18nKey={"successProductModelCreation"} />);
          if (oid.id !== null && oid.id !== undefined && oid.id !== "") {
            SysService.getProductModelsByOrganisationId(oid.id).then(
              (response) => {
              
                if (response.status === 200) {
                  setProductModels(response?.data);
                } else {
                  toast.error(response.status);
                }
              },
              (error) => {
                const errorMessage =
                  (error.response &&
                    error.response?.data &&
                    error.response?.data.message) ||
                  error.message ||
                  error.timeoutErrorMessage ||
                  error.toString();
            
                toast.error(error.response.status + " " + error.response?.data);
              }
            );
          }
          setLoading(false);
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        // toast.error(<Trans i18nKey={errorMessage} />);
        toast.error(error.response?.data.responseCode, <Trans i18nKey={"failProductModelCreation"} />)
        setLoading(false);
      }
    );
    setProductModel(emptyProductModelData);
    setCreateProductModel(false);
  };
  const submitProductModelEdit = () => {
    setLoading(true);
  
    if (
      singularProductModel?.name === "" ||
      singularProductModel?.brandName === "" ||
      singularProductModel?.code === "" ||
      // productModel.gtin === "" ||
      // productModel.imageUri === "" ||
      singularProductModel?.description === "" || 
      singularProductModel?.sector === "" ||
      singularProductModel?.productCategory === "" ||
      singularProductModel?.productType === ""
      // singularProductModel?.manufacturer === ""
      // singularProductModel?.country === "" 
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    const productModelDTO = {
      productModelDTO: {
        id: singularProductModel?.id,
        name: singularProductModel?.name,
        brandName: singularProductModel?.brandName,
        description: singularProductModel?.description,
        ean: singularProductModel?.ean,
        mpn: singularProductModel?.mpn,
        code: singularProductModel?.code,
        gtin: singularProductModel?.gtin,
        imageUri: singularProductModel?.imageUri,
        sector: singularProductModel?.sector,
        productCategory: singularProductModel?.productCategory,
        productType: singularProductModel?.productType,
        // manufacturer: singularProductModel?.manufacturer,
        // country: singularProductModel?.country,
        status: singularProductModel?.status,
        organisation: oid,
      },
    };
  

    SysService.updateProductModel(productModelDTO).then(
      (response) => {
        if (response.status === 200) {
          toast.success(<Trans i18nKey={"successProductModelUpdate"} />);
          if (oid.id !== null && oid.id !== undefined && oid.id !== "") {
            SysService.getProductModelsByOrganisationId(oid.id).then(
              (response) => {
             
                if (response.status === 200) {
                  setProductModels(response?.data);
                } else {
                  toast.error(response.status);
                }
              },
              (error) => {
                const errorMessage =
                  (error.response &&
                    error.response?.data &&
                    error.response?.data.message) ||
                  error.message ||
                  error.timeoutErrorMessage ||
                  error.toString();
            
                toast.error(error.response.status + " " + error.response?.data);
                
              }
            );
          }
          setLoading(false);
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(error.response?.data.responseCode, <Trans i18nKey={"failProductModelUpdate"} />)
        setLoading(false);
      }
    );
    setLoading(false);
    setSingularProductModel({});
    setEditProductModel(false);
  };
  const getProductModelInfo = (id) => {
    SysService.getProductModelById(id).then(
      (response) => {
       
        if (response.status === 200) {
          setSingularProductModel(response?.data);
        } else {
          toast.error(response.status);
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
       
        toast.error(error.response.status + " " + error.response?.data);
      }
    );
  };
  const statusInfo = (statData) => {
    return t("STATUS_" + statData);
  };
  const documentKeywordTemplate = (documentData) => {
    if (
      documentData?.keyword !== undefined &&
      documentData?.keyword !== null &&
      documentData?.keyword !== ""
    ) {
      const chipInfo = documentData?.keyword.split(";");
      return (
        <>
          <div>
            {chipInfo.map((ai) => (
              <>
                <Chip
                  label={ai}
                  className="mr-2 mb-2"
                  style={{ backgroundColor: "rgb(249, 163, 101)" }}
                />
              </>
            ))}
          </div>
        </>
      );
    }
  };
  const documentCreationtimeTemplate = (documentData) => {
    if (
      documentData?.creationTime !== undefined &&
      documentData?.creationTime !== null &&
      documentData?.creationTime !== ""
    ) {
      return GeneralFunctions.getDateTemplateV2(documentData?.creationTime);
    }
  };
  const actionTemplateStatus = (data) => {
    return (
      <Chip
        label={t("STATUS_" + data.status)}
        className="mr-2 mb-2"
        style={{ backgroundColor: "#382c7c" ,color:"#fff"}}
      />
    );
  };
  const actionTemplate = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          tooltip={t("edit")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setEditProductModel(true);
            getProductModelInfo(data?.id);
          }}
          id="buttonFontWeight"
        />
        <Button
          icon="pi pi-exclamation-circle"
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setViewProductModel(true);
            getProductModelInfo(data?.id);
          }}
          id="buttonFontWeight"
        />
        <Button
          id="buttonFontWeight"
          icon="pi pi-chevron-right"
          tooltip={t("goto")}
          className="p-button-rounded p-button-text"
          onClick={() => {

            navigate("/pm/" + data?.code);
          }}
        />
      </div>
    );
  };
  const metaDataCatalogueProductModel = (data) => {
    return (
      <div>
        {/* {data?.mdcField || data?.mdcUrl || data?.mdcDescription ? ( */}
          <Button
          icon="pi pi-external-link"
          tooltip={t("goto")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            // generateMetaDataCatalogPDF(data);
            setMDCVisible(true);
          }}
          id="buttonFontWeight"
        />
        {/* ):(
          <>{"NaN"}</>
        )} */}
        
      </div>
    );
  };
  const modelLevelDPPTemplate = (data) => {
    return (
      <div>
        {data?.passportCode && data?.providerEndpoint ? (
          <Button
          icon="pi pi-external-link"
          tooltip={t("goto")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            // navigate("/pm/" + data?.id);
            if (data?.providerEndpoint.endsWith("/")) {
              window.open(
                data?.providerEndpoint + data?.passportCode,
                "_blank",
                "noopener,noreferrer"
              );
            } else {
              window.open(
                data?.providerEndpoint +
                  "/" +
                  data?.passportCode,
                "_blank",
                "noopener,noreferrer"
              );
            }
          }}
          id="buttonFontWeight"
        />
        ):(
          <>{"NaN"}</>
        )} 
      </div>
    );
  };
  const generateMetaDataCatalogPDF = async (data) => {
    if(!isLoggedIn()){
      navigate("/login");
    }
    else if(isLoggedIn() && isCTManufacturer()){

      setLoading(true);
      const doc = new jsPDF();

      let yPosition = 20;
      const lineHeight = 10;
      const sectionSpacing = 15;
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(data.name + " MetaData Catalog", 105, yPosition, {
        align: "center",
      });
      yPosition += sectionSpacing;

      const fields = [
        {
          label: "MetaData catalog description",
          value: data.mdcDescription,
        },
        {
          label: "MetaData catalog field",
          value: data.mdcField,
        },
        { label: "MetaData catalog url", value: data?.mdcUrl },
      ];

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      fields.forEach((field) => {
        if (field.value) {
          doc.text(`${field.label}:`, 20, yPosition);
          doc.text(`${field.value}`, 100, yPosition); 
          yPosition += lineHeight + 2; 
        }
      });
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.rect(15, 10, 180, yPosition - 10);
      doc.save(data.name+"-MetaData-Catalog-"+GeneralFunctions.getDateTemplateV4(Date.now())+".pdf");
      setLoading(false);
      toast.success(<Trans i18nKey={"successMDCPDFCreation"} />);
    }
    else{
      setLoading(false);
      toast.warning(<Trans i18nKey="warnWrongRole" />);
      return;
    }
  };
  useEffect(() => {
    setUsername(giveUsername());
    if(!isCTManufacturer()){navigate("/landing");}
  }, []);
  useEffect(() => {
    if (username !== null && username !== undefined && username !== "") {
      SysService.getOrganisationByUsername(username).then(
        (response) => {
        
          if (response.status === 200) {
            setOid(response?.data);
          } else {
            toast.error(response.status);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
        
          toast.error(errorMessage);
        }
      );
    }
  }, [username]);
  useEffect(() => {
    if (oid.id !== null && oid.id !== undefined && oid.id !== "") {
      SysService.getProductModelsByOrganisationId(oid.id).then(
        (response) => {
        
          if (response.status === 200) {
            setProductModels(response?.data);
          } else {
            toast.error(response.status);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
      
          toast.error(error.response.status + " " + error.response?.data?.message);
        }
      );
    }
  }, [oid]);
  return (
    <ProductModelsContainer>
      <h2>{t("ProductModels")}</h2>
      <AddProductModelContainer>
        <Button
          className="view-button"
          label={t("createAProductModel")}
          onClick={() => {
            setProductModel(emptyProductModelData);
            setCreateProductModel(true);
          }}
        />
      </AddProductModelContainer>

      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={productModels}
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noProductModelsFound")}
              paginator
              rows={10}

              loading={loading}
            >
              <Column field="name" header={t("productModelName")}></Column>
             
              <Column
                field="brandName"
                header={t("productModelBrandName")}
              ></Column>
              <Column
                header={t("productModelLevelDPPLink")}
                style={{ textAlign: "center", width: "10em" }}
                body={modelLevelDPPTemplate}
              />
              <Column
                header={t("productmodelMetaDataCatalogueActions")}
                style={{ textAlign: "center", width: "10em" }}
                body={metaDataCatalogueProductModel}
              />
              <Column
                header={t("productmodelActions")}
                style={{ textAlign: "center", width: "10em" }}
                body={actionTemplate}
              />
            </DataTable>
          </div>
        </div>
      </BlockUI>
      <Dialog
        visible={createProductModel}
        modal
        header={t("productModelInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setCreateProductModel(false);
          // setServiceData(emptyServiceData);
          setProductModel(emptyProductModelData);
        }}
      >
        <EditProductModelContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              // letterSpacing: "0.5px",
              marginBottom: "0.5rem",
            }}
          >
          </div>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="productModelName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.name}
                  maxLength={100}
                  onChange={(e) =>
                    setProductModel({ ...productModel, name: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelName">
                  {t("productModelName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelBrandName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.brandName}
                  maxLength={100}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      brandName: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelBrandName"
                >
                  {t("productModelBrandName")} (*)
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <InputText
                  id="productModelManufacturer"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.manufacturer}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      manufacturer: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelManufacturer"
                >
                  {t("productModelManufacturer")} (*)
                </label>
              </div> */}
              <div className="p-float-label input-container">
                <InputText
                  id="productModelSector"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.sector}
                  maxLength={50}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      sector: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelSector"
                >
                  {t("productModelSector")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelCategory"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.productCategory}
                  maxLength={100}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      productCategory: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelCategory"
                >
                  {t("productModelCategory")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelType"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.productType}
                  maxLength={100}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      productType: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelType"
                >
                  {t("productModelType")} (*)
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <InputText
                  id="productModelCountry"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.country}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      country: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelCountry"
                >
                  {t("productModelCountry")} (*)
                </label>
              </div> */}
              <div className="p-float-label input-container">
                <InputText
                  id="productModelEan"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.ean}
                  maxLength={50}
                  onChange={(e) =>
                    setProductModel({ ...productModel, ean: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelEan">
                  {t("productModelEan")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelMpn"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.mpn}
                  maxLength={50}
                  onChange={(e) =>
                    setProductModel({ ...productModel, mpn: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelMpn">
                  {t("productModelMpn")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelCode"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.code}
                  maxLength={100}
                  onChange={(e) =>
                    setProductModel({ ...productModel, code: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelCode">
                  {t("productModelCode")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelGtin"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.gtin}
                  maxLength={50}
                  onChange={(e) =>
                    setProductModel({ ...productModel, gtin: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelGtin">
                  {t("productModelGtin")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelDescription"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.description}
                  maxLength={200}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      description: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelDescription"
                >
                  {t("productModelDescription")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelImageURI"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={productModel?.imageUri}
                  maxLength={255}
                  onChange={(e) =>
                    setProductModel({
                      ...productModel,
                      imageUri: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="productModelImageURI">
                  {t("productModelImageURI")}
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitProductModelCreation}
          />
        </EditProductModelContainer>
      </Dialog>
      <Dialog
        visible={editProductModel}
        modal
        header={t("productModelInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setEditProductModel(false);
          // setServiceData(emptyServiceData);
          // setProductModel({});
          setSingularProductModel({})
        }}
      >
        <EditProductModelContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          >
          </div>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="productModelName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.name}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularProductModel({ ...singularProductModel, name: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelName">
                  {t("productModelName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelBrandName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.brandName}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      brandName: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelBrandName"
                >
                  {t("productModelBrandName")} (*)
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <InputText
                  id="productModelManufacturer"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.manufacturer}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      manufacturer: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelManufacturer"
                >
                  {t("productModelManufacturer")} (*)
                </label>
              </div> */}
              <div className="p-float-label input-container">
                <InputText
                  id="productModelSector"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.sector}
                  maxLength={50}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      sector: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelSector"
                >
                  {t("productModelSector")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelCategory"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.productCategory}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      productCategory: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelCategory"
                >
                  {t("productModelCategory")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelType"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.productType}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      productType: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelType"
                >
                  {t("productModelType")} (*)
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <InputText
                  id="productModelCountry"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.country}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      country: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelCountry"
                >
                  {t("productModelCountry")} (*)
                </label>
              </div> */}
              <div className="p-float-label input-container">
                <InputText
                  id="productModelEan"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.ean}
                  maxLength={50}
                  onChange={(e) =>
                    setSingularProductModel({ ...singularProductModel, ean: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelEan">
                  {t("productModelEan")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelMpn"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.mpn}
                  maxLength={50}
                  onChange={(e) =>
                    setSingularProductModel({ ...singularProductModel, mpn: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelMpn">
                  {t("productModelMpn")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelCode"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.code}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularProductModel({ ...singularProductModel, code: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelCode">
                  {t("productModelCode")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelGtin"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.gtin}
                  maxLength={50}
                  onChange={(e) =>
                    setSingularProductModel({ ...singularProductModel, gtin: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelGtin">
                  {t("productModelGtin")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelDescription"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.description}
                  maxLength={200}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      description: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="productModelDescription"
                >
                  {t("productModelDescription")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelImageURI"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularProductModel?.imageUri}
                  maxLength={255}
                  onChange={(e) =>
                    setSingularProductModel({
                      ...singularProductModel,
                      imageUri: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="productModelImageURI">
                  {t("productModelImageURI")}
                </label>
              </div>

              <div className="p-float-label input-container">
                <Dropdown
                  id="productModelStatus"
                  className="w-full p-inputtext-sm"
                  optionLabel="statusTypeName"
                  optionValue="statusTypeCode"
                  placeholder={statusInfo(singularProductModel?.status)}
                  value={singularProductModel?.status}
                  options={statusTypesList}
                  // editable
                  onChange={(e) =>
                    setSingularProductModel({ ...singularProductModel, status: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="productModelStatus">
                  {t("productModelStatus")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitProductModelEdit}
          />
        </EditProductModelContainer>
      </Dialog>
      <Dialog
        visible={viewProductModel}
        modal
        header={t("productModelInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewProductModel(false);
          // setServiceData(emptyServiceData);
          // setProductModel(emptyProductModelData);
          setSingularProductModel({})
        }}
      >
        <ViewProductModelContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          >
          </div>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="productModelName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.name}
                />
                <label className="platformColor" htmlFor="productModelName">
                  {t("productModelName")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelBrandName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.brandName}
                />
                <label
                  className="platformColor"
                  htmlFor="productModelBrandName"
                >
                  {t("productModelBrandName")}
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <InputText
                  id="productModelManufacturer"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.manufacturer}
                />
                <label
                  className="platformColor"
                  htmlFor="productModelManufacturer"
                >
                  {t("productModelManufacturer")}
                </label>
              </div> */}
              <div className="p-float-label input-container">
                <InputText
                  id="productModelSector"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.sector}
                />
                <label
                  className="platformColor"
                  htmlFor="productModelSector"
                >
                  {t("productModelSector")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelCategory"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.productCategory}
                />
                <label
                  className="platformColor"
                  htmlFor="productModelCategory"
                >
                  {t("productModelCategory")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelType"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.productType}
                />
                <label
                  className="platformColor"
                  htmlFor="productModelType"
                >
                  {t("productModelType")}
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <InputText
                  id="productModelCountry"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.country}
                />
                <label
                  className="platformColor"
                  htmlFor="productModelCountry"
                >
                  {t("productModelCountry")}
                </label>
              </div> */}
              <div className="p-float-label input-container">
                <InputText
                  id="productModelEan"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.ean}
                />
                <label className="platformColor" htmlFor="productModelEan">
                  {t("productModelEan")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelMpn"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.mpn}
                />
                <label className="platformColor" htmlFor="productModelMpn">
                  {t("productModelMpn")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelCode"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.code}
                />
                <label className="platformColor" htmlFor="productModelCode">
                  {t("productModelCode")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelGtin"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.gtin}
                />
                <label className="platformColor" htmlFor="productModelGtin">
                  {t("productModelGtin")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelDescription"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.description}
                />
                <label
                  className="platformColor"
                  htmlFor="productModelDescription"
                >
                  {t("productModelDescription")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="productModelImageURI"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularProductModel?.imageUri}
                />
                <label className="platformColor" htmlFor="productModelImageURI">
                  {t("productModelImageURI")}
                </label>
              </div>

              <div className="p-float-label input-container">
                {/* <div className="chip-holder"> */}
                <p className="chip-title">{t("productModelStatus")}</p>
                {actionTemplateStatus(singularProductModel)}
              </div>
              <div>
                {singularProductModel?.documents?.length > 0 && (
                  <DataTable
                    name="dt"
                    size="small"
                    showGridlines
                    value={singularProductModel?.documents}
                    dataKey="id"
                    responsiveLayout="scroll"
                    emptyMessage={t("noProductModelDocumentsFound")}
                    paginator
                    rows={5}
                    loading={loading}
                  >
                    <Column
                      field="artifactTitle"
                      header={t("productModelDocumentArtifactTitle")}
                    ></Column>
                    <Column
                      field="artifactPublicUrl"
                      header={t("productModelDocumentArtifactPublicUrl")}
                    ></Column>
                    <Column
                      field="artifactLocalUrl"
                      header={t("productModelDocumentArtifactLocalUrl")}
                    ></Column>
                    <Column
                      field="description"
                      header={t("productModelDocumentDescription")}
                    ></Column>
                    <Column
                      field={documentKeywordTemplate}
                      header={t("productModelDocumentKeyword")}
                    ></Column>
                    <Column
                      field={documentCreationtimeTemplate}
                      header={t("productModelCreationTime")}
                    ></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </form>
        </ViewProductModelContainer>
      </Dialog> 
      <Dialog
        visible={mdcVisible}
        header={t("sendMDCRequest")}
        onHide={() => {setMDCVisible(false)}}
      >
        <MDCDialog/>        
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ProductModelsContainer>
  );
};

export default withTranslation()(ProductModels);
