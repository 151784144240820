import styled from "@emotion/styled";
export const TutorialContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;

  .tutorial-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    .tutorial-card {
      margin-bottom: 1rem;
      padding-bottom: 0.25rem;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      width: 350px;
      heigth: 450px;
      background-color: #f4f4f4;
      border-radius: 4px;
      border-style: solid;
      border-width: thin;
      border-color: #382c7c;

      .tutorial-cover-img {
        min-width: 120px;
        max-width: 345px;
        border-radius: 4px;
        border-style: solid;
        border-width: thin;
        border-color: #382c7c;
        
      }
      .tutorial-card-title {
        padding-top:0.25rem;
        padding-bottom:0.25rem;
      }
      .tutorial-cardbtn {
        background-color: #382c7c;
        border-radius: 6px;
        border-style: solid;
        border-color: #382c7c;
        border-width: thin;
        &:hover {
          background-color: #f72685;
          border-radius: 6px;
          border-style: solid;
          border-color: #f72685;
          border-width: thin;
        }
      }
    }
  @media only screen and (max-width: 950px) {
    .tutorial-cards-container {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .tutorial-card{
      width: 250px;
      heigth: 400px;
    }
      .tutorial-cover-img {
        min-width: 120px;
        width: 246px;
        border-radius: 4px;
        border-style: solid;
        border-width: thin;
        border-color: #382c7c;
        
      }
  
  }

  @media only screen and (max-width: 576px) {
    .tutorial-cards-container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .tutorial-card {
      width: 250px;
      heigth: 400px;
      .tutorial-cover-img {
        min-width: 120px;
        width: 246px;
        border-radius: 4px;
        border-style: solid;
        border-width: thin;
        border-color: #382c7c;
        
      }
    }
  }

  @media only screen and (max-width: 420px) {
    .tutorial-cards-container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .tutorial-card {
      width: 200px;
      heigth: 400px;
      .tutorial-cover-img {
        min-width: 120px;
        width: 196px;
        border-radius: 4px;
        border-style: solid;
        border-width: thin;
        border-color: #382c7c;
        
      }
    }
  }


  }

  .api-doc-left-menu-opener-btn {
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    min-width: 162px;
  }

  .toTutorialPageButton {
    background-color: #382c7c;
    border-radius: 6px;
    border-style: solid;
    border-color: #382c7c;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;
