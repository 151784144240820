import axios from "axios";
import config from "../../config";

const apiosErionAuth = axios.create({
  baseURL: config.erionApiAuthUrl,
  timeout: 30000,
  timeoutErrorMessage: "Connection timeout. Please try again",
  headers: { 'Content-type': 'application/x-www-form-urlencoded' },
});

const apiosErionTransports = axios.create({
  baseURL: config.erionApiTransportsUrl,
  timeout: 30000,
  timeoutErrorMessage: "Connection timeout. Please try again"
});

export {apiosErionAuth,apiosErionTransports};
