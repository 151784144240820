import {
  ProvidersContainer,
  CreateProviderDialogContainer,
  ViewProviderDialogContainer,
  EditProviderDialogContainer
} from "./assets/ProvidersContainer.style";
import { OrganisationAddContainer } from "./assets/OrganisationsContainer.style";
import { withTranslation, Trans } from "react-i18next";
import React, { useEffect, useState } from "react";
import SysService from "../functionalities/services/sys.service";
import WaitDialog from "../components/WaitDialog";
import { Dropdown } from "primereact/dropdown";
import {providerTypesList ,statusTypesList } from "../data/ct-data";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Chip } from "primereact/chip";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { useNavigate } from "react-router-dom";
import { isCTAdmin } from "../functionalities/common/token-check";

const emptyProviderData = {
  providerName: "",
  providerOrganisationName: "",
  // providerType: "",
  providerEndPoint: "",
  providerApiEndPoint: "",
};

const Providers = ({ t }) => {
  const [providers, setProviders] = useState([]);
  const [providerData, setProviderData] = useState(emptyProviderData);
  const [loading, setLoading] = useState(false);
  const [singularProvider, setSingularProvider] = useState({});
  const [createProviderVisible, setCreateProviderVisible] = useState(false);
  const [editProviderVisible, setEditProviderVisible] = useState(false);
  const [viewProviderVisible, setViewProviderVisible] = useState(false);
  const navigate = useNavigate();

  const submitProviderCreation = () => {
    setLoading(true);
    if (
      providerData.providerName === "" ||
      providerData.providerOrganisationName === "" ||
      // providerData.providerType === "" ||
      providerData.providerEndPoint === ""
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    const providerDTO = {
      providerDTO: {
        name: providerData.providerName,
        organisationName: providerData.providerOrganisationName,
        // type: providerData.providerType,
        endpoint: providerData.providerEndPoint,
        apiEndpoint: providerData.providerApiEndPoint,
      },
    };
   
    SysService.addProvider(providerDTO).then(
      (response) => {
        if (response.status === 200) {
       
          toast.success(<Trans i18nKey={"successProviderCreation"} />);
          SysService.getProviders().then(
            (response) => {
              setProviders(response?.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response?.data &&
                  error.response?.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();         
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

      
      }
    );
    setLoading(false);
    setCreateProviderVisible(false);
    setProviderData(emptyProviderData);
    return;
  };
  const submitProviderEdit = () =>{
    setLoading(true);
    if (
      singularProvider.name === "" ||
      singularProvider.organisationName === "" ||
      // singularProvider.type === "" ||
      singularProvider.endpoint === ""
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    const providerDTO = {
      providerDTO: {
        id: singularProvider.id,
        name: singularProvider.name,
        organisationName: singularProvider.organisationName,
        // type: singularProvider.type,
        endpoint: singularProvider.endpoint,
        apiEndpoint: singularProvider.apiEndpoint,
        status: singularProvider.status
      },
    };
  
    SysService.updateProvider(providerDTO).then(
      (response) => {
        if (response.status === 200) {
        
          toast.success(<Trans i18nKey={"successProviderUpdate"} />);
          SysService.getProviders().then(
            (response) => {
            
              setProviders(response?.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response?.data &&
                  error.response?.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
      }
    );
    setLoading(false);
    setEditProviderVisible(false);
    setSingularProvider({})
    // setProviderData(emptyProviderData)
    return;
  }
  const getProviderInfo = (id) => {
    SysService.getProviderById(id).then(
      (response) => {
     
        setSingularProvider(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
          //toast eksik
      }
    );
  };

  const statusInfo = (statData) => {
    return t("STATUS_" + statData);
  }
  const typeListTemplate = (data) => {
    return (
      <>
        <div>
          {(() => {
            switch (data?.type) {
              case "Dpp":
                return (
                  <Chip
                    label={data?.type}
                    className="mr-2 mb-2"
                    style={{backgroundColor: "#72bfbf",color: "#fff"}}
                  />
                );
              case "Dpo":
                return (
                  <Chip
                    label={data?.type}
                    className="mr-2 mb-2"
                    style={{backgroundColor: "#83bf4f", color: "#fff"}}
                  />
                );
              default:
                return "";
            }
          })()}
        </div>
      </>
    );
  };
  const actionTemplateStatus = (data) => {
    return (
      <Chip
        label={t("STATUS_" + data.status)}
        className="mr-2 mb-2"
        style={{backgroundColor: "#382c7c" ,color:"#fff"}}
      />
    );
  };
  const actionTemplate = (data) => {
      return (
        <div>
          <Button
            icon="pi pi-pencil"
            tooltip={t("edit")}
            className="p-button-rounded p-button-text"
            onClick={() => {setEditProviderVisible(true);getProviderInfo(data.id);}}
            id="buttonFontWeight"
          />
          <Button
            icon="pi pi-exclamation-circle"
            tooltip={t("inquire")}
            className="p-button-rounded p-button-text"
            onClick={() => {
              setViewProviderVisible(true);
              getProviderInfo(data.id);
            }}
            id="buttonFontWeight"
          />
        </div>
      );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    if(isCTAdmin()){
      SysService.getProviders().then(
        (response) => {
          setProviders(response?.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
        }
      );
    }
    else{
      navigate("/landing");
    }
    
  }, []);
  return (
    <ProvidersContainer>
      <h2>{t("providersPage")}</h2>
      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("createAProvider")}
          onClick={() => setCreateProviderVisible(true)}
        />
      </OrganisationAddContainer>
      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={providers}
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noProvidersFound")}
              paginator
              rows={10}
              loading={loading}
            >
              <Column
                field="name"
                header={t("providerPlatformName")}
              ></Column>
              <Column
                field="organisationName"
                header={t("providerOrganisationName")}
              ></Column>
              {/* <Column
                field={typeListTemplate}
                header={t("providerType")}
              ></Column> */}
              <Column field="endpoint" header={t("providerEndPoint")}></Column>
              <Column field="apiEndpoint" header={t("providerApiEndPoint")}></Column>
              <Column
                body={actionTemplateStatus}
                header={t("status")}
              ></Column>
              <Column
                header={t("action")}
                style={{ textAlign: "center", width: "8em" }}
                body={actionTemplate}
              />
            </DataTable>
          </div>
        </div>
      </BlockUI>
      <Dialog
        visible={createProviderVisible}
        modal
        header={t("createAProvider")}
        className="p-fluid dialog-size"
        onHide={() => {
          setCreateProviderVisible(false);
          setProviderData(emptyProviderData);
        }}
      >
        <CreateProviderDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          >
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="providerName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={providerData?.providerName}
                  maxLength={100}
                  onChange={
                    (e) =>
                      setProviderData({
                        ...providerData,
                        providerName: e.target.value,
                      })
                  }
                />
                <label className="platformColor" htmlFor="providerName">
                  {t("pleaseEnterDPPProviderPlatformName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="providerOrganisationName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={providerData.providerOrganisationName}
                  maxLength={100}
                  onChange={(e) =>
                    setProviderData({
                      ...providerData,
                      providerOrganisationName: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="providerOrganisationName"
                >
                  {t("pleaseEnterProviderOrganisationName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="providerEndPoint "
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={providerData.providerEndPoint}
                  maxLength={255}
                  onChange={(e) =>
                    setProviderData({
                      ...providerData,
                      providerEndPoint: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="providerEndPoint">
                  {t("pleaseEnterProviderEndPoint")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="providerApiEndPoint "
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={providerData.providerApiEndPoint}
                  maxLength={255}
                  onChange={(e) =>
                    setProviderData({
                      ...providerData,
                      providerApiEndPoint: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="providerApiEndPoint">
                  {t("pleaseEnterProviderApiEndPoint")}
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <Dropdown
                  // providerTypesList
                  id="providerType"
                  options={providerTypesList}
                  value={providerData?.providerType}
                  optionLabel="providerTypeName"
                  optionValue="providerTypeName"
                  onChange={(e) =>
                    setProviderData({
                      ...providerData,
                      providerType: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="providerType">
                  {t("pleaseEnterProviderType")} (*)
                </label>
              </div> */}
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitProviderCreation}
          />
        </CreateProviderDialogContainer>
      </Dialog>
      <Dialog
        visible={editProviderVisible}
        modal
        header={t("providerInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setEditProviderVisible(false);
          setSingularProvider({})
        }}
      >
        <div
          className="px-2 platformColor"
          id="dialogTitle"
          style={{
            fontSize: "16px",
            lineHeight: "18px",
            fontWeight: "700",
            marginBottom: "0.5rem",
          }}
        >
        </div>

        <EditProviderDialogContainer>
        <form>
          <div className="flex align-items-center gap-3 ">
            <div className="p-float-label input-container">
              <InputText
                id="providerName"
                type="text"
                className="p-inputtext-sm w-full view-container"
                value={singularProvider?.name}
                maxLength={100}
                onChange={(e) =>
                  setSingularProvider({
                    ...singularProvider,
                    name: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="providerName">
                {t("providerPlatformName")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="providerOrganisationName"
                type="text"
                className="p-inputtext-sm w-full view-container"
                value={singularProvider?.organisationName}
                maxLength={100}
                onChange={(e) =>
                  setSingularProvider({
                    ...singularProvider,
                    organisationName: e.target.value,
                  })
                }
              />
              <label
                className="platformColor"
                htmlFor="providerOrganisationName"
              >
                {t("providerOrganisationName")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="endpoint"
                type="text"
                className="p-inputtext-sm w-full view-container"
                value={singularProvider?.endpoint}
                maxLength={255}
                onChange={(e) =>
                  setSingularProvider({
                    ...singularProvider,
                    endpoint: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="endpoint">
                {t("providerEndPoint")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="apiEndpoint"
                type="text"
                className="p-inputtext-sm w-full view-container"
                value={singularProvider?.apiEndpoint}
                maxLength={255}
                onChange={(e) =>
                  setSingularProvider({
                    ...singularProvider,
                    apiEndpoint: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="apiEndpoint">
                {t("providerApiEndPoint")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <Dropdown
                id="providerStatus"
                className="w-full p-inputtext-sm"
                optionLabel="statusTypeName"
                optionValue="statusTypeCode"
                placeholder={statusInfo(singularProvider?.status)}
                value={singularProvider?.status}
                options={statusTypesList}
                // editable
                onChange={(e) =>
                  setSingularProvider({
                    ...singularProvider,
                    status: e.target.value,
                  })
                }
              />

              <label className="platformColor" htmlFor="providerStatus">
                {t("providerStatus")} (*)
              </label>
            </div>
            {/* <div className="p-float-label input-container">
              <Dropdown
                id="providerType"
                className="w-full p-inputtext-sm"
                optionLabel="providerTypeName"
                optionValue="providerTypeName"
                placeholder={singularProvider?.type}
                value={singularProvider?.type}
                options={providerTypesList}
                onChange={(e) =>
                  setSingularProvider({
                    ...singularProvider,
                    type: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="type">
                {t("providerType")} (*)
              </label>
            </div> */}
          </div>
        </form>
        <Button
          className="submit-button"
          label={t("submit")}
          onClick={submitProviderEdit}
        />
        </EditProviderDialogContainer>
      </Dialog>
      <Dialog
        visible={viewProviderVisible}
        modal
        header={t("providerInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewProviderVisible(false);
          setSingularProvider({})
        }}
      >
        <div
          className="px-2 platformColor"
          id="dialogTitle"
          style={{
            fontSize: "16px",
            lineHeight: "18px",
            fontWeight: "700",
            marginBottom: "0.5rem",
          }}
        >
        </div>
        <ViewProviderDialogContainer>
        <form>
          <div className="flex align-items-center gap-3 ">
            <div className="p-float-label input-container">
              <InputText
                id="providerName"
                type="text"
                className="p-inputtext-sm w-full view-container"
                disabled
                value={singularProvider?.name}
              />
              <label className="platformColor" htmlFor="providerName">
                {t("providerPlatformName")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="providerOrganisationName"
                type="text"
                className="p-inputtext-sm w-full view-container"
                disabled
                value={singularProvider?.organisationName}
              />
              <label
                className="platformColor"
                htmlFor="providerOrganisationName"
              >
                {t("providerOrganisationName")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="endpoint"
                type="text"
                className="p-inputtext-sm w-full view-container"
                disabled
                value={singularProvider?.endpoint}
              />
              <label className="platformColor" htmlFor="endpoint">
                {t("providerEndPoint")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="apiEndpoint"
                type="text"
                className="p-inputtext-sm w-full view-container"
                disabled
                value={singularProvider?.apiEndpoint}
              />
              <label className="platformColor" htmlFor="apiEndpoint">
                {t("providerApiEndPoint")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="registrationTime"
                type="text"
                className="p-inputtext-sm w-full view-container"
                disabled
                value={GeneralFunctions.getDateTemplateV2(
                  singularProvider?.registrationTime
                )}
              />
              <label className="platformColor" htmlFor="registrationTime">
                {t("providerRegistrationTime")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="lastModificationTime"
                type="text"
                className="p-inputtext-sm w-full view-container"
                disabled
                value={GeneralFunctions.getDateTemplateV2(
                  singularProvider?.lastModificationTime
                )}
              />
              <label className="platformColor" htmlFor="lastModificationTime">
                {t("providerLastModificationTime")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <p className="chip-title">{t("providerStatus")}</p>
              {actionTemplateStatus(singularProvider)}
            </div>
            {/* <div className="p-float-label input-container">
              <p className="chip-title">{t("providerType")}</p>

              {typeListTemplate(singularProvider)}

            </div> */}
          </div>
        </form>
        </ViewProviderDialogContainer>

      </Dialog>

      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ProvidersContainer>
  );
};

export default withTranslation()(Providers);
