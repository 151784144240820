const config = {
    backendApiUrl: window.RUNTIME_CONFIG.BACKEND_API_URL || process.env.REACT_APP_BACKEND_API_URL,
    cartifApiUrl: window.RUNTIME_CONFIG.CARTIF_API_URL || process.env.CARTIF_APP_ADDRESS,
    erionApiAuthUrl: process.env.REACT_APP_ERION_API_AUTH_ADDRESS,
    erionApiTransportsUrl: process.env.REACT_APP_ERION_API_TRANSPORTS_ADDRESS,
    erionApiClientId: process.env.REACT_APP_ERION_API_CLIENT_ID,
    erionApiClientSecret: process.env.REACT_APP_ERION_API_CLIENT_SECRET,
    erionApiResource: process.env.REACT_APP_ERION_API_RESPONSE,
    erionApiScope: process.env.REACT_APP_ERION_API_SCOPE,
  };
  
export default config;
  