import axios from "axios";
import React, { act, useEffect, useRef, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast, Slide } from "react-toastify";
import WaitDialog from "../components/WaitDialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Chip } from "primereact/chip";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import APIService from "../functionalities/services/api.service";
import ApiRequestDetails from "../components/ApiRequestDetails";
import { ServiceEmbedContainer, ServiceEmbedDialogContainer,CustomEndpointDialogContainer } from "./assets/ServiceEmbedContainer.style";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { giveUsername } from "../functionalities/common/token-check";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BlockUI } from "primereact/blockui";
import { useNavigate } from "react-router-dom";
import { OverlayPanel } from 'primereact/overlaypanel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { BsFiletypeJson,BsFiletypePdf,BsFiletypeTxt } from "react-icons/bs";
import { Checkbox } from 'primereact/checkbox';

const emptyAuthInfo = {
  username: "",
  password: ""
}
const emptyTokenObject = {
  tokenData: "",
}

const ServiceEmbed = ({ t, serviceData, responsiveW, responsiveH , selectedPM, setSelectedPM, rsid }) => {
  const [loading, setLoading] = useState(false);
  const [authNeedVisible, setAuthNeedVisible] = useState(false);
  const [endpoints, setEndpoints] = useState([]);
  const [paramsVisible, setParamsVisible] = useState(false);
  const [requestParams, setRequestParams] = useState([]);
  const [singularParam, setSingularParam] = useState([]);
  const [ssoComplete, setSsoComplete] = useState(false);
  const [token, setToken] = useState(emptyTokenObject);
  const [requestResultVisible, setRequestResultVisible] = useState(false);
  const [requestResult, setRequestResult] = useState({});
  const [resultKeys, setResultKeys] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeParams, setActiveParams] = useState([]);
  const [authInfo, setAuthInfo] = useState(emptyAuthInfo);
  const [activeEndpoint, setActiveEndpoint] = useState({});

  const [activeRequestParameters, setActiveRequestParameters] = useState({});
  const [activeRequestBody, setActiveRequestBody] = useState({});
  // const [paramStr, setParamStr] = useState("");
  const [parameters, setParameters] = useState([]);
  const [customAPIEndpointVisible, setCustomAPIEndpointVisible] = useState(false);
  const [authActiveIndex, setAuthActiveIndex] = useState(0);
  const [username, setUsername] = useState("");
  const [oid, setOid] = useState({});
  const [rsUserInfo, setRsUserInfo] = useState({});
  const [resultRecords, setResultRecords] = useState([]);
  const [resultRecordsVisible, setResultRecordsVisible] = useState(false);
  const [serviceResultIsLCA, setServiceResultIsLCA] = useState(false);
  const [lcaCheckDetailEnabled, setLcaCheckDetailEnabled] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null)

  const [singularRecord, setSingularRecord] = useState("");
  const navigate = useNavigate();
  const refObjCode = useRef(null);
  const refObjDownload = useRef(null);
  const refObjDownloadServiceResult = useRef(null);

  const[singularRecordId, setSingularRecordId] = useState(0);


  const handleMouseEnter = () => {
    setDelayHandler(setTimeout(() => {
        setLcaCheckDetailEnabled(true)
    }, 1350))
}

const handleMouseLeave = () => {
    setLcaCheckDetailEnabled(false);
    clearTimeout(delayHandler)
}


  const addParameters = (endPoint, parameterObj) => {
    let _paramKeys = Object.keys(parameterObj)
    
    for(let i = 0; i<_paramKeys.length;i++){
      endPoint = endPoint.replace(_paramKeys[i],parameterObj[_paramKeys[i]])
    }
    endPoint = endPoint.replaceAll('{','');
    endPoint = endPoint.replaceAll('}','');

    // console.log(endPoint)

    return endPoint;
  }

  const handleParameters = (data) => {
    let _paramStr = data;
    while(_paramStr.indexOf('{') !== -1 && _paramStr.indexOf('}') !== -1){
        parameters.push(_paramStr.slice(_paramStr.indexOf('{')+1,_paramStr.indexOf('}')));
        _paramStr = _paramStr.slice(_paramStr.indexOf('}')+1);
    }
  }

  const handleRequestDetails = () => {
    // console.log("request details")
    // console.log(activeRequestParameters)
    // console.log(activeRequestBody)
    if(Object.keys(activeRequestBody).length > 0 &&activeRequestBody !== undefined && activeRequestBody !== null && activeRequestBody !== ""){

      try{
          // console.log(JSON.parse(activeRequestBody))
          setActiveRequestBody(JSON.parse(activeRequestBody))
        
        return
      }
      catch(error){
        toast.error(<Trans i18nKey={"errorRequestBodyFaulty"}/>)
        return
      }
    }
  }
  const submitCredentialsSave = (type) => {
    setLoading(true);

    const registeredServiceUserInfoMappingDTO = {
      registeredServiceUserInfoMappingDTO : {
        ctUserEmail: username,
        registeredService:serviceData,
      }
    }
    if(type === 1){
      registeredServiceUserInfoMappingDTO.registeredServiceUserInfoMappingDTO.savedToken = token?.tokenData;
    }else if(type === 0) {
      registeredServiceUserInfoMappingDTO.registeredServiceUserInfoMappingDTO.savedCredentials = JSON.stringify(authInfo);
    }else {
      setLoading(false);
      return;
    }

    SysService.addRegisteredServiceUserInfo(registeredServiceUserInfoMappingDTO).then(
      (response) => {
        // toast.success(<Trans i18nKey="successServiceUserInfoSave"/>);
        toast.success(<Trans i18nKey={response?.data?.responseMessage}/>);
        SysService.getRegisteredServiceUserInfo(username, serviceData?.id).then(
          (response) => {
            if (response.status === 200) {
              if(response?.data !== ""){
                const _rspData = response?.data
                if(_rspData.savedCredentials !== undefined &&  _rspData.savedCredentials !== null && _rspData.savedCredentials !== "" ){
                  // setRsUserInfo({...rsUserInfo,parsedCredentials : JSON.parse(_rspData.savedCredentials)})
                  _rspData.parsedCredentials = JSON.parse(_rspData.savedCredentials);
                }
                setRsUserInfo(_rspData);
              }
            } else {
              toast.error(response.status);
            }setLoading(false);
          },
          (error) => {
            const errorMessage =
              (error.response &&
                error.response?.data &&
                error.response?.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString();
          
            toast.error(errorMessage);
            setLoading(false);
          }
        );
      },
      (error) => {
        console.log(error)
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        toast.error(<Trans i18nKey="failServiceUserInfoSave"/>);
        setLoading(false);
      }
    )
  }
  const resetCredentialsSave = (type) => {
    setLoading(true);
    const registeredServiceUserInfoMappingDTO = {
      registeredServiceUserInfoMappingDTO : {
        ctUserEmail: username,
        registeredService:serviceData,
      }
    }
    if(type === "token"){
      registeredServiceUserInfoMappingDTO.registeredServiceUserInfoMappingDTO.savedToken = "";
    }else if(type === "credentials") {
      registeredServiceUserInfoMappingDTO.registeredServiceUserInfoMappingDTO.savedCredentials = "";
    }else {
      setLoading(false);
      return;
    }
   
    SysService.addRegisteredServiceUserInfo(registeredServiceUserInfoMappingDTO).then(
      (response) => {
        if(response.status === 200) {
          toast.success(<Trans i18nKey="successServiceUserInfoReset"/>);
          // setLoading(false);
          SysService.getRegisteredServiceUserInfo(username, serviceData?.id).then(
            (response) => {
              if (response.status === 200) {
                if(response?.data !== ""){
                  const _rspData = response?.data
                  if(_rspData.savedCredentials !== undefined &&  _rspData.savedCredentials !== null && _rspData.savedCredentials !== "" ){
                    // setRsUserInfo({...rsUserInfo,parsedCredentials : JSON.parse(_rspData.savedCredentials)})
                    _rspData.parsedCredentials = JSON.parse(_rspData.savedCredentials);
                  }
                  console.log(_rspData)
                  setRsUserInfo(_rspData);
  
                  if(_rspData?.savedCredentials === "" && _rspData?.savedToken === "" ){
                    setAuthActiveIndex(0);
                  }
                }
                console.log(response?.data);            
              } else {
                toast.error(response.status);
              }setLoading(false);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response?.data &&
                  error.response?.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
            
              toast.error(errorMessage);
              setLoading(false);
            }
          );
        }

      },
      (error) => {
        console.log(error)
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        toast.error(<Trans i18nKey="failServiceUserInfoReset"/>);
        setLoading(false);
      }
    )
  }
  
  const submitSSO = (activeIndex) => {
    setLoading(true);
    if(activeIndex === 0) {
      if(authInfo?.username === "" || authInfo?.password === ""){
        setLoading(false);
        toast.warning(<Trans i18nKey="warnAuth1" />);
        return;
  
      }
      APIService.serviceTokenRequest(serviceData?.serviceAuthUrl,authInfo).then(
        (response) => {
          console.log(response?.data)
          setToken(response?.data);//?
          setLoading(false);
          setAuthNeedVisible(false);
  
        },
        (error) => {
          console.log(error)
          setToken(error)
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
          toast.error(<Trans i18nKey="failServiceAuth"/>)
          setLoading(false);
          
        }
      )
      // setAuthNeedVisible(false);
      // setAuthInfo(emptyAuthInfo);
    }
    else if(activeIndex === 1){
      setLoading(false);
      setAuthNeedVisible(false);
    }

  }
  const submitParameters = (data,index) => {
    //turn singular param array to json
    // requestParams[index] = data;
    setParamsVisible(false);

    setParameters([]);
  }
  const submitRequest = (method,endPoint,i) => {
    setRequestResultVisible(true);
    setLoading(true);

    // console.log(activeRequestParameters)
    // console.log(activeRequestBody)

    let _endPoint = ""
    if(!(endPoint[0] === '/')){
      _endPoint = '/'+endPoint;
    }
    else{
      _endPoint = endPoint;
    }
    // console.log(_endPoint[_endPoint.length-1])
    if(!(_endPoint[_endPoint.length-1] === '/')){
      _endPoint = _endPoint + '/';
    }


    _endPoint = addParameters(_endPoint, activeRequestParameters)

    // console.log(method.toLowerCase(),endPoint)
    // console.log(serviceData?.endpoint+_endPoint)
    // console.log(requestParams[i])
    console.log(serviceData?.endpoint+_endPoint)
    APIService.serviceApiRequest(
      serviceData?.endpoint,
      method.toLowerCase(),
      _endPoint,
      requestParams,
      activeRequestBody,
      token
    )
      .then(function (response) {
        console.log(response);
        console.log(Object.keys(response?.data))
        setRequestResult(response);
        setResultKeys(Object.keys(response?.data))
      })
      .catch(function (error) {
        console.log(error);
        setRequestResult(error);
      })
      .finally(function () {
        // always executed
      });  

    setLoading(false);

  }
  const handleResultStatus = (data) => {
    if(data < 200 || ( data >= 300 && data < 400) ){
      return (
          <Chip
            label={data}
            className="method-chip"
            style={{
              backgroundColor: "#61affe",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
              fontSize: "14px",
              color: "#fff",
            }}
          />
      );
    }
    else if( data >= 200 && data < 300){
      return (
          <Chip
            label={data}
            className="method-chip"
            style={{
              backgroundColor: "#49cc90",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
              fontSize: "14px",
              color: "#fff",
            }}
          />
      );
    }
    else if(data > 400){
      return (
          <Chip
            label={data}
            className="method-chip"
            style={{
              backgroundColor: "#ff2c2c",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
              fontSize: "14px",
              color: "#fff",
            }}
          />
      );
    }
  }
  const handleResultData = (data) => {
    return JSON.stringify(data, undefined, 2);
  }
  
  const methodTemplate = (data) => {
    let m = "";
    if(data?.registeredServiceEndpointMethod !== undefined && data?.registeredServiceEndpointMethod !== null) { m = data?.registeredServiceEndpointMethod.toLowerCase();}
    switch (m) {
      case "get":
        return (
            <Chip
              label={"GET"}
              className="method-chip"
              style={{
                backgroundColor: "#61affe",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
      case "post":
        return (
            <Chip
              label={"POST"}
              className="method-chip"
              style={{
                backgroundColor: "#49cc90",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
      case "put":
        return (
            <Chip
              label={"PUT"}
              className="method-chip"
              style={{
                backgroundColor: "#fca434",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
      case "delete":
        return (
            <Chip
              label={"DELETE"}
              className="method-chip"
              style={{
                backgroundColor: "#f93e3e",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
    }
  }
  const handleMethodType = (data) => {
    switch (data) {
      case "GET":
        return (
          // <div style={{ fontSize: "14px" }}>
            <Chip
              label={"GET"}
              className="method-chip"
              style={{
                backgroundColor: "#61affe",
                marginRight: "0.7rem",
                // paddingRight: "0.7rem",
                width: "60px",
                // marginBottom: "0.7rem",
                fontSize: "14px",
                color: "#fff",
              }}
            />
          //</div> 
        );
      case "POST":
        return (
          // <div style={{ fontSize: "14px" }}>
            <Chip
              label={"POST"}
              className="method-chip"
              style={{
                backgroundColor: "#49cc90",
                marginRight: "0.7rem",
                paddingRight: "0.7rem",
                width: "60px",
                // marginBottom: "0.7rem",
                fontSize: "14px",
                color: "#fff",
              }}
            />
          // </div>
        );
      case "PUT":
        return (
          // <div style={{ fontSize: "14px" }}>
            <Chip
              label={"PUT"}
              className="method-chip"
              style={{
                backgroundColor: "#fca434",
                marginRight: "0.7rem",
                paddingRight: "0.7rem",
                width: "60px",
                // marginBottom: "0.7rem",
                fontSize: "14px",
                color: "#fff",
              }}
            />
          // </div>
        );
      case "DELETE":
        return (
          // <div style={{ fontSize: "14px" }}>
            <Chip
              label={"DELETE"}
              className="method-chip"
              style={{
                backgroundColor: "#f93e3e",
                marginRight: "0.7rem",
                paddingRight: "0.7rem",
                width: "60px",
                // marginBottom: "0.7rem",
                fontSize: "14px",
                color: "#fff",
              }}
            />
          // </div>
        );
    }
  };
  const handleServiceResultRecordSave = (responseData, responseURL, responseMethod) => {
    setLoading(true);
    const registeredServiceResultMappingDTO = {
      registeredServiceResultMappingDTO: {
        ctUserEmail : username,
        organisation: oid,
        registeredService: serviceData,
        registeredServiceEndpoint: responseURL,
        registeredServiceEndpointMethod: responseMethod,
        registeredServiceResult: JSON.stringify(responseData),
        lcaCheck: serviceResultIsLCA
      },
    };
    if( Object.keys(selectedPM).length > 0) {
      registeredServiceResultMappingDTO.registeredServiceResultMappingDTO.productModel = selectedPM;
    }
    SysService.createRegisteredServiceResultRecord(registeredServiceResultMappingDTO).then(
      (response) => {
          if(response.status === 200) {
            toast.success(<Trans i18nKey={"successServiceResultRecordSave"} />)
            setLoading(false);
          }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(errorMessage, <Trans i18nKey={"failServiceResultRecordSave"} />);
        setLoading(false);
      }
    );
  }
  
  const productModelTemplate = (data) => {
    if(data?.productModel !== undefined && data?.productModel !== null){
      return (
        <div>
          <Button
            icon="pi pi-chevron-right"
            tooltip={t("goto")}
            iconPos="right" 
            label={data?.productModel?.name}
            className="p-button-rounded p-button-text"
            onClick={() => {navigate("/pm/" + data?.productModel?.code);}}
            id="buttonFontWeight"
          />
        </div>
       
      );

    }else { return ""}
  }
  const saveTimeTemplate = (data) => {
    //registeredServiceResultSaveTime
    return (GeneralFunctions.getDateTemplateV2(data?.registeredServiceResultSaveTime))
  }
  const resultRecordTemplate = (data) => {

  }
  const actionTemplate = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-code"
          // icon={}
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            setSingularRecord(data?.registeredServiceResult);
            refObjCode.current.toggle(e)
          }}
          id="buttonFontWeight"
        />

        <Button
          icon="pi pi-download"
          tooltip={t("download")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            setSingularRecord(data?.registeredServiceResult);
            refObjDownload.current.toggle(e)
          }}
          id="buttonFontWeight"
        />
        <Button
          icon="pi pi-clone"
          tooltip={t("copyToClipboard")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            if(GeneralFunctions.copyToClipboard(data?.registeredServiceResult)) {
              toast.info(<Trans i18nKey={"infoCopiedToClipboard"}/>);
            } else{
              toast.error(<Trans i18nKey={"failCopiedToClipboard"} />)
            }
          }}
          id="buttonFontWeight"
        />
        {/* <Button
          icon="pi pi-file-export"
          tooltip={t("downloadAsCsv")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            if(GeneralFunctions.copyToClipboard(data?.registeredServiceResult)){
              toast.info(<Trans i18nKey={"infoCopiedToClipboard"}/>);
            }else{
              toast.error(<Trans i18nKey={"failCopiedToClipboard"} />)
            }
          }}
          id="buttonFontWeight"
        /> */}
      </div>
    );
  };
  useEffect(()=>{
    const _tempParams = []
    if(serviceData?.endpointObject !== undefined && serviceData?.endpointObject !== null){
      for(let i = 0; i<JSON.parse(serviceData?.endpointObject).length; i++){
        _tempParams.push({})
      }
      setRequestParams(_tempParams)
    }
    setUsername(giveUsername())
  },[])

  useEffect(() => {
    if (username !== null && username !== undefined && username !== "") {
      SysService.getOrganisationByUsername(username).then(
        (response) => {
          if (response.status === 200) {
            setOid(response?.data);
          } else {
            toast.error(response.status);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
        
          toast.error(errorMessage);
        }
      );

    }
  }, [username]);
  useEffect(()=>{
    if(serviceData?.serviceResultSaveCheck && username !== null && username !== undefined && username !== ""){
      SysService.getRegisteredServiceResultRecords(username,rsid).then(
        (response) => {
          if (response.status === 200) {
            setResultRecords(response?.data);
          } else {
            toast.error(response.status);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
        
          toast.error(errorMessage);
        }
      );
    }
    if(serviceData?.id && username !== null && username !== undefined && username !== ""){
      SysService.getRegisteredServiceUserInfo(username, serviceData?.id).then(
        (response) => {
          if (response.status === 200) {
            if(response?.data !== ""){
              const _rspData = response?.data
              if(_rspData.savedCredentials !== undefined &&  _rspData.savedCredentials !== null && _rspData.savedCredentials !== "" ){
                // setRsUserInfo({...rsUserInfo,parsedCredentials : JSON.parse(_rspData.savedCredentials)})
                _rspData.parsedCredentials = JSON.parse(_rspData.savedCredentials);
              }
              setRsUserInfo(_rspData);
            }
          } else {
            toast.error(response.status);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
        
          toast.error(errorMessage);
        }
      );
    }
  },[serviceData]);

  return (
    <ServiceEmbedContainer>
      <div className="service-endpoints-container">
        <p className="service-title">{serviceData?.name}</p>
        <p className="service-title">{serviceData?.endpoint}</p>
        {serviceData?.authNeed && (
          <Button
            className="endpoint-btn"
            icon="pi pi-lock"
            tooltip={t("authenticate")}
            onClick={() => {
              setAuthNeedVisible(true);
            }}
          />
        )}
        {resultRecords.length > 0 && (
          <Button
            className="endpoint-btn"
            icon="pi pi-database"
            tooltip={t("showResultRecords")}
            onClick={() => {
              setResultRecordsVisible(true);
            }}
          />
        )}
        <div className="endpoints-container">
          {serviceData?.type === "IFRAME" ? (
            <iframe
              className="service-iframe"
              src={serviceData?.endpoint}
              // title={serviceData?.name}
            ></iframe>
          ) : serviceData?.type === "API" ? (
            <div className="api-endpoints-container">
              {serviceData?.endpointObject !== undefined &&
                serviceData?.endpointObject !== null &&
                serviceData?.endpointObject !== "" &&
                JSON.parse(serviceData?.endpointObject).map((e, i) => (
                  <div className="api-endpoint-item">
                    {/* <p>{e?.methodType}{e?.endPoint}</p> */}
                    {handleMethodType(e?.methodType)}
                    <InputText
                      style={{ minWidth: "300px" }}
                      className="api-endpoint-item-endpoint"
                      value={"/" + e?.endPoint}
                      disabled
                    />
                    <Button
                      className="endpoint-btn"
                      tooltip={t("setRequestDetails")}
                      // icon="pi pi-list"
                      icon="pi pi-cog"
                      // disabled={e?.params === undefined}
                      onClick={() => {
                        setActiveIndex(i);
                        // if(e?.params !== undefined && e?.params !== null){
                        setActiveParams(e?.params);
                        setActiveEndpoint(e);
                        setParamsVisible(true);
                        handleParameters(e?.endPoint);
                        // }
                      }}
                    />

                    {!serviceData.authNeed ? (
                      <Button
                        className="endpoint-btn"
                        tooltip={t("sendRequest")}
                        icon="pi pi-external-link"
                        onClick={() =>
                          submitRequest(e?.methodType, e?.endPoint, i)
                        }
                      />
                    ) : (
                      <Button
                        className="endpoint-btn"
                        tooltip={t("sendRequest")}
                        icon="pi pi-external-link"
                        disabled={ssoComplete}
                        onClick={() =>
                          submitRequest(e?.methodType, e?.endPoint, i)
                        }
                      />
                    )}
                  </div>
                ))}
              <div className="custom-endpoint-container">
                <Button
                  className="custom-endpoint-add-btn"
                  icon="pi pi-plus"
                  tooltip={t("useCustomEndpoint")}
                  onClick={() => {
                    setCustomAPIEndpointVisible(true);
                  }}
                />
                <Button
                  className="custom-endpoint-info-btn"
                  icon="pi pi-exclamation-circle"
                  tooltip={t("infoCustomEndpoint")}
                />
              </div>
            </div>
          ) : (
            <div>{t("warnServiceTypeNotDefined")}</div>
          )}
        </div>
      </div>
      <Dialog
        style={{ minWidth: "600px" }}
        visible={authNeedVisible}
        header={t("serviceAuthentication")}
        onHide={() => {
          setAuthNeedVisible(false);
          setAuthInfo(emptyAuthInfo);
          setToken(emptyTokenObject);
        }}
      >
        <ServiceEmbedDialogContainer>
          <div>
            {t("serviceAuthUrl")}:{serviceData?.serviceAuthUrl}
          </div>
          <br />
          <TabView
            activeIndex={authActiveIndex}
            onTabChange={(e) => {
              setAuthActiveIndex(e.index);
            }}
          >
            <TabPanel header={t("withUsernamePassword")}>
              <div>
                <p>{t("username")}:</p>
                <InputText
                  className="ser"
                  value={authInfo?.username}
                  onChange={(e) =>
                    setAuthInfo({ ...authInfo, username: e.target.value })
                  }
                />
                <p>{t("password")}:</p>
                <Password
                  className=""
                  toggleMask
                  type="text"
                  feedback={false}
                  value={authInfo?.password}
                  onChange={(e) =>
                    setAuthInfo({ ...authInfo, password: e.target.value })
                  }
                />
              </div>
            </TabPanel>
            <TabPanel header={t("withToken")}>
              <InputText
                style={{ minWidth: "500px" }}
                value={token?.tokenData}
                onChange={(e) => {
                  setToken({ ...token, tokenData: e.target.value });
                }}
              />
            </TabPanel>
            {Object.keys(rsUserInfo).length > 0 &&
              (rsUserInfo?.savedToken !== "" ||
                rsUserInfo?.savedCredentials !== "") && (
                <TabPanel header={t("existingCredentials")}>
                  {rsUserInfo?.savedToken !== undefined &&
                    rsUserInfo?.savedToken !== null &&
                    rsUserInfo?.savedToken !== "" && (
                      <div>
                        <InputText
                          style={{ minWidth: "580px" }}
                          disabled
                          value={rsUserInfo?.savedToken}
                        />
                        <Button
                          className="endpoint-btn"
                          tooltip={t("removeFromDB")}
                          label={t("reset")}
                          icon="pi pi-eraser"//trash, pi-delete-left,pi-times-circle
                          onClick={() => resetCredentialsSave("token")}
                        />
                        <Button
                          className="endpoint-btn"
                          tooltip={t("useAsToken?")}
                          icon="pi pi-check"
                          label={t("")}
                          onClick={()=>{
                            setToken({ ...token, tokenData: rsUserInfo?.savedToken });
                            submitSSO(1)
                          }}
                        />
                      </div>
                    )}

                  {rsUserInfo?.savedCredentials !== undefined &&
                    rsUserInfo?.savedCredentials !== null &&
                    rsUserInfo?.savedCredentials !== "" && (
                      <div>
                        <InputText
                          style={{ minWidth: "290px" }}
                          disabled
                          value={
                            JSON.parse(rsUserInfo?.savedCredentials)?.username
                          }
                        />
                        <InputText
                          style={{ minWidth: "290px" }}
                          disabled
                          value={
                            JSON.parse(rsUserInfo?.savedCredentials)?.password
                          }
                        />
                        <Button
                          className="endpoint-btn"
                          tooltip={t("removeFromDB")}
                          label={t("reset")}
                          icon="pi pi-eraser"
                          onClick={() => resetCredentialsSave("credentials")}
                        />  
                        <Button
                          className="endpoint-btn"
                          tooltip={t("useAsCredentials?")}
                          icon="pi pi-check"
                          label={t("")}
                        />
                      </div>
                    )}
                  {/* <Button
                  className="endpoint-btn"
                  tooltip={t("resetSavedCredential?")}
                  label={t("reset")}
                  onClick={() => resetCredentialsSave()}
                /> */}
                </TabPanel>
              )}
          </TabView>
          {authActiveIndex !== 2 && (
            <div style={{ paddingTop: "1rem" }}>
              <Button
                className="endpoint-btn"
                label={t("submit")}
                tooltip={authActiveIndex === 0 ?t("tokenGenInfo") : ""}
                onClick={() => submitSSO(authActiveIndex)}
              />
              <Button
                className="endpoint-btn"
                tooltip={t("saveDataToDatabase?")}
                label={t("saveToDB")}
                onClick={() => submitCredentialsSave(authActiveIndex)}
              />
            </div>
          )}

          {Object.keys(token) > 0 && token !== undefined && <div></div>}
        </ServiceEmbedDialogContainer>
      </Dialog>
      <Dialog
        visible={paramsVisible}
        header={t("requestDetails")}
        onHide={() => {
          setParamsVisible(false);
          setRequestParams({});
          setActiveIndex(0);
          setActiveParams([]);
          setActiveEndpoint({});
          setActiveRequestBody({});
          setParameters([]);
        }}
      >
        <ServiceEmbedDialogContainer>
          <ApiRequestDetails
            type={"registered"}
            parameters={parameters}
            setActiveRequestParameters={setActiveRequestParameters}
            setActiveRequestBody={setActiveRequestBody}
          />

          <div>
            <Button
              className="endpoint-btn"
              label={t("submit")}
              // onClick={()=>submitParameters(singularParam,activeIndex)}
              onClick={() => {
                handleRequestDetails();
                setParamsVisible(false);
              }}
            />
          </div>
        </ServiceEmbedDialogContainer>
      </Dialog>
      <Dialog
        visible={requestResultVisible}
        header={t("requestResult")}
        onHide={() => {
          setRequestResultVisible(false);
          setRequestResult({});
          setResultKeys([]);
          setParameters([]);
          setSingularRecord("");
          setServiceResultIsLCA(false);
          setLcaCheckDetailEnabled(false);
        }}
      >
        <ServiceEmbedDialogContainer>
          {requestResult?.status === 200 ? (
            <div className="service-request-result-err-detail-container">
              <p className="service-request-result-err-detail">
                {" "}
                <span className="service-request-result-err-detail-title">
                  {t("requestStatus")}
                  {" : "}
                </span>
                {handleResultStatus(requestResult?.status)} {"- OK"}
              </p>
              {serviceData?.serviceResultSaveCheck && (
                <>
                <Checkbox mouseTrack className="method-lca-check" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  tooltip={ lcaCheckDetailEnabled ? t("isLcaCheckDetail") :  t("isLcaCheck")} onChange={e => setServiceResultIsLCA(e.checked)} checked={serviceResultIsLCA}></Checkbox>
                
                <Button
                  className="endpoint-btn"
                  icon="pi pi-cloud-upload"
                  tooltip={t("sendRecord")}
                  onClick={() => {
                    handleServiceResultRecordSave(
                      requestResult?.data,
                      requestResult?.config?.baseURL +
                        requestResult?.config?.url,
                      requestResult?.config?.method
                    );
                  }}
                />
                </>
              )}
              <Button
                icon="pi pi-clone"
                tooltip={t("copyToClipboard")}
                className="p-button-rounded p-button-text"
                onClick={() => {
                  if(GeneralFunctions.copyToClipboard(JSON.stringify(requestResult?.data))) {
                    toast.info(<Trans i18nKey={"infoCopiedToClipboard"}/>);
                  } else{
                    toast.error(<Trans i18nKey={"failCopiedToClipboard"} />)
                  }
                }}
                id="buttonFontWeight"
              />
               <Button
                  icon="pi pi-download"
                  tooltip={t("download")}
                  className="p-button-rounded p-button-text"
                  onClick={(e) => {
                    setSingularRecord(JSON.stringify(requestResult?.data));
                    refObjDownloadServiceResult.current.toggle(e);
                  }}
                  id="buttonFontWeight"
                />
              <div className="service-request-result-response-data-container">
                <pre className="service-request-result-response-data">
                  {JSON.stringify(requestResult?.data, undefined, 2)}
                </pre>
              </div>
              <OverlayPanel ref={refObjDownloadServiceResult}>
            <div>
              <Button
                className="p-button-rounded p-button-text"
                // icon="pi pi-file"
                icon={<BsFiletypeTxt />}
                tooltip={t("downloadAsTxt")}
                onClick={() => {
                  GeneralFunctions.saveDataAsTextFile(
                    JSON.stringify(JSON.parse(singularRecord), undefined, 2),
                    serviceData?.name+"_result_"+GeneralFunctions.getDateTemplateV4(Date.now())
                  );
                }}
              />
              <Button
                className="p-button-rounded p-button-text"
                icon={<BsFiletypeJson />}
                tooltip={t("downloadAsJson")}
                onClick={() => {
                  GeneralFunctions.saveDataAsJson(
                    JSON.parse(singularRecord),
                    serviceData?.name+"_result_"+GeneralFunctions.getDateTemplateV4(Date.now())
                  );
                }}
              />
              <Button
                className="p-button-rounded p-button-text"
                // icon="pi pi-file-pdf"
                icon={<BsFiletypePdf />}
                tooltip={t("downloadAsPdf")}
                onClick={() => {
                  GeneralFunctions.saveDataAsPdfFile(
                    JSON.stringify(JSON.parse(singularRecord), undefined, 2),
                    serviceData?.name+"_result_"+GeneralFunctions.getDateTemplateV4(Date.now())
                  );
                }}
              />
            </div>
          </OverlayPanel>
            </div>

          ) : (
            <div>
              <div>
                <p className="service-request-result-err-detail">
                  {handleResultStatus(requestResult?.response?.status)}
                </p>
                <p className="service-request-result-err-detail">
                  {requestResult?.message}
                </p>
                <p className="service-request-result-err-detail">
                  {requestResult?.response?.statusText}
                </p>
                <p className="service-request-result-err-detail">
                  {requestResult?.response?.data?.detail}
                </p>
              </div>
            </div>
          )}
           
        </ServiceEmbedDialogContainer>
      </Dialog>
      <Dialog
        visible={customAPIEndpointVisible}
        header={t("customAPIEndpoint")}
        onHide={() => {
          setCustomAPIEndpointVisible(false);
        }}
      >
        <CustomEndpointDialogContainer>
          <ApiRequestDetails type={"custom"} parameters={[]} endpoint={serviceData?.endpoint} loading={loading} setLoading={setLoading}/>
          {/* <Button
            className="custom-endpoint-info-btn"
            icon="pi pi-exclamation-circle"
            tooltip={t("warnWorkInProgress")}
          /> */}
        </CustomEndpointDialogContainer>
      </Dialog>
      <Dialog
        visible={resultRecordsVisible}
        header={t("previousResults")}
        onHide={() => {
          setResultRecordsVisible(false);
          setSingularRecord("");
        }}
      >
        <ServiceEmbedDialogContainer>
          <div className="previous-result-container">
            <DataTable
              className="previous-result-table"
              name="dt"
              size="small"
              showGridlines
              value={resultRecords}
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noRecordsFound")}
              paginator
              rows={10}
              loading={loading}
              globalFilterFields={["orgname", "email", "status"]}
            >
              {/* <Column field="registeredServiceEndpointMethod" header={t("method")}></Column> */}
              <Column body={methodTemplate} header={t("recordMethod")}></Column>

              <Column
                field="registeredServiceEndpoint"
                header={t("recordEndpoint")}
              ></Column>
              <Column
                body={saveTimeTemplate}
                header={t("recordSaveTime")}
              ></Column>
              <Column
                body={productModelTemplate}
                header={t("recordProductModel")}
                style={{ textAlign: "center", width: "12em" }}
              ></Column>
              {/* <Column body={resultRecordTemplate} header={t("resultRecord")}></Column> */}

              <Column
                header={t("action")}
                style={{ textAlign: "center", width: "8em" }}
                body={actionTemplate}
              />
            </DataTable>
            {/* {singularRecord !== undefined &&
              singularRecord !== null &&
              singularRecord !== "" && (
                <div className="previous-result-table-data">
                  <pre className="previous-result-table-data-pre">
                    {JSON.stringify(JSON.parse(singularRecord), undefined, 2)}
                  </pre>
                </div>
              )} */}
            <OverlayPanel ref={refObjCode}>
              {singularRecord !== undefined &&
                singularRecord !== null &&
                singularRecord !== "" && (
                  <div className="previous-result-table-data">
                    <Button
                      icon="pi pi-clone"
                      tooltip={t("copyToClipboard")}
                      className="p-button-rounded p-button-text"
                      onClick={() => {
                        if (GeneralFunctions.copyToClipboard(singularRecord)) {
                          toast.info(
                            <Trans i18nKey={"infoCopiedToClipboard"} />
                          );
                        } else {
                          toast.error(
                            <Trans i18nKey={"failCopiedToClipboard"} />
                          );
                        }
                      }}
                      id="buttonFontWeight"
                    />
                    <ScrollPanel style={{ width: "100%", height: "250px" }}>
                      <div className="service-request-result-response-data-container">
                        <pre className="service-request-result-response-data">
                          {JSON.stringify(
                            JSON.parse(singularRecord),
                            undefined,
                            2
                          )}
                        </pre>
                      </div>
                    </ScrollPanel>
                  </div>
                )}
            </OverlayPanel>
            <OverlayPanel ref={refObjDownload}>
              <div>
                <Button
                  className="p-button-rounded p-button-text"
                  // icon="pi pi-file"
                  icon={<BsFiletypeTxt />}
                  tooltip={t("downloadAsTxt")}
                  onClick={() => {
                    GeneralFunctions.saveDataAsTextFile(
                      JSON.stringify(JSON.parse(singularRecord), undefined, 2),
                      serviceData?.name+"_result_"+GeneralFunctions.getDateTemplateV4(Date.now())
                    );
                  }}
                />
                <Button
                  className="p-button-rounded p-button-text"
                  icon={<BsFiletypeJson />}
                  tooltip={t("downloadAsJson")}
                  onClick={() => {
                    GeneralFunctions.saveDataAsJson(
                      JSON.parse(singularRecord),
                      serviceData?.name+"_result_"+GeneralFunctions.getDateTemplateV4(Date.now())
                    );
                  }}
                />
                {/* <Button
                  className="endpoint-btn"
                  icon="pi pi-file-excel"
                  tooltip={t("downloadAsCsv")}
                  onClick={()=>{ GeneralFunctions.convertToCSV(JSON.parse(singularRecord))}}
                /> */}
                <Button
                  className="p-button-rounded p-button-text"
                  // icon="pi pi-file-pdf"
                  icon={<BsFiletypePdf />}
                  tooltip={t("downloadAsPdf")}
                  onClick={() => {
                    GeneralFunctions.saveDataAsPdfFile(
                      JSON.stringify(JSON.parse(singularRecord), undefined, 2),
                      serviceData?.name+"_result_"+GeneralFunctions.getDateTemplateV4(Date.now())
                    );
                  }}
                />
              </div>
            </OverlayPanel>
           
          </div>
        </ServiceEmbedDialogContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ServiceEmbedContainer>
  );
};

export default withTranslation()(ServiceEmbed);
