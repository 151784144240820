import styled from "@emotion/styled";
export const ApiPageRequestsContainer = styled.div`
    // width: fit-content;
    display:flex; 
    flex-direction:row;
    max-width: 200px !important;
    .request-container{
        background-color: rgba(${(props) => props.requestBgColor},0.3);
        border-radius: 6px;
        border-top: 6px;
        border-style: solid;
        border-color: rgba(${(props) => props.requestBgColor},0.3);
        border-width: thin;
        margin:auto;
        padding-top:0.25rem;
        font-size: 13px;
        // max-width: 200px !important;
    }
    .request-container-blank{
        background-color: rgba(214,214,214,0.3);
        border-radius: 6px;
        border-top: 6px;
        border-style: solid;
        border-color: rgba(214,214,214,0.3);
        border-width: thin;
        // margin:auto;
        // padding-top:0.25rem;
        padding-bottom: 0.2rem;
        margin-bottom: 0.1rem;
        margin-top: 0.1rem;
        font-size: 13px;
    }
    .request-endpoint{
        overflow-x: scroll;
        //white-space: nowrap;
        font-size: 14px;
    }
    .request-body-container-langs{
    
    .lang-tabs{

        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 5px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
        .dialog-size {
            width: 50%;
        }

        .lang-selection{
            .code-block-area{
                background-color:#303b42; //#384454;
                color: #fff;
                // width: 350px !important;
            }
        }
    }
    }
`