import React, { useState, useEffect, useRef } from "react";
import { Trans, withTranslation } from "react-i18next";
import { ToastContainer, toast, Slide } from "react-toastify";
import { InputText } from "primereact/inputtext";
import WaitDialog from "../components/WaitDialog";
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch';
import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
  isCTProvider,
  isCTRepairer,
} from "../functionalities/common/token-check";
import PubService from "../functionalities/services/pub.service"
import ApiService from "../functionalities/services/api.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BlockUI } from "primereact/blockui";
import MDCDialog from "../components/MDCDialog"
import { Dialog } from "primereact/dialog";
import {
  SearchContainer,
  SearchFilterDialogContainer,
  EventAddDialogContainer,
} from "./assets/SearchContainer.style";
import { DPPViewContainer } from "./assets/ProductModelViewContainer.style";
import * as Fa6Icons from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { GrClearOption } from "react-icons/gr";
import { FaSearch, FaSearchPlus } from "react-icons/fa";
import { testProductModels } from "../data/ct-data";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useParams } from "react-router-dom";
import { OverlayPanel } from 'primereact/overlaypanel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { BsFiletypeJson,BsFiletypePdf,BsFiletypeTxt } from "react-icons/bs";

// const emptySearchFilter = {
//   productModelSector: "",
//   productModelCat: "",
//   productModelType: "",
//   productBrandName : "",
// };

const emptyAddEventRequestBody = {
  vc: "",
  passportCode: "",
  eventId: "",
  subEventId: "",
  facilityUid: "",
  note: "",
  address: "",
  city: "",
  country: "",
}

const SearchV2 = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const [filterBoxVisibility, setFilterBoxVisibility] = useState(false);
  // const [searchFilters, setSearchFilters] = useState(emptySearchFilter);
  const navigate = useNavigate();
  const [filters, setFilters] = useState(null);
  const { keyword } = useParams();
  const [mdcVisible, setMDCVisible] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [itemLevelSearchVisible, setItemLevelSearchVisible] = useState(false);
  const [directItemLevelSearchVisible, setDirectItemLevelSearchVisible] = useState(false);

  const [passportId, setPassportId] = useState("");
  const [selectedPM, setSelectedPM] = useState({});
  const [addEventRequestBody, setAddEventRequestBody] = useState(emptyAddEventRequestBody);

  const [passportRequestResult, setPassportRequestResult] = useState({});
  const [addEventVisible, setAddEventVisible] = useState(false);
  const refObjPassportServiceResult = useRef(null);

  const onSearchSubmit = () => { if (searchText !== undefined || searchText !== null || searchText !== "") { search(); } };

  const clearFilter = () => {
    if(keyword !== undefined && keyword !== null && keyword !== "") {
      setSearchText("");
      navigate("/search");
    }
    else {
      setSearchText("");
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      search();
    }
  };
  const search = () => {
    setLoading(true);
    setSearchResults([]);
    // if(searchState){searchItemLevels()}else{searchProductModels();}
    searchProductModels();
  }
  // const searchItemLevels = () => {}
  const searchProductModels = () => {
    if(keyword !== undefined && keyword !== null && keyword !== ""){
      PubService.searchProductModel(keyword,searchState).then(
        (response) => {
          setLoading(false);
          setSearchResults(response?.data);
        },
        (error) => {
          setLoading(false);
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.responseCode) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
          toast.error(<Trans i18nKey={errorMessage} />);
        }
      );
    }
    else{
      PubService.searchProductModel(searchText,searchState).then(
        (response) => {
          setLoading(false);
          setSearchResults(response?.data);
        },
        (error) => {
          setLoading(false);
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.responseCode) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
          toast.error(<Trans i18nKey={errorMessage} />);
        }
      );
    }
  }
  const handleRedirectToProvider = () => {
    console.log(selectedPM)
    if (selectedPM?.passportCode && selectedPM?.provider?.endpoint) {
      if (selectedPM?.provider?.endpoint.endsWith("/")) {
        window.open(
          selectedPM?.provider?.endpoint + selectedPM?.passportCode,
          "_blank",
          "noopener,noreferrer"
        );
      } else {
        window.open(
          selectedPM?.provider?.endpoint +
            "/" +
            selectedPM?.passportCode,
          "_blank",
          "noopener,noreferrer"
        );
      }
    } else {
      toast.warning(<Trans i18nKey={"warnDPPRedirectNotPossible"} />);
    }
  };
  const handlePassportRequestV2 = (e) => {
    if(passportId !== undefined && passportId !== null && passportId !== "" ){
      
      setLoading(true);
      ApiService.tracidItemLevelSearch(passportId).then(
        (response) => {
          setPassportRequestResult(response?.data)
          setLoading(false);
          refObjPassportServiceResult.current.toggle(e);
        },
        (error) => {
         const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          // toast.error(
          //   error.response.status +
          //     " " +
          //     error.response?.data.responseMessage +
          //     " " +
          //     error.name
          // );
          toast.error(<Trans i18nKey={t("failPassportSearch")}/>);
          setLoading(false);

        }
      )
    } else {
      toast.warning(<Trans i18nKey={"warnPassportIdMandatory"} />)
    }
  }
  const handlePassportRequest = (e) => {
    if (selectedPM?.provider?.apiEndpoint) {
      if(passportId !== undefined && passportId !== null && passportId !== "" ){
        let pApiEndpoint = selectedPM?.provider?.apiEndpoint
        if(!selectedPM?.provider?.apiEndpoint.endsWith("/")){
         pApiEndpoint = pApiEndpoint + "/";
        }
        setLoading(true);
        ApiService.providerPassportRequest(pApiEndpoint,passportId).then(
          (response) => {
            setPassportRequestResult(response?.data)
            setLoading(false);
            refObjPassportServiceResult.current.toggle(e);
          },
          (error) => {
            const errorMessage =
              (error.response &&
                error.response?.data &&
                error.response?.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString();

            toast.error(<Trans i18nKey={t("failPassportSearch")}/>);

            setLoading(false);

          }
        )
      } else {
        toast.warning(<Trans i18nKey={"warnPassportIdMandatory"} />)
      }

    } else {
      toast.warning(<Trans i18nKey={"warnPassportRequestNotPossible"} />);
    }

  }
  const actionTemplateProductModel = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-chevron-right"
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            // setViewProductModelVisible(true);
            // getProductModelInfo(data?.id);
            // setSingularProductModel(data);
            navigate("/pm/" + data?.code);
          }}
          id="buttonFontWeight"
        />
        {/* {searchState && 
          <Button
            icon="pi pi-search"
            tooltip={t("dppSearch")}
            className="p-button-rounded p-button-text"
            onClick={() => {
              setSelectedPM(data);
              setItemLevelSearchVisible(true);
            }}
            id="buttonFontWeight"
          />
        } */}
      </div>
    );
  };
  const handleEventAction = (data) => {
    return (
      <div>
        <Button
            icon="pi pi-send"
            tooltip={t("sendEventRequest")}
            className="p-button-rounded p-button-text"
            onClick={() => {
              handleAddEvent(data);
            }}
            id="buttonFontWeight"
          />
      </div>
    )
  }
  const metaDataCatalogueProductModel = (data) => {
    return (
      <div>
        {/* {data?.mdcField || data?.mdcUrl || data?.mdcDescription ? ( */}
          <Button
            icon="pi pi-external-link"
            tooltip={t("goto")}
            className="p-button-rounded p-button-text"
            onClick={() => {
              redirectToMDC();
            }}
            id="buttonFontWeight"
          />
        {/* ) : (
          <>{"NaN"}</>
        )} */}
      </div>
    );
  };
  const redirectToMDC = () => {
      if(!isLoggedIn()){
        navigate("/login");
      }
      else{
        // setLoading(true);
        setMDCVisible(true);
        //navigate();
      }
    };

  const handleAddEvent = (data) => {
    if(data?.provider?.apiEndpoint !== undefined && data?.provider?.apiEndpoint !== null && data?.provider?.apiEndpoint !== "") {
      setSelectedPM(data);
      setAddEventVisible(true);
    }else { toast.warning(<Trans i18nKey={t("warnNoProviderApiEndpoint")}/>)}
  }
  const submitAddEvent = () => {
    setLoading(true);
    if(
      addEventRequestBody.vc === "" || 
      addEventRequestBody.passportCode === "" || 
      addEventRequestBody.eventId === "" || 
      addEventRequestBody.subEventId === "" || 
      addEventRequestBody.facilityUid === "" || 
      addEventRequestBody.note === "" || 
      addEventRequestBody.address === "" || 
      addEventRequestBody.city === "" || 
      addEventRequestBody.country === "" 
    ){
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    const event = {
      event: {
        vc : addEventRequestBody?.vc,
        passportCode: addEventRequestBody?.passportCode,
        eventId: parseInt(addEventRequestBody?.eventId),
        subEventId: parseInt(addEventRequestBody?.subEventId),
        facilityUid: addEventRequestBody?.facilityUid,
        note: addEventRequestBody?.note,
        address: addEventRequestBody?.address,
        city: addEventRequestBody?.city,
        country: addEventRequestBody?.country,
      }
    }
    let pApiEndpoint = selectedPM?.provider?.apiEndpoint
    if(!selectedPM?.provider?.apiEndpoint.endsWith("/")){
      pApiEndpoint = pApiEndpoint + "/";
    }
    ApiService.providerAddEventRequest(pApiEndpoint, event).then(
      (response) => {
        console.log(response.data);
        if(response?.status === 200){
          toast.success(<Trans i18nKey={t("successAddEventToProvider")}/>)
          setLoading(false);

        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(
          error.response.status +
            " " +
            error.response?.data.responseMessage +
            " " +
            error.name
        );
        setLoading(false);
      }
    );
  }

  const organisationToManufacturer = (data) => {
    return (
      <div>
        {data?.organisation ? (
          <>{data?.organisation?.name}</>
        ):(
          <>{"NaN"}</>
        )}

      </div>
    );
  };
  const generateMetaDataCatalogPDF = async (data) => {
    if(!isLoggedIn()){
      navigate("/login");
    }
    else if(isLoggedIn() && isCTManufacturer()){

      setLoading(true);
      const doc = new jsPDF();

      let yPosition = 20;
      const lineHeight = 10;
      const sectionSpacing = 15;
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(data.name + " MetaData Catalog", 105, yPosition, {
        align: "center",
      });
      yPosition += sectionSpacing;

      const fields = [
        {
          label: "MetaData catalog description",
          value: data.mdcDescription,
        },
        {
          label: "MetaData catalog field",
          value: data.mdcField,
        },
        { label: "MetaData catalog url", value: data?.mdcUrl },
      ];

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      fields.forEach((field) => {
        if (field.value) {
          doc.text(`${field.label}:`, 20, yPosition);
          doc.text(`${field.value}`, 100, yPosition); 
          yPosition += lineHeight + 2; 
        }
      });
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.rect(15, 10, 180, yPosition - 10);
      doc.save(data.name+"-MetaData-Catalog-"+GeneralFunctions.getDateTemplateV4(Date.now())+".pdf");
      setLoading(false);
      toast.success(<Trans i18nKey={"successMDCPDFCreation"} />);
    }
    else{
      setLoading(false);
      toast.warning(<Trans i18nKey="warnWrongRole" />);
      return;
    }
  };
  useEffect(()=>{
    // setSearchResults(testProductModels);
    search();
  },[])

  useEffect(()=>{
    if(searchText === ""){search();}
  },[searchText])
  // useEffect(()=>{
  //   search();
  // },[searchState])
  useEffect(()=>{ 
    search();
  },[keyword])
  return (
    <SearchContainer>
      {localStorage.getItem("i18nextLng") === "tr" ? (
        <h2>
          {" "}
          {t("productModels")}
          {"  "}
          {t("lookingfor")}
        </h2>
      ) : (
        <h2>
          {" "}
          {t("lookingFor")}
          {" : "}
          {t("productModels")}
        </h2>
      )}

      <div className="menubar-container">
        {/* <Menubar model={searchBarItems}></Menubar> */}
        <div className="search-bar-container col-12">
          {/* <InputSwitch checked={searchState} onChange={(e) => setSearchState(e.value)} /> */}
          {/* <InputSwitch
            className="search-state-switch"
            checked={searchState}
            tooltip={searchState ? t("switchToProductModelSearch") : t("switchToItemLabelSearch")}
            onChange={(e) => {
              setSearchState(e.value);
            }}
          /> */}

          <div className="search-input-container">
            <InputText
              className="search-input"
              // placeholder={
              //   searchState ? t("searchItemsLevels") : t("searchProductModels")
              // }
              placeholder={t("searchProductModels")}
              // style={{ minWidth: "4rem" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          {/* <div className="search-filter-button-container" title={t("searchFilters")}>
            <Button
              className="search-filter-button"
              label={t("searchFilters")}
              icon={<Fa6Icons.FaListCheck />}
              onClick={() => setFilterBoxVisibility(true)}
              // onMouseEnter={()=>setFilterBoxExpand(true)}
              // onMouseLeave={()=>setFilterBoxExpand(false)}
            />
          </div> */}
          <div
            className="search-submit-button-container"
            title={t("clearKeyword")}
          >
            <Button
              className="search-submit-button"
              icon={<GrClearOption />}
              onClick={() => clearFilter()}
              // onClickCapture={()=> setSearchText("")}
            />
          </div>
          <div className="search-submit-button-container" title={t("search")}>
            <Button
              className="search-submit-button"
              style={{}}
              icon={<FaSearch />}
              onClick={() => onSearchSubmit()}
            />
          </div>
          <div
            className="search-submit-button-container"
            title={t("itemLevelsearch")}
          >
            <Button
              className="search-submit-button"
              style={{}}
              icon={<FaSearchPlus />}
              onClick={() => {
                setDirectItemLevelSearchVisible(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="search-results-container">
        {/* {searchResults.length >= 0 && ( */}
        <BlockUI fullscreen autoZIndex={false}>
          <div className="col-12">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <DataTable
                size="small"
                emptyMessage={t("noSearchResults")}
                filters={filters}
                showGridlines
                value={searchResults}
                paginator
                responsiveLayout="scroll"
                rows={30}
                loading={loading}
              >
                <Column
                  header={t("productModelManufacturer")}
                  style={{ textAlign: "center", width: "6em" }}
                  body={organisationToManufacturer}
                />
                <Column
                  field="sector"
                  style={{ textAlign: "center", width: "6em" }}
                  header={t("productModelSector")}
                  filter
                />
                <Column
                  field="productCategory"
                  style={{ textAlign: "center", width: "6em" }}
                  header={t("productModelCategory")}
                  filter
                />
                <Column
                  field="productType"
                  style={{ textAlign: "center", width: "6em" }}
                  header={t("productModelType")}
                  filter
                />
                <Column
                  field="brandName"
                  style={{ textAlign: "center", width: "6em" }}
                  header={t("productModelBrandName")}
                  filter
                />
                {/* <Column
              field="country"
              header={t("productModelCountry")}
            /> */}
                <Column
                  field="name"
                  style={{ textAlign: "center", width: "6em" }}
                  header={t("productModelName")}
                />
                <Column
                  header={t("productmodelActions")}
                  style={{ textAlign: "center", width: "6em" }}
                  body={actionTemplateProductModel}
                />
                <Column
                  header={t("eventActions")}
                  style={{ textAlign: "center", width: "6em" }}
                  body={handleEventAction}
                />
                <Column
                  header={t("productmodelMetaDataCatalogueActions")}
                  style={{ textAlign: "center", width: "6em" }}
                  body={metaDataCatalogueProductModel}
                />
              </DataTable>
            </div>
          </div>
        </BlockUI>
        {/* )} */}
      </div>
      {/* <Dialog
        visible={filterBoxVisibility}
        modal
        header={t("searchFilters")}
        className="p-fluid dialog-size"
        onHide={() => {
          setFilterBoxVisibility(false);
        }}
      >
        <SearchFilterDialogContainer>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelSector")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelSector: e.target.value })
              }
            />
          </div>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelCategory")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelCategory: e.target.value })
              }
            />
          </div>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelType")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelType: e.target.value })
              }
            />
          </div>
          <div className="search-filter-container">
            <InputText
              placeholder={t("productModelBrandName")}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, productModelBrandName: e.target.value })
              }
            />
          </div>
          <Button className="search-filter-submit-btn" label={t("submit")} onClick={()=>{}}/>
        </SearchFilterDialogContainer>
      </Dialog> */}
      <Dialog
        visible={mdcVisible}
        header={t("sendMDCRequest")}
        onHide={() => {
          setMDCVisible(false);
        }}
      >
        <MDCDialog />
      </Dialog>
      <Dialog
        visible={itemLevelSearchVisible}
        modal
        header={t("overallDPP")}
        className="p-fluid dialog-size"
        onHide={() => {
          setItemLevelSearchVisible(false);
          setPassportId("");
          // setSelectedPM({});
        }}
        style={{ width: "550px" }}
      >
        <DPPViewContainer>
          <div className="dpp-view-container">
            <div className="view-dpp-btn-container">
              <Button
                className="dpp-view-btn"
                onClick={() => {
                  handleRedirectToProvider();
                }}
                label={t("viewModelLevelDPP")}
              />
            </div>
            <div className="or-container">
              <p className="or-text">{t("or")}</p>
            </div>
            <div className="enter-dpp-info-btn-container">
              <InputText
                className="dpp-view-inputarea"
                placeholder={t("enterPassportId")}
                type="text"
                onChange={(e) => {
                  setPassportId(e.target.value);
                }}
              />
              <Button
                icon="pi pi-external-link"
                className="dpp-view-submit-btn"
                tooltip={t("sendPassportRequestToProviderAPIEndpoint")}
                onClick={(e) => {
                  handlePassportRequest(e);
                }}
              />
            </div>
          </div>
          <OverlayPanel ref={refObjPassportServiceResult}>
            {passportRequestResult !== undefined &&
              passportRequestResult !== null &&
              passportRequestResult !== "" && (
                <div>
                  <Button
                    icon="pi pi-clone"
                    tooltip={t("copyToClipboard")}
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                      if (
                        GeneralFunctions.copyToClipboard(
                          JSON.stringify(passportRequestResult, undefined, 2)
                        )
                      ) {
                        toast.info(<Trans i18nKey={"infoCopiedToClipboard"} />);
                      } else {
                        toast.error(
                          <Trans i18nKey={"failCopiedToClipboard"} />
                        );
                      }
                    }}
                    id="buttonFontWeight"
                  />
                  <Button
                    className="p-button-rounded p-button-text"
                    // icon="pi pi-file"
                    icon={<BsFiletypeTxt />}
                    tooltip={t("downloadAsTxt")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsTextFile(
                        JSON.stringify(passportRequestResult, undefined, 2),
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />
                  <Button
                    className="p-button-rounded p-button-text"
                    icon={<BsFiletypeJson />}
                    tooltip={t("downloadAsJson")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsJson(
                        passportRequestResult,
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />

                  <Button
                    className="p-button-rounded p-button-text"
                    // icon="pi pi-file-pdf"
                    icon={<BsFiletypePdf />}
                    tooltip={t("downloadAsPdf")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsPdfFile(
                        JSON.stringify(passportRequestResult, undefined, 2),
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />
                  <ScrollPanel style={{ width: "100%", height: "250px" }}>
                    <div className="service-request-result-response-data-container">
                      <pre className="service-request-result-response-data">
                        {JSON.stringify(passportRequestResult, undefined, 2)}
                      </pre>
                    </div>
                  </ScrollPanel>
                </div>
              )}
          </OverlayPanel>
        </DPPViewContainer>
      </Dialog>
      <Dialog
        visible={directItemLevelSearchVisible}
        modal
        header={t("searchDPP")}
        className="p-fluid dialog-size"
        onHide={() => {
          setDirectItemLevelSearchVisible(false);
          setPassportId("");
        }}
        style={{ width: "550px" }}
      >
        <DPPViewContainer>
          <div className="dpp-view-container">
            <div className="enter-dpp-info-btn-container">
              <InputText
                className="dpp-view-inputarea-v2"
                placeholder={t("enterPassportId")}
                type="text"
                onChange={(e) => {
                  setPassportId(e.target.value);
                }}
              />
              <Button
                icon="pi pi-external-link"
                className="dpp-view-submit-btn"
                tooltip={t("searchWithPassportCode")}
                onClick={(e) => {
                  handlePassportRequestV2(e);
                }}
              />
            </div>
          </div>
          <OverlayPanel ref={refObjPassportServiceResult}>
            {passportRequestResult !== undefined &&
              passportRequestResult !== null &&
              passportRequestResult !== "" && (
                <div>
                  <Button
                    icon="pi pi-clone"
                    tooltip={t("copyToClipboard")}
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                      if (
                        GeneralFunctions.copyToClipboard(
                          JSON.stringify(passportRequestResult, undefined, 2)
                        )
                      ) {
                        toast.info(<Trans i18nKey={"infoCopiedToClipboard"} />);
                      } else {
                        toast.error(
                          <Trans i18nKey={"failCopiedToClipboard"} />
                        );
                      }
                    }}
                    id="buttonFontWeight"
                  />
                  <Button
                    className="p-button-rounded p-button-text"
                    // icon="pi pi-file"
                    icon={<BsFiletypeTxt />}
                    tooltip={t("downloadAsTxt")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsTextFile(
                        JSON.stringify(passportRequestResult, undefined, 2),
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />
                  <Button
                    className="p-button-rounded p-button-text"
                    icon={<BsFiletypeJson />}
                    tooltip={t("downloadAsJson")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsJson(
                        passportRequestResult,
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />

                  <Button
                    className="p-button-rounded p-button-text"
                    // icon="pi pi-file-pdf"
                    icon={<BsFiletypePdf />}
                    tooltip={t("downloadAsPdf")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsPdfFile(
                        JSON.stringify(passportRequestResult, undefined, 2),
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />
                  <ScrollPanel style={{ width: "100%", height: "250px" }}>
                    <div className="service-request-result-response-data-container">
                      <pre className="service-request-result-response-data">
                        {JSON.stringify(passportRequestResult, undefined, 2)}
                      </pre>
                    </div>
                  </ScrollPanel>
                </div>
              )}
          </OverlayPanel>
        </DPPViewContainer>
      </Dialog>
      <Dialog
        visible={addEventVisible}
        modal
        header={t("sendEventRequest")}
        onHide={() => {
          setAddEventVisible(false);
          setSelectedPM({});
          setAddEventRequestBody(emptyAddEventRequestBody);
        }}
      >
        <EventAddDialogContainer>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-vc">
                  {t("pleaseEnterAddEventVc")} (*)
                </p>
                <InputText
                  id="addevent-vc"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.vc}
                  // maxLength={100}
                  onChange={(e) => {
                    console.log(addEventRequestBody);
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      vc: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-passportcode">
                  {t("pleaseEnterAddEventPassportCode")} (*)
                </p>
                <InputText
                  id="addevent-passportcode"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.passportCode}
                  // maxLength={100}
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      passportCode: e.target.value,
                    })
                  }
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-eventId">
                  {t("pleaseEnterAddEventEventId")} (*)
                </p>
                <InputText
                  id="addevent-eventId"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.eventId}
                  // maxLength={100}
                  keyfilter="int"
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      eventId: e.target.value,
                    })
                  }
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-subEventId">
                  {t("pleaseEnterAddEventSubEventId")} (*)
                </p>
                <InputText
                  id="addevent-subEventId"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.subEventId}
                  // maxLength={100}
                  keyfilter="int"
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      subEventId: e.target.value,
                    })
                  }
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-facilityUid">
                  {t("pleaseEnterAddEventFacilityUid")} (*)
                </p>
                <InputText
                  id="addevent-facilityUid"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.facilityUid}
                  // maxLength={100}
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      facilityUid: e.target.value,
                    })
                  }
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-note">
                  {t("pleaseEnterAddEventNote")} (*)
                </p>
                <InputText
                  id="addevent-note"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.note}
                  maxLength={150}
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      note: e.target.value,
                    })
                  }
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-address">
                  {t("pleaseEnterAddEventAddress")} (*)
                </p>
                <InputText
                  id="addevent-address"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.address}
                  maxLength={200}
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      address: e.target.value,
                    })
                  }
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-city">
                  {t("pleaseEnterAddEventCity")} (*)
                </p>
                <InputText
                  id="addevent-city"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.city}
                  maxLength={50}
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      city: e.target.value,
                    })
                  }
                />
              </div>
              <div className="addevent-container">
                <p className="platformColor" htmlFor="addevent-country">
                  {t("pleaseEnterAddEventCountryCode")} (*)
                </p>
                <InputText
                  id="addevent-country"
                  type="text"
                  className="p-inputtext-sm w-full addevent-it"
                  value={addEventRequestBody?.country}
                  maxLength={2}
                  onChange={(e) =>
                    setAddEventRequestBody({
                      ...addEventRequestBody,
                      country: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitAddEvent}
          />
        </EventAddDialogContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </SearchContainer>
  );
};
export default withTranslation()(SearchV2);
