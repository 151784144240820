import { RolesContainer,CreateRolesDialogContainer,MatchRolesDialogContainer } from "./assets/RolesContainer.style";
import {
  OrganisationAddContainer,
} from "./assets/OrganisationsContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import WaitDialog from "../components/WaitDialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Column } from "primereact/column";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import SysService from "../functionalities/services/sys.service";
import { useNavigate } from "react-router-dom";
import { isCTAdmin } from "../functionalities/common/token-check";

const emptyRoleData = {
  name: "",
};

const EditRoles = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [services, setServices] = useState([]);
  const [roleData, setRoleData] = useState(emptyRoleData);
  const [createRoleVisible, setCreateRoleVisible] = useState(false);
  const [editRoleVisible, setEditRoleVisible] = useState(false);
  const [singularRole, setSingularRole] = useState({});

  const [selectedServices, setSelectedServices] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [matchRoleVisible, setMatchRoleVisible] = useState(false);
  const [roleMap, setRoleMap] = useState({});

  const navigate = useNavigate();

  const submitRoleCreation = () => {
    setLoading(true);

    if ( roleData.name === "" ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    if( /\s/g.test(roleData.name)){
      toast.warning(<Trans i18nKey="warnNoSpacesInRoles" />);
      setLoading(false);
      return;
    }
    roleData.name = "CT_" + roleData.name;
    const roleD = {
      roleDTO: { ... roleData }
    }
    
    SysService.addRole(roleD).then(
      (response) => {
        if (response.status === 200) {
          toast.success(<Trans i18nKey={"successRoleCreation"} />);
          SysService.getRoles().then(
            (response) => {
              setRoles(response?.data);
              setLoading(false);
              setCreateRoleVisible(false);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.responseCode) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();

              toast.error(<Trans i18nKey={errorMessage} />);
              setLoading(false);
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(
          errorMessage,
          <Trans i18nKey={"failRoleCreation"} />
        );
        setLoading(false);
      }
    );
  };
  const submitRoleEdit = () => {
    setLoading(true);
    if ( singularRole.name === "" ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    if( /\s/g.test(singularRole.name)){
      toast.warning(<Trans i18nKey="warnNoSpacesInRoles" />);
      setLoading(false);
      return;
    }
    const roleD = {
      roleDTO: { ... singularRole }
    }
    SysService.updateRole(roleD).then(
      (response) => {
        if (response.status === 200) {
          toast.success(<Trans i18nKey={"successRoleUpdate"} />);
          SysService.getRoles().then(
            (response) => {
              setRoles(response?.data);
              setLoading(false);
              setEditRoleVisible(false);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.responseCode) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();

              toast.error(<Trans i18nKey={errorMessage} />);
              setLoading(false);
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(
          errorMessage,
          <Trans i18nKey={"failRoleUpdate"} />
        );
        setLoading(false);
      }
    );
  };

  const handleRoleMatchToService = () => {
    setLoading(true);
    if(selectedServices === undefined || selectedServices === null ){
      setLoading(false);
      toast.warning(<Trans i18nKey="warnServiceSelect"/>);
      return;
    }
    if(selectedRoles === undefined || selectedRoles === null ){
      setLoading(false);
      toast.warning(<Trans i18nKey="warnRoleSelect"/>);
      return;
    }

    for(let i = 0; i<selectedServices.length ; i++){

      const registeredServiceDTO = {
        registeredServiceDTO: {
          id: selectedServices[i].id,
          name: selectedServices[i].name,
          organisationName: selectedServices[i].organisationName,
          type: selectedServices[i].type,
          endpoint: selectedServices[i].endpoint,
          status: selectedServices[i].status,
          shortDescription: selectedServices[i]?.shortDescription,
          description: selectedServices[i]?.description,
          roles: selectedRoles
        },
      };
      SysService.updateRegisteredServiceRoleMapping(registeredServiceDTO).then(
        (response) => {
          if(response.status === 200) {
            toast.success(<Trans i18nKey={"successServiceRoleMapping"} />)
            setLoading(false);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.responseCode) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
          toast.error(
            errorMessage,
            <Trans i18nKey={"failServiceRoleMapping"} />
          );
          setLoading(false);
        }
      )
    }
    // setLoading(false);
    // setMatchRoleVisible(false);
    // setSelectedServices(null);
    // setSelectedRoles(null);
    return;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const actionTemplate = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          tooltip={t("edit")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setEditRoleVisible(true);
            setSingularRole(data);
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };
  useEffect(()=>{
    if(isCTAdmin()){
      SysService.getRoles().then(
        (response) => {
   
          setRoles(response?.data);
        },
        (error) => {
          const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        }
      );
      SysService.getRegisteredServices().then(
        (response) => {
   
          setServices(response?.data);
        },
        (error) => {
          const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        }
      );
    }
    else{
      navigate("/landing");
    }

  },[]);
  return (
    <RolesContainer>
      <h2>{t("rolesPage")}</h2>
      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("createARole")}
          onClick={() => setCreateRoleVisible(true)}
        />
      </OrganisationAddContainer>
      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("matchRolesToServices")}
          onClick={() => setMatchRoleVisible(true)}
        />
      </OrganisationAddContainer>
      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={roles}
              className="role-dt"
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noRolesFound")}
              // header={renderHeader1}
              paginator
              rows={10}
              // filters={filters1}
              // filterDisplay="menu"
              loading={loading}
              style={{backgroundColor:"#EBEBEB"}}
              // globalFilterFields={["serviceName", "serviceContact", "status"]}
            >
              <Column
                field="name"
                header={t("roleName")}
                // sortable
              ></Column>
              <Column
                header={t("action")}
                style={{ textAlign: "center", width: "8em" }}
                body={actionTemplate}
              ></Column>
            </DataTable>
          </div>
        </div>
      </BlockUI>
      <Dialog
        visible={createRoleVisible}
        modal
        header={t("createARole")}
        className="p-fluid dialog-size"
        onHide={() => {
          setCreateRoleVisible(false);
          setRoleData(emptyRoleData);
        }}
      >
        <CreateRolesDialogContainer>
          {/* <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem"
              // letterSpacing: "0.5px",
            }}
          >
            {t("createARole")}
          </div> */}
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={roleData?.name}
                  onChange={
                    (e) => setRoleData({ ...roleData, name: (e.target.value).toUpperCase() })
                  }
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("pleaseEnterRoleName")} (*)
                </label>
              </div>
              <div>
                <p>{t("roleInfoText")} {"CT_"}{roleData.name}</p>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitRoleCreation}
          />
        </CreateRolesDialogContainer>
      </Dialog>
      <Dialog
        visible={matchRoleVisible}
        modal
        header={t("matchRolesToServices")}
        className="p-fluid dialog size"
        style={{minWidth:"45rem",maxWidth:"50rem"}}
        onHide={() => {
          setMatchRoleVisible(false);
          setSelectedServices(null);
          setSelectedRoles(null);
        }}
      >
        <MatchRolesDialogContainer>
          <div className="">
            <MultiSelect
              id="serviceList"
              className="w-full p-inputtext-sm service-container"
              optionLabel="name"
              value={selectedServices}
              options={services}
              display="chip"
              maxSelectedLabels={4}
              onChange={(e) => setSelectedServices(e.value)
                  // console.log(e.value)
              }

            />
          </div>
          <div className="">
            <MultiSelect
                id="serviceList"
                className="w-full p-inputtext-sm service-container"
                optionLabel="name"
                value={selectedRoles}
                options={roles}
                display="chip"
                maxSelectedLabels={5}
                onChange={(e) => setSelectedRoles(e.value)}
            />
          </div>
          <Button
            className="submit-button"
            label={t("submit")}
            // disabled
            onClick={handleRoleMatchToService}
          />
          
        </MatchRolesDialogContainer>
        
      </Dialog>
      <Dialog
        visible={editRoleVisible}
        modal
        header={t("editARole")}
        className="p-fluid dialog-size"
        onHide={() => {
          setEditRoleVisible(false);
          setSingularRole({});
        }}
      >
        <CreateRolesDialogContainer>

          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={singularRole?.name}
                  onChange={
                    // (e) => handleOrgCreation("organisationName", e.target.value)
                    (e) => setSingularRole({ ...singularRole, name: (e.target.value).toUpperCase() })
                  }
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("pleaseEnterRoleName")} (*)
                </label>
              </div>
              <div>
                <p>{t("roleInfoTextv2")} {singularRole.name}</p>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitRoleEdit}
          />
        </CreateRolesDialogContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </RolesContainer>
  );
};

export default withTranslation()(EditRoles);
