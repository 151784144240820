import {
  ServicesContainer,
  CreateServicesDialogContainer,
  ViewServicesDialogContainer,
  UpdateEndpointContainer,
} from "./assets/ServicesContainer.style";
import { OrganisationAddContainer } from "./assets/OrganisationsContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import {
  serviceTypesList,
  statusTypesList,
  methodTypes,
} from "../data/ct-data";
import WaitDialog from "../components/WaitDialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { ToggleButton } from 'primereact/togglebutton';
import ApiEndpointInput from "../components/ApiEndpointInput";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { useNavigate } from "react-router-dom";
import { isCTAdmin } from "../functionalities/common/token-check";

const emptyServiceData = {
  serviceName: "",
  serviceType: 0,
  serviceEndPoint: "",
  serviceWebsite: "",
  serviceApiEndpoints: [],
  serviceOrganisationName: "",
  serviceShortDescription: "",
  serviceDescription: "",
  serviceApiEndpointText: "",
  authNeed: false,
  authUrl: "",
  serviceAuthUrl : "",
  selectedRoles: null,
};
const emptyApiEndpoint = {
  
  methodCode: 0,
  methodType: "",
  endPoint: "",
  // params: [],
  // headerInfo: { 
  //   headerEndpoint: "",
  //   //headerInput?
  // }
}
// const emptyParam = {
//   paramName: "",
//   paramType: "" // int, string, bool
// }


const EditServices = ({ t }) => {
  const [services, setServices] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState(emptyServiceData);
  const [createServiceVisible, setCreateServiceVisible] = useState(false);
  const [viewServiceVisible, setViewServiceVisible] = useState(false);
  const [editServiceVisible, setEditServiceVisible] = useState(false);
  const [singularService, setSingularService] = useState({});

  const [apiEndpointNum, setApiEndpointNum] = useState(0);
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [apiEndpoints, setApiEndpoints] = useState([]);
  const [apiEndpointComponents, setApiEndpointComponents] = useState([]);
  const [checked, setChecked] = useState(false);
  const [existingAPIEndpointNum, setExistingAPIEndpointNum] = useState(0);
  const [currentMethodType, setCurrentMethodType] = useState("");
  const [singularParam, setSingularParam] = useState({});
  const [paramNum, setParamNum] = useState(0);
  const [paramVisible, setParamVisible] = useState(false);
  const [activeParamNum, setActiveParamNum] = useState(-1);
  const [endpointInfoVisible, setEndpointVisible] = useState(false);

  const navigate = useNavigate();

  const submitServiceCreation = () => {
    setLoading(true);
    // console.log(apiEndpoints)
    // const endpointObjectText = JSON.stringify(apiEndpoints);
    // console.log(endpointObjectText);
    // console.log(JSON.parse(endpointObjectText));
    if (
      serviceData.serviceName === "" ||
      serviceData.serviceOrganisationName === "" ||
      serviceData.serviceType === 0 ||
      serviceData.serviceEndPoint === ""
      // serviceData.serviceEndpoint.length === 0
      // selectedRoles.length === 0
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    if( serviceData.authNeed && serviceData.authUrl === ""){
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    const registeredServiceDTO = {
      registeredServiceDTO: {
        name: serviceData.serviceName,
        organisationName: serviceData.serviceOrganisationName,
        website: serviceData?.serviceWebsite,
        type: getServiceTypeName(serviceData.serviceType),
        endpoint: serviceData.serviceEndPoint,
        apiEndpoints: serviceData?.serviceApiEndpoints,
        shortDescription: serviceData?.shortDescription,
        description: serviceData?.description,
        endpointObject: JSON.stringify(apiEndpoints),
        authNeed: serviceData.authNeed,
        serviceAuthUrl: serviceData.authNeed ? serviceData.authUrl : "",
        roles: selectedRoles,
      },
    };

    SysService.addRegisteredService(registeredServiceDTO).then(
      (response) => {
        toast.success(<Trans i18nKey={"successServiceCreation"} />);
        if (response.status === 200) {
          SysService.getRegisteredServices().then(
            (response) => {
              setServices(response?.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(
          error,
          <Trans i18nKey={"failServiceCreation"} />
          //   {
          //     style:{
          //     background: '#EBEBEB',
          //     color:"#000"
          //   }
          // }
        );
      }
    );

    setLoading(false);
    setCreateServiceVisible(false);
    setServiceData(emptyServiceData);
    setApiEndpointNum(0);
    setSelectedRoles(null);
    setChecked(false);
    return;
  };
  const submitServiceEdit = () => {
    setLoading(true);
    if (
      singularService.name === "" ||
      singularService.organisationName === "" ||
      singularService.type === "" ||
      singularService.endpoint === ""
      //singularService.roles.length === 0
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    if( singularService.authNeed && singularService?.serviceAuthUrl === '' ){
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    if( !singularService.authNeed) {
      setSingularService({
        ...singularService,
        serviceAuthUrl: ""
      });
    }
    if( singularService.type  === 'IFRAME'){
      setSingularService({
        ...singularService,
        endpoint: ""
      });
    }
    const registeredServiceDTO = {
      registeredServiceDTO: {
        id: singularService.id,
        name: singularService.name,
        organisationName: singularService.organisationName,
        website: singularService?.website,
        type: singularService.type,
        endpoint: singularService.endpoint,
        status: singularService.status,
        shortDescription: singularService?.shortDescription,
        description: singularService?.description,
        authNeed: singularService.authNeed,
        serviceAuthUrl: singularService.authNeed ? singularService?.serviceAuthUrl : "",
        roles: singularService?.roles,
      },
    };
    console.log(registeredServiceDTO)

    SysService.updateRegisteredService(registeredServiceDTO).then(
      (response) => {
        if (response.status === 200) {
          toast.success(<Trans i18nKey={"successServiceUpdate"} />);
          SysService.getRegisteredServices().then(
            (response) => {
              setServices(response?.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
            }
          );
        } else {
          // toast.error(response.status, <Trans i18nKey={"failServiceUpdate"} />,{
          //   style:{
          //     background: '#EBEBEB',
          //     color:"#000"
          //   }
          // })
          toast.error(response.status, <Trans i18nKey={"failServiceUpdate"} />);
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(
          error.response.status +
            " " +
            error.response.data.responseMessage +
            " " +
            error.name
        );
      }
    );

    setLoading(false);
    setSingularService({});
    setEditServiceVisible(false);
    setApiEndpointNum(0);
    setSelectedRoles(null);
    return;
  };
  const getServiceInfo = (id) => {
    SysService.getRegisteredServiceById(id).then(
      (response) => {
        console.log(response?.data);
        setSingularService(response?.data);
        // setApiEndpoints();
        handleExistingAPIEndpoints(response?.data);        
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
      }
    );
  };
  const getServiceTypeName = (serviceCode) => {
    for (let i = 0; i < serviceTypesList.length; i++) {
      if (serviceTypesList[i]["serviceTypeCode"] === serviceCode) {
        return serviceTypesList[i]["serviceTypeName"];
      }
    }
  };

  const actionTemplateStatus = (data) => {
    return (
      <Chip
        label={t("STATUS_" + data.status)}
        className="mr-2 mb-2"
        style={{ backgroundColor: "#382c7c", color: "#fff" }}
      />
    );
  };
  const statusInfo = (statData) => {
    return t("STATUS_" + statData);
  };
  const getMethodName = (methodCode) => {
    switch (methodCode) {
      case 1:
        return "GET"
      case 2:
        return "POST"
      case 3:
        return "PUT"
      case 4:
        return "DELETE"
      default:
        return "NA";
    }
  }
  const getMethodColor = (methodCode) => {
    switch (methodCode) {
      case 1:
        return "#61affe"
      case 2:
        return "#49cc90"
      case 3:
        return "#fca130"
      case 4:
        return "#f93e3e"
      default:
        return "#d6d6d6";
    }
  }


  const actionTemplate = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          tooltip={t("edit")}
          className="p-button-rounded p-button-text service-edit-button"
          onClick={() => {
            setEditServiceVisible(true);
            getServiceInfo(data.id);
          }}
          id="buttonFontWeight"
        />
        <Button
          icon="pi pi-exclamation-circle"
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setViewServiceVisible(true);
            getServiceInfo(data.id);
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };
  const typeListTemplate = (data) => {
    return (
      <div>
        {/* if type 1 or 2 change bckgrnd clr */}
        {(() => {
          switch (data?.type) {
            case "IFRAME":
              return (
                <Chip
                  label={data?.type}
                  className="mr-2 mb-2"
                  style={{ backgroundColor: "#72bfbf", color: "#fff" }}
                />
              );
            case "API":
              return (
                <Chip
                  label={data?.type}
                  className="mr-2 mb-2"
                  // style={{ backgroundColor: "#20d4a4" }}
                  style={{ backgroundColor: "#83bf4f", color: "#fff" }}
                />
              );
            default:
              return "";
          }
        })()}
      </div>
    );
  };
  const serviceRoleListTemplate = (data) => {
    let roles = [];
    if (data?.roles !== undefined && data?.roles.length > 0) {
      roles = data?.roles.map((role) => {
        return (
          <Chip
            label={role?.name}
            className="mr-2 mb-2"
            style={{
              backgroundColor: "#382c7c",
              color: "#FFFFFF",
              marginLeft: "0.125rem",
              marginRight: "0.125rem",
            }}
          />
        );
      });
    } else if (data?.roles !== undefined && data?.roles.length === 0) {
      roles = [
        <Chip
          label={t("noRoleAssignedToService")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />,
      ];
    }
    return roles;
  };

  const serviceAuthNeedTemplate = (data) => {
    if(data){
      return (
        <Chip
          label={t("yes")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      );
    }
    else{
      return (
        <Chip
          label={t("no")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      );
    }
  }
  const serviceAuthNeedTextTemplate = (data) => {
    if(data){
      return t("yes")
    }else{
      return t("no")
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const apiEndpointTemplate = (data) => {
    let endPoints = [];
    const endpointArr = JSON.parse(data);
    if (endpointArr.length > 0) {
      endPoints = endpointArr.map((e) => {
        return (
          <div style={{ fontSize: "14px" }}>
            <Chip
              label={e?.methodType}
              className="method-chip"
              style={{
                backgroundColor: getMethodColor(e?.methodCode),
                marginRight: "0.7rem",
                marginBottom: "0.7rem",
                fontSize: "14px",
              }}
            />
            {"/"}
            {e?.endPoint}
          </div>
        );
      });
    }
    return endPoints;
  };

  const handleEndpointUpdate = (data) => {
    let endPoints = [];
    const endpointArr = JSON.parse(data);
    if (endpointArr.length > 0) {
      endPoints = endpointArr.map((e) => {
        // console.log(e)
        return (
          <UpdateEndpointContainer>
          <div className="endpoint-container-item">
            <Dropdown
              // id={"serviceApiMethodType"+apiEndpointNum}
              // name = {apiEndpointNum}
              className="w-full p-inputtext-sm endpoint-item"                              
              options={methodTypes}
              // value={e?.methodType}
              placeholder={e?.methodType}
              optionLabel="name"
              optionValue="code"
              appendTo="self"
              onChange={(i)=>{
                handleEndpointChange(e,0,i.target.value);
                
              }}
            />
            <InputText
              id="serviceApiEndpoint"
              type="text"
              // name={apiEndpointNum}
              placeholder={e?.endPoint}
              className="p-inputtext-sm w-full endpoint-item"
              maxLength={250}
              onChange={(i)=>{
                handleEndpointChange(e,1,i.target.value);

              }}
            />
          </div>
          </UpdateEndpointContainer>
        );
      });
    }
    return endPoints;
  };

  const handleNewServiceEndpoint = (data, index) =>{
    setApiEndpoints(apiEndpoints.map((e,i) => 
      i === index ? {...e, methodCode:data.value.code , methodType :data.value.name} : e
    ));

  }

  const handleEndpointChange = (e,changeType,changedData) => {

    if(changeType === 0){

    }
    else if(changeType === 1){

    }
  };

  const handleExistingAPIEndpoints = (data) => {
    console.log(data)
    if( data?.endpointObject !== undefined && 
      data?.endpointObject !== null && data?.endpointObject !== ""){
        setExistingAPIEndpointNum(JSON.parse(data?.endpointObject).length);
        setApiEndpoints(JSON.parse(data?.endpointObject));
    }
    else{
      setExistingAPIEndpointNum(0);
    }
  }
  useEffect(()=>{console.log(currentMethodType)},[currentMethodType]);
  useEffect(() => {
    if(isCTAdmin()){
      SysService.getRegisteredServices().then(
        (response) => {
          setServices(response?.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
        }
      );
      SysService.getRoles().then(
        (response) => {
          setRoles(response?.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
        }
      );
    }
    else{
      navigate("/landing");
    }

  }, []);
  // useEffect(()=>{
  //   if(singularService !== undefined && singularService.type !== "IFRAME" && singularService.endpointObject  !== undefined && singularService.endpointObject !== null){
  //     setSingularService({
  //       ...singularService,
  //       existingApiEndpointNum: JSON.parse(singularService?.endpointObject).length,
  //     });
  //   }
  // },[singularService]);
  return (
    <ServicesContainer>
      <h2>{t("servicesPage")}</h2>
      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("createAService")}
          onClick={() => setCreateServiceVisible(true)}
        />
      </OrganisationAddContainer>
      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={services}
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noServicesFound")}
              paginator
              rows={10}
              loading={loading}
            >
              <Column field="name" sortable header={t("serviceName")}></Column>
              <Column
                field="organisationName"
                sortable
                header={t("serviceOrganisationName")}
              ></Column>
              <Column
                field={typeListTemplate}
                header={t("serviceType")}
              ></Column>
              <Column field="endpoint" header={t("serviceEndpoint")}></Column>
              <Column body={actionTemplateStatus} header={t("status")}></Column>
              <Column
                header={t("serviceActions")}
                style={{ textAlign: "center", width: "8em" }}
                body={actionTemplate}
              />
            </DataTable>
          </div>
        </div>
      </BlockUI>
      <Dialog
        visible={createServiceVisible}
        modal
        header={t("createAService")}
        className="p-fluid dialog-size"
        onHide={() => {
          setCreateServiceVisible(false);
          setServiceData(emptyServiceData);
          setApiEndpointNum(0);
          setSelectedRoles(null);
          setApiEndpoints([]);
          setApiEndpointComponents([]);
          setChecked(false);
          setActiveParamNum(-1);
        }}
      >
        <CreateServicesDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          ></div>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData?.serviceName}
                  maxLength={100}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceName: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("pleaseEnterServiceName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceOrganisationName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData?.serviceOrganisationName}
                  maxLength={100}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceOrganisationName: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="serviceOrganisationName"
                >
                  {t("pleaseEnterServiceOrganisationName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceWebsite"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData?.serviceWebsite}
                  maxLength={250}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceWebsite: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceWebsite">
                  {t("pleaseEnterServiceWebsite")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputTextarea
                  id="serviceShortDescription"
                  type="text"
                  className="p-inputtext-sm w-full shortdescription-textarea"
                  value={serviceData?.serviceShortDescription}
                  maxLength={150}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceShortDescription: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="serviceShortDescription"
                >
                  {t("pleaseEnterServiceShortDescription")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputTextarea
                  id="serviceDescription"
                  type="text"
                  className="p-inputtext-sm w-full description-textarea"
                  value={serviceData?.serviceDescription}
                  maxLength={800}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceDescription: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceDescription">
                  {t("pleaseEnterServiceDescription")}
                </label>
              </div>
              <div className="p-float-label w-full input-container">
                <Dropdown
                  id="serviceType"
                  className="w-full p-inputtext-sm"
                  value={serviceData?.serviceType}
                  options={serviceTypesList}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceType: e.target.value,
                    })
                  }
                  optionLabel="serviceTypeName"
                  optionValue="serviceTypeCode"
                  appendTo="self"
                />
                <label className="platformColor" htmlFor="serviceType">
                  {t("pleaseSelectServiceType")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceEndpoint "
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData?.serviceEndPoint}
                  maxLength={250}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceEndPoint: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceEndpoint">
                  {t("pleaseEnterServiceEndpoint")} (*)
                </label>
              </div>
              {serviceData?.serviceType !== undefined &&
                serviceData?.serviceType !== null &&
                serviceData?.serviceType === 2 && (
                  <div className="p-float-label input-container">
                    <Button
                      className="endpoint-button"
                      icon="pi pi-plus-circle"
                      tooltip={t("add")}
                      type="button"
                      name={apiEndpointNum}
                      onClick={(e)=>{
                        // console.log(e)
                        setApiEndpointNum(apiEndpointNum + 1);
                        const _emptyEndpoint = {...emptyApiEndpoint, index: apiEndpointNum}
                        apiEndpoints.push(_emptyEndpoint)
                        console.log(apiEndpoints)
                      }}

                    />
                    <Button
                      className="endpoint-button"
                      icon="pi pi-minus-circle"
                      tooltip={t("remove")}
                      type="button"
                      onClick={() => {
                        apiEndpointNum !== 0 &&
                          setApiEndpointNum(apiEndpointNum - 1);
                        apiEndpoints.pop();
                        // apiEndpointComponents.pop();
                        console.log(apiEndpoints)
                      }}
                    />
                    <Button
                      className="endpoint-button-wip"
                      icon="pi pi-exclamation-circle"
                      tooltip={t("warnWorkInProgressBelow")}
                    />
                    <div className="p-float-label endpoint-container">
                    {apiEndpointNum !== 0 && (
                      apiEndpoints.map((s,i) => ( 
                        <div className="endpoint-container-item">
                          <Dropdown
                              id={"serviceApiMethodType" + i}
                              name={i}
                              className="w-full p-inputtext-sm endpoint-item"
                              options={methodTypes}
                              value={apiEndpoints[i].methodType}
                              //apiEndpoints[apiEndpointNum]?.methodType}
                              onChange={(e) => {
                                // apiEndpoints[i].methodCode = e.value.code;
                                // apiEndpoints[i].methodType = e.value.name;
                                console.log(apiEndpoints[i].methodType)
                                handleNewServiceEndpoint(e,i)
                              }}
                              optionLabel="name"
                              // optionValue="code"
                              appendTo="self"
                            />
                            <InputText
                              id={"serviceApiEndpoint"+ i}
                              type="text"
                              name={i}
                              className="p-inputtext-sm w-full endpoint-item"
                              maxLength={250}
                              onChange={(e) => {
                                apiEndpoints[i].endPoint = e.target.value;
                                console.log(apiEndpoints);
                              }}
                            />
                            {/* <Button
                              name={"serviceApiParameter"+ i}
                              style={{ minWidth: "40px", minHeight: "40px" }}
                              icon="pi pi-wrench"
                              tooltip={t("setParameters")}
                              onClick={(e,pi) => {
                                setParamVisible(true);
                                // setSingularParam({
                                //   ...singularParam,
                                //   index: e.target.name,
                                // });
                                setActiveParamNum(i)
                                console.log(i)
                              }}
                            /> */}
                        </div>
                        ))
                      )}
                    </div>
                  </div>
                )}
              <div className="p-float-label w-full input-container">
                <p className="platformColor" htmlFor="serviceAuthNeed">
                  {t("pleaseSelectServiceAuthNeed")}(*)
                </p>
                <ToggleButton
                  checked={checked}
                  // checked={serviceAuthNeedTextTemplate(t(checked)) }
                  style={{
                    backgroundColor: "#fff",
                    color: "#00a3ff",
                    maxWidth: "4rem",
                  }}
                  onChange={(e) => {
                    setServiceData({ ...serviceData, authNeed: e.value });
                    setChecked(e.value);
                  }}
                  className="w-8rem service-toggle"
                />
                {checked && (
                  <>
                    <p className="platformColor">{t("serviceAuthUrl2")}(*)</p>
                    <InputText
                      id="serviceserviceAuthUrl"
                      type="text"
                      className="p-inputtext-sm w-full"
                      value={serviceData?.authUrl}
                      maxLength={250}
                      onChange={(e) =>
                        setServiceData({
                          ...serviceData,
                          authUrl: e.target.value,
                        })
                      }
                    />
                  </>
                )}
              </div>
             
              <div className="p-float-label w-full input-container">
                <MultiSelect
                  id="serviceRoles"
                  className="w-full p-inputtext-sm"
                  value={selectedRoles}
                  options={roles}
                  display="chip"
                  maxSelectedLabels={5}
                  onChange={(e) => setSelectedRoles(e.value)}
                  optionLabel="name"
                  appendTo="self"
                />
                <label className="platformColor" htmlFor="serviceRoles">
                  {t("pleaseSelectRoleToAssignToService")}
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitServiceCreation}
          />
        </CreateServicesDialogContainer>
      </Dialog>
      <Dialog
        visible={editServiceVisible}
        modal
        header={t("serviceInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setEditServiceVisible(false);
          // setServiceData(emptyServiceData);
          setServiceData({});
          setSingularService({});
          // setSelectedRoles(null);
          setApiEndpointNum(0);
          setApiEndpoints([]);
          setApiEndpointComponents([]);
          setChecked(false);
        }}
      >
        <CreateServicesDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          ></div>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularService?.name}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      name: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("serviceName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceOrganisationName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularService?.organisationName}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      organisationName: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="serviceOrganisationName"
                >
                  {t("serviceOrganisationName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceWebsite"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularService?.website}
                  maxLength={100}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      website: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceWebsite">
                  {t("serviceWebsite")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputTextarea
                  id="serviceShortDescription"
                  type="text"
                  className="p-inputtext-sm w-full shortdescription-textarea"
                  value={singularService?.shortDescription}
                  maxLength={150}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      shortDescription: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="serviceShortDescription"
                >
                  {t("serviceShortDescription")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputTextarea
                  id="serviceDescription"
                  type="text"
                  className="p-inputtext-sm w-full description-textarea"
                  value={singularService?.description}
                  maxLength={800}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      description: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceDescription">
                  {t("serviceDescription")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <Dropdown
                  id="serviceStatus"
                  className="w-full p-inputtext-sm"
                  optionLabel="statusTypeName"
                  optionValue="statusTypeCode"
                  placeholder={statusInfo(singularService?.status)}
                  value={singularService?.status}
                  options={statusTypesList}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      status: e.target.value,
                    })
                  }
                />

                <label className="platformColor" htmlFor="serviceStatus">
                  {t("serviceStatus")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <Dropdown
                  id="serviceType"
                  className="w-full p-inputtext-sm"
                  optionLabel="serviceTypeName"
                  optionValue="serviceTypeName"
                  placeholder={singularService?.type}
                  value={singularService?.type}
                  options={serviceTypesList}
                  onChange={(e) => {
                    setSingularService({
                      ...singularService,
                      type: e.target.value,
                    });
                  }}
                />
                <label className="platformColor" htmlFor="type">
                  {t("serviceType")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="endpoint"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularService?.endpoint}
                  maxLength={250}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      endpoint: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="endpoint">
                  {t("serviceEndpoint")} (*)
                </label>
              </div>
              {singularService?.type === "API" &&
                existingAPIEndpointNum > 0 && (
                  <div className="p-float-label input-container">
                    {apiEndpoints.map((item, index) => (
                      <div className="endpoint-container-item">
                        <Dropdown
                          value={apiEndpoints[index].methodType}
                          options={methodTypes}
                          optionLabel="name"
                          optionValue="code"
                          onChange={(e) => {
                            apiEndpoints[index].methodType = getMethodName(
                              e.target.value
                            );
                            apiEndpoints[index].methodCode = e.target.value;
                            console.log(apiEndpoints);
                          }}
                        />
                        <InputText
                          // style={{ backgroundColor: "" }}
                          value={apiEndpoints[index].endPoint}
                          // placeholder={apiEndpoints[index].endPoint}
                          onChange={(e) => {
                            console.log(existingAPIEndpointNum)
                            // apiEndpoints[index].endPoint = e.target.value;
                            // setApiEndpoints(
                            //   apiEndpoints[index].endPoint = e.target.value,
                            // );
                            // apiEndpoints[index].endPoint =  e.target.value

                            setApiEndpoints([
                              ...apiEndpoints,
                              (apiEndpoints[index].endPoint = e.target.value),
                            ]);
                            // console.log(item)
                            console.log(apiEndpoints);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              {singularService?.type === "API" && (
                <div className="p-float-label input-container">
                  <Button
                    className="endpoint-button"
                    icon="pi pi-plus-circle"
                    tooltip={t("add")}
                    type="button"
                    onClick={(e)=>{
                      setApiEndpointNum(apiEndpointNum + 1);
                      const _emptyEndpoint = {...emptyApiEndpoint, index: apiEndpointNum}
                      apiEndpoints.push(_emptyEndpoint)
                      console.log(apiEndpoints)
                    }}
                  />
                  <Button
                    className="endpoint-button"
                    icon="pi pi-minus-circle"
                    tooltip={t("remove")}
                    type="button"
                    onClick={() => {

                      apiEndpointNum !== 0 &&
                        setApiEndpointNum(apiEndpointNum - 1);
                      apiEndpoints.pop();
                      // apiEndpointComponents.pop();
                      console.log(apiEndpoints)
                    }}
                  />
                </div>
              )}
              <div className="p-float-label w-full input-container">
                <p className="platformColor" htmlFor="serviceAuthNeed">
                  {t("pleaseSelectServiceAuthNeed")}(*)
                </p>
                <ToggleButton
                  checked={singularService?.authNeed}
                  // checked={serviceAuthNeedTextTemplate(singularService?.authNeed)}
                  //conditional based on localstorage lang
                  style={{
                    backgroundColor: "#fff",
                    color: "#00a3ff",
                    maxWidth: "4rem",
                  }}
                  onChange={(e) => {
                    setSingularService({
                      ...singularService,
                      authNeed: e.value,
                    });
                    setChecked(e.value);
                  }}
                  className="w-8rem service-toggle"
                />
              </div>
              {singularService?.authNeed && (
                <div className="p-float-label w-full input-container">
                  <p className="platformColor" htmlFor="serviceAuthNeed">
                    {t("serviceAuthUrl3")} (*)
                  </p>
                  <InputText
                    value={singularService?.serviceAuthUrl}
                    type="text"
                    onChange={(e) => {
                      setSingularService({
                        ...singularService,
                        serviceAuthUrl: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
              <div className="p-float-label w-full input-container">
                <MultiSelect
                  id="serviceRoles"
                  className="w-full p-inputtext-sm"
                  value={singularService?.roles}
                  options={roles}
                  display="chip"
                  maxSelectedLabels={5}
                  onChange={(e) =>
                    setSingularService({
                      ...singularService,
                      roles: e.value,
                    })
                  }
                  optionLabel="name"
                  appendTo="self"
                />
                <label className="platformColor" htmlFor="serviceRoles">
                  {t("serviceRoles")}
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitServiceEdit}
          />
        </CreateServicesDialogContainer>
      </Dialog>
      <Dialog
        visible={viewServiceVisible}
        modal
        header={t("serviceInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewServiceVisible(false);
          setSingularService({});
        }}
      >
        <ViewServicesDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          ></div>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.name}
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("serviceName")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceOrganisationName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.organisationName}
                />
                <label
                  className="platformColor"
                  htmlFor="serviceOrganisationName"
                >
                  {t("serviceOrganisationName")}
                </label>
              </div>
              {singularService?.website !== undefined &&
                singularService?.website !== null &&
                singularService?.website !== "" && (
                  <div className="p-float-label input-container">
                    <InputText
                      id="serviceWebsite"
                      type="text"
                      className="p-inputtext-sm w-full view-container"
                      disabled
                      value={singularService?.website}
                    />
                    <label className="platformColor" htmlFor="serviceWebsite">
                      {t("serviceWebsite")}
                    </label>
                  </div>
                )}
              <div className="p-float-label input-container">
                <InputText
                  id="serviceShortDescription"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.shortDescription}
                  style={{ minHeight: "90px" }}
                />
                <label
                  className="platformColor"
                  htmlFor="serviceShortDescription"
                >
                  {t("serviceShortDescription")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceDescription"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.description}
                  style={{ minHeight: "120px" }}
                />
                <label className="platformColor" htmlFor="serviceDescription">
                  {t("serviceDescription")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="registrationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    singularService?.registrationTime
                  )}
                />
                <label className="platformColor" htmlFor="registrationTime">
                  {t("serviceregistrationTime")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="lastModificationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    singularService?.lastModificationTime
                  )}
                />
                <label className="platformColor" htmlFor="lastModificationTime">
                  {t("servicelastModificationTime")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("serviceStatus")}</p>
                {actionTemplateStatus(singularService)}
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("serviceType")}</p>
                {typeListTemplate(singularService)}
              </div>
              {/* <br /> */}

              <div className="p-float-label input-container">
                <p className="platformColor" htmlFor="endpoint">
                  {t("serviceEndpoint")}
                </p>
                <InputText
                  id="endpoint"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.endpoint}
                />
              </div>
              {singularService?.endpointObject !== undefined &&
                singularService?.endpointObject !== null &&
                singularService?.endpointObject !== "" &&
                singularService?.type === "API" && (
                  // singularServiceEndpointObject = JSON.parse(singularService?.endpointObject)
                  <div className="p-float-label input-container">
                    {apiEndpointTemplate(singularService?.endpointObject)}
                  </div>
                )}

              <div className="p-float-label input-container">
                <p className="chip-title">{t("serviceAuthNeed")}</p>
                {serviceAuthNeedTemplate(singularService?.authNeed)}
                {singularService?.authNeed && (
                  <>
                  <p className="platformColor" htmlFor="serviceAuthUrl">
                    {t("serviceAuthUrl3")}
                  </p>
                  <InputText
                    id="serviceAuthUrl"
                    type="text"
                    className="p-inputtext-sm w-full view-container"
                    disabled
                    value={singularService?.serviceAuthUrl}
                  /></>
                )}
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("serviceRoles")}</p>
                {serviceRoleListTemplate(singularService)}
              </div>
            </div>
          </form>
        </ViewServicesDialogContainer>
      </Dialog>
      {/* <Dialog
        visible={paramVisible}
        // header={t("parametersForEndpoint")+singularParam.index}
        header={t("parametersForEndpoint")+activeParamNum}
        onHide={() => {
          setParamVisible(false);
          // setSingularParam({});
          setParamNum(0);
          setActiveParamNum(-1);
        }}
      >
        <div>
          <div>
            <Button
              icon="pi pi-plus-circle"
              tooltip={t("add")}
              onClick={()=>{
                setParamNum(paramNum + 1);
                apiEndpoints[activeParamNum].params.push(emptyParam)
                console.log(apiEndpoints[activeParamNum].params)
              }}
            />
            <Button
              icon="pi pi-minus-circle"
              tooltip={t("remove")}
              onClick={()=> {
                paramNum !== 0 && setParamNum(paramNum - 1);
                apiEndpoints[activeParamNum].params.pop();
                console.log(apiEndpoints[activeParamNum].params)
              }}
            />
          </div>
          {paramNum !== 0 && 
           (apiEndpoints[activeParamNum].params.map((p) => (

            <div>
              <InputText onChange={(e)=>console.log(typeof(e.target.value))}/>
            </div>
           )))
          } 
          <div>
            <Button className="submit-btn" label={t("submit")}/>
          </div>
        </div>
      </Dialog> */}
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ServicesContainer>
  );
};

export default withTranslation()(EditServices);
