import styled from "@emotion/styled";
export const ApiRequestsDetailsContainer = styled.div`
  min-width: 650px;
  min-height: ${(props)=>props.customApiRequestStyleDropdownMinHeight};
  
  .api-request-details-panel {
    padding-left: 0.2rem;
    padding-rigt: 0.2rem;
  }

  .api-endpoint-item-endpoint-container {
    margin-bottom: 1rem;

    .api-request-custom-request-method-select {
      .p-dropdown-label p-inputtext {
        color: #000;
      }
    }
    .api-endpoint-item-endpoint-btn {
    }
  }
  .api-request-custom-request-body-container {
    // width : 500px;
    .p-panel-header {
      // background-color: #fff;
    }
    .api-request-custom-request-body-inputtextarea {
      // width:
    }
  }
`;
