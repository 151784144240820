import {
  ProductModelViewContainer,
  DPPViewContainer,
  AvailableServicesContainer,
  MDCRequestContainer,
  ServicesCardsSection,
  LcaServiceResutlsContainer
} from "./assets/ProductModelViewContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import WaitDialog from "../components/WaitDialog";
import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import SysService from "../functionalities/services/sys.service";
import PubService from "../functionalities/services/pub.service";
import ApiService from "../functionalities/services/api.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { redirect, useParams } from "react-router";
import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
  isCTProvider,
  isCTRepairer,
  getMyRoles
} from "../functionalities/common/token-check";
import { Image } from "primereact/image";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import MDCDialog from "../components/MDCDialog"
import QRCodeGenerator from "../components/QRCodeGenerator";
import ServicesCards from "../components/ServicesCards";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { giveUsername } from "../functionalities/common/token-check";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from 'primereact/overlaypanel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { BsFiletypeJson,BsFiletypePdf,BsFiletypeTxt } from "react-icons/bs";

const ProductModelView = ({ t }) => {
  const { code } = useParams();

  const [loading, setLoading] = useState(false);
  const [viewAvailableServices, setViewAvailableService] = useState(false);
  const [viewDPPOptions, setViewDPPOptions] = useState(false);
  const [viewSavedLcaResults, setViewSavedLcaResults] = useState(false);
  const [productModelData, setProductModelData] = useState({});
  const [services, setServices] = useState([]);
  const [mdcVisible, setMDCVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [savedLcaResults, setSavedLcaResults] = useState([]);
  const [singularRecord, setSingularRecord] = useState("");
  const [selectedLcaResult, setSelectedLcaResult] = useState({});
  const [passportRequestResult, setPassportRequestResult] = useState({});
  const [passportId, setPassportId] = useState("");
  const navigate = useNavigate();
  const refObjCode = useRef(null);
  const refObjDownload = useRef(null);
  const refObjDownloadServiceResult = useRef(null);
  const refObjPassportServiceResult = useRef(null);

  const submitToMDC = () => {

  }

  const resultActionsTemplate = (data) => {
    setSelectedLcaResult(data)
    return (
      <div>
        <Button
          icon="pi pi-code"
          // icon={}
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            setSingularRecord(data?.registeredServiceResult);
            refObjCode.current.toggle(e);
          }}
          id="buttonFontWeight"
        />
        <Button
          icon="pi pi-download"
          tooltip={t("download")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            setSingularRecord(data?.registeredServiceResult);
            refObjDownload.current.toggle(e);
          }}
          id="buttonFontWeight"
        />
        <Button
          icon="pi pi-clone"
          tooltip={t("copyToClipboard")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            if (
              GeneralFunctions.copyToClipboard(data?.registeredServiceResult)
            ) {
              toast.info(<Trans i18nKey={"infoCopiedToClipboard"} />);
            } else {
              toast.error(<Trans i18nKey={"failCopiedToClipboard"} />);
            }
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };
  const resultServiceTemplate = (data) => {
    return (
    <div>{data?.registeredService?.name}</div>

    )
  }
 const methodTemplate = (data) => {
    let m = "";
    if(data?.registeredServiceEndpointMethod !== undefined && data?.registeredServiceEndpointMethod !== null) { m = data?.registeredServiceEndpointMethod.toLowerCase();}
    switch (m) {
      case "get":
        return (
            <Chip
              label={"GET"}
              className="method-chip"
              style={{
                backgroundColor: "#61affe",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
      case "post":
        return (
            <Chip
              label={"POST"}
              className="method-chip"
              style={{
                backgroundColor: "#49cc90",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
      case "put":
        return (
            <Chip
              label={"PUT"}
              className="method-chip"
              style={{
                backgroundColor: "#fca434",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
      case "delete":
        return (
            <Chip
              label={"DELETE"}
              className="method-chip"
              style={{
                backgroundColor: "#f93e3e",
                marginRight: "0.7rem",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
              }}
            />
        );
    }
  }
  const saveTimeTemplate = (data) => {
    return (GeneralFunctions.getDateTemplateV2(data?.registeredServiceResultSaveTime))
  }
  const roleTemplate = () => {
    const roles = getMyRoles();    
    return roles.map((e)=>(
      <Chip
        label={e}
        className="mr-2 mb-2"
        style={{
          backgroundColor: "#382c7c",
          color: "#FFFFFF",
          marginLeft: "0.125rem",
          marginRight: "0.125rem",
        }}
      />
    ))
  }
  const roleTemplateOld = () => {
    const roles = [
      isCTAdmin() && (
        <Chip
          label={t("admin")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
      isCTManufacturer() && (
        <Chip
          label={t("manufacturer")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
      isCTProvider() && (
        <Chip
          label={t("provider")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
      isCTRepairer() && (
        <Chip
          label={t("repairer")}
          className="mr-2 mb-2"
          style={{
            backgroundColor: "#382c7c",
            color: "#FFFFFF",
            marginLeft: "0.125rem",
            marginRight: "0.125rem",
          }}
        />
      ),
    ];
    return roles;
  };
  const redirectToMDC = () => {
    if(!isLoggedIn()){
      navigate("/login");
    }
    else{
      // setLoading(true);
      setMDCVisible(true);
      //navigate();
    }
  };
  const filterServices = () => {};

  const generateMetaDataCatalogPDF = async () => {
    if(!isLoggedIn()){
      navigate("/login");
    }
    else if(isLoggedIn() && isCTManufacturer()){

      setLoading(true);
      const doc = new jsPDF();

      // Başlangıç Y pozisyonu ve satır aralığı
      let yPosition = 20;
      const lineHeight = 10;
      const sectionSpacing = 15; // Bölümler arası ekstra boşluk

      // Başlık ve görsel ayarları
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(productModelData.name + " MetaData Catalog", 105, yPosition, {
        align: "center",
      });
      yPosition += sectionSpacing;

      // Alanları düzenleyerek ekleme
      const fields = [
        {
          label: "MetaData catalog description",
          value: productModelData.mdcDescription,
        },
        {
          label: "MetaData catalog field",
          value: productModelData.mdcField,
        },
        { label: "MetaData catalog url", value: productModelData?.mdcUrl },
      ];

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      // Her bir alanı kontrol ederek gösterme
      fields.forEach((field) => {
        if (field.value) {
          doc.text(`${field.label}:`, 20, yPosition);
          doc.text(`${field.value}`, 100, yPosition); // Değerler sağa hizalanır
          yPosition += lineHeight + 2; // Alanlar arasında ekstra boşluk
        }
      });

      // Çerçeve çizme
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.rect(15, 10, 180, yPosition - 10);

      // PDF'i indir
      doc.save(productModelData.name + "-MetaData-Catalog-"+GeneralFunctions.getDateTemplateV4(Date.now())+".pdf");
      setLoading(false);
      toast.success(<Trans i18nKey={"successMDCPDFCreation"} />);
    }
    else{
      setLoading(false);
      toast.warning(<Trans i18nKey="warnWrongRole" />);
      return;
    }
  };

  const handleRedirectToProvider = () => {
    if (productModelData?.passportCode && productModelData?.providerEndpoint) {
      if (productModelData?.providerEndpoint.endsWith("/")) {
        window.open(
          productModelData?.providerEndpoint + productModelData?.passportCode,
          "_blank",
          "noopener,noreferrer"
        );
      } else {
        window.open(
          productModelData?.providerEndpoint +
            "/" +
            productModelData?.passportCode,
          "_blank",
          "noopener,noreferrer"
        );
      }
    } else {
      toast.warning(<Trans i18nKey={"warnDPPRedirectNotPossible"} />);
    }
  };
  const handlePassportRequest = (e) => {
    //console.log(productModelData)
    if (productModelData?.providerApiEndpoint) {
      if(passportId !== undefined && passportId !== null && passportId !== "" ){
        let pApiEndpoint = productModelData?.providerApiEndpoint
        if(!productModelData?.providerApiEndpoint.endsWith("/")){
         pApiEndpoint = pApiEndpoint + "/";
        }
        setLoading(true);
        ApiService.providerPassportRequest(pApiEndpoint,passportId).then(
          (response) => {
            console.log(response?.data);
            setPassportRequestResult(response?.data)
            setLoading(false);
            refObjPassportServiceResult.current.toggle(e);
          },
          (error) => {
            const errorMessage =
              (error.response &&
                error.response?.data &&
                error.response?.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString();

            toast.error(
              error.response.status +
                " " +
                error.response?.data.responseMessage +
                " " +
                error.name
            );
            setLoading(false);

          }
        )
      } else {
        toast.warning(<Trans i18nKey={"warnPassportIdMandatory"} />)
      }

    } else {
      toast.warning(<Trans i18nKey={"warnPassportRequestNotPossible"} />);
    }

  }

  useEffect(() => {
    setLoading(true);
    PubService.getProductModelByCode(code).then(
      (response) => {
        setLoading(false);
        setProductModelData(response?.data);
        if (
          response?.data?.id !== undefined &&
          response?.data?.id !== null &&
          response?.data?.id !== ""
        ) {
          PubService.getRegisteredServiceResultRecordsByPM(
            response?.data?.id
          ).then(
            (response) => {
              setSavedLcaResults(response?.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response?.data &&
                  error.response?.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();

              toast.error(
                error.response.status +
                  " " +
                  error.response?.data.responseMessage +
                  " " +
                  error.name
              );
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        toast.error(<Trans i18nKey={errorMessage} />);
        setLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    if (isLoggedIn()) {
      SysService.getRegisteredServices().then(
        (response) => {
          setServices(response?.data);
        },
        (error) => {
          if(error.response.status === 403){
            toast.warn(<Trans i18nKey={t(error.response?.data.responseMessage)}/>)
            PubService.getRegisteredServices().then(
              (response) => {
                setServices(response?.data);
              },
              (error) => {
                const errorMessage =
                  (error.response &&
                    error.response?.data &&
                    error.response?.data.message) ||
                  error.message ||
                  error.timeoutErrorMessage ||
                  error.toString();
      
                toast.error(
                  error.response.status +
                    " " +
                    error.response?.data.responseMessage +
                    " " +
                    error.name
                );
              }
            );

          }else{
            const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          toast.error(
            error.response.status +
              " " +
              error.response?.data.responseMessage +
              " " +
              error.name
          );
          }
          
        }
      );
      setUsername(giveUsername())
    } else {
      PubService.getRegisteredServices().then(
        (response) => {
          setServices(response?.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          toast.error(
            error.response.status +
              " " +
              error.response?.data.responseMessage +
              " " +
              error.name
          );
        }
      );
    }
  }, []);
  useEffect(() => {
    filterServices();
  }, [services]);
  // useEffect(() => {
  //   if(Object.keys(productModelData).length > 0){
  //     PubService.getRegisteredServiceResultRecordsByPM(productModelData?.id).then(
  //       (response) => {
  //         setSavedLcaResults(response?.data);
  //       },
  //       (error) => {
  //         const errorMessage =
  //           (error.response &&
  //             error.response?.data &&
  //             error.response?.data.message) ||
  //           error.message ||
  //           error.timeoutErrorMessage ||
  //           error.toString();

  //         toast.error(
  //           error.response.status +
  //             " " +
  //             error.response?.data.responseMessage +
  //             " " +
  //             error.name
  //         );
  //       }
  //     )
  //   }
  // },[productModelData])
  return (
    <ProductModelViewContainer>
      <div
        className="px-2 platformColor"
        id="dialogTitle"
        style={{
          fontSize: "16px",
          lineHeight: "18px",
          fontWeight: "700",
          marginBottom: "0.5rem",
        }}
      ></div>
      <div className="flex align-items-center gap-3 ">
        {productModelData?.imageUri !== "" &&
          productModelData?.imageUri !== null &&
          productModelData?.imageUri !== undefined && (
            <div className="p-float-label search-item-info-container">
              <p className="platformColor">
                {t("productModelImageURI")}
                {":"}{" "}
              </p>
              <p className="search-pm-image-link">
                {productModelData?.imageUri}
              </p>
              <Image
                className="search-pm-image"
                src={productModelData?.imageUri}
              ></Image>
            </div>
          )}

        {isLoggedIn() && (
          <div className="p-float-label search-item-info-container">
            <p>
              {" "}
              <span className="platformColor">
                {getMyRoles().length > 1 ? t("yourRoles") : t("yourRole")}
                {":"}
              </span>{" "}
              {roleTemplate()}
            </p>
          </div>
        )}
        {productModelData?.organisationName !== "" &&
          productModelData?.organisationName !== null &&
          productModelData?.organisationName !== undefined && (
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <strong>
                  <span className="platformColor">
                    {t("productModelManufacturer")}
                    {":"}
                  </span>{" "}
                  {productModelData?.organisationName}
                </strong>
              </p>
            </div>
          )}
        <div className="p-float-label search-item-info-div">
          {productModelData?.sector !== "" &&
            productModelData?.sector !== null &&
            productModelData?.sector !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelSector")}
                    {":"}
                  </span>{" "}
                  {productModelData?.sector}
                </p>
              </div>
            )}
          {productModelData?.productCategory !== "" &&
            productModelData?.productCategory !== null &&
            productModelData?.productCategory !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelCategory")}
                    {":"}
                  </span>{" "}
                  {productModelData?.productCategory}
                </p>
              </div>
            )}
          {productModelData?.productType !== "" &&
            productModelData?.productType !== null &&
            productModelData?.productType !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelType")}
                    {":"}
                  </span>{" "}
                  {productModelData?.productType}
                </p>
              </div>
            )}
          {productModelData?.name !== "" &&
            productModelData?.name !== null &&
            productModelData?.name !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelName")}
                    {":"}
                  </span>{" "}
                  {productModelData?.name}
                </p>
              </div>
            )}
          {productModelData?.brandName !== "" &&
            productModelData?.brandName !== null &&
            productModelData?.brandName !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelBrandName")}
                    {":"}
                  </span>{" "}
                  {productModelData?.brandName}
                </p>
              </div>
            )}
          {productModelData?.ean !== "" &&
            productModelData?.ean !== null &&
            productModelData?.ean !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelEan")}
                    {":"}
                  </span>{" "}
                  {productModelData?.ean}
                </p>
              </div>
            )}
          {productModelData?.mpn !== "" &&
            productModelData?.mpn !== null &&
            productModelData?.mpn !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelMpn")}
                    {":"}
                  </span>{" "}
                  {productModelData?.mpn}
                </p>
              </div>
            )}
          {/* {productModelData?.code !== "" &&
            productModelData?.code !== null &&
            productModelData?.code !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelCode")}
                    {":"}
                  </span>{" "}
                  {productModelData?.code}
                </p>
              </div>
            )} */}
          {productModelData?.gtin !== "" &&
            productModelData?.gtin !== null &&
            productModelData?.gtin !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p>
                  {" "}
                  <span className="platformColor">
                    {t("productModelGtin")}
                    {":"}
                  </span>{" "}
                  {productModelData?.gtin}
                </p>
              </div>
            )}
          {productModelData?.description !== "" &&
            productModelData?.description !== null &&
            productModelData?.description !== undefined && (
              <div className="p-float-label search-item-info-container">
                <p className="platformColor">
                  {t("productModelDescription")}
                  {":"}
                </p>
                <p className="">{productModelData?.description}</p>
              </div>
            )}
        </div>
        {/* {productModelData?.providerId ? ( */}
        {productModelData?.providerName ? (
          <div className="p-float-label search-item-info-div">
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelProvider")}
                  {":"}
                </span>{" "}
                {/* {productModelData?.providerId} */}
                {productModelData?.providerName}
              </p>
            </div>
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelCreationDateAndTime")}
                  {":"}
                </span>{" "}
                {/* {productModelData?.creationTime} */}
                {GeneralFunctions.getDateTemplateV2(
                  productModelData?.creationTime
                )}
              </p>
            </div>
          </div>
        ) : (
          <div className="p-float-label search-item-info-div">
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelProvider")}
                  {":"}
                </span>{" "}
                {t("circthreadPlatform")}
              </p>
            </div>
            <div className="p-float-label search-item-info-container">
              <p>
                {" "}
                <span className="platformColor">
                  {t("productModelCreationDateAndTime")}
                  {":"}
                </span>{" "}
                {/* {productModelData?.creationTime} */}
                {GeneralFunctions.getDateTemplateV2(
                  productModelData?.creationTime
                )}
              </p>
            </div>
          </div>
        )}

        {/* <div className="p-float-label input-container">
                <p className="chip-title">{t("productModelStatus")}</p>
                {actionTemplateStatus(productModelData)}
              </div> */}
        {productModelData?.passportCode &&
          productModelData?.providerEndpoint && (
            <div className="p-float-label search-item-info-container">
              {/* <p className="chip-title">{t("availableServices")}</p> */}
              <Button
                className="pm-view-dialog-btn"
                onClick={() => {
                  setViewDPPOptions(true);
                }}
              >
                {t("overallDPP")}
              </Button>
            </div>
          )}

        <div className="p-float-label search-item-info-container">
          {/* <p className="chip-title">{t("availableServices")}</p> */}
          <Button
            className="pm-view-dialog-btn"
            onClick={() => {
              redirectToMDC();
              // generateMetaDataCatalogPDF();
            }}
          >
            {t("metaDataCatalogue")}
          </Button>
        </div>
        <div className="p-float-label search-item-info-container">
          {/* <p className="chip-title">{t("availableServices")}</p> */}
          <Button
            className="pm-view-dialog-btn"
            onClick={() => {
              setViewAvailableService(true);
            }}
          >
            {t("availableServices")}
          </Button>
        </div>
        {savedLcaResults.length > 0 && (
          <div className="p-float-label search-item-info-container">
            <Button
              className="pm-view-dialog-btn"
              onClick={() => {
                setViewSavedLcaResults(true);
              }}
            >
              {t("savedLcaResults")}
            </Button>
          </div>
        )}

        <div className="p-float-label search-item-qr-div">
          <QRCodeGenerator
            link={process.env.REACT_APP_SERVER_ADDRESS + "/pm/" + code}
            id={code}
          />
        </div>
        {/* <div>
                {productModelData?.documents?.length > 0 && (
                  <DataTable
                    name="dt"
                    size="small"
                    showGridlines
                    value={productModelData?.documents}
                    dataKey="id"
                    responsiveLayout="scroll"
                    emptyMessage={t("noProductModelDocumentsFound")}
                    paginator
                    rows={5}
                    loading={loading}
                  >
                    <Column
                      field="artifactTitle"
                      header={t("productModelDocumentArtifactTitle")}
                    ></Column>
                    <Column
                      field="artifactPublicUrl"
                      header={t("productModelDocumentArtifactPublicUrl")}
                    ></Column>
                    <Column
                      field="artifactLocalUrl"
                      header={t("productModelDocumentArtifactLocalUrl")}
                    ></Column>
                    <Column
                      field="description"
                      header={t("productModelDocumentDescription")}
                    ></Column>
                    <Column
                      field={documentKeywordTemplate}
                      header={t("productModelDocumentKeyword")}
                    ></Column>
                    <Column
                      field={documentCreationtimeTemplate}
                      header={t("productModelCreationTime")}
                    ></Column>
                  </DataTable>
                )}
              </div> */}
      </div>
      <Dialog
        visible={viewDPPOptions}
        modal
        header={t("overallDPP")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewDPPOptions(false);
          setPassportId("");
        }}
        style={{ width: "550px" }}
      >
        <DPPViewContainer>
          <div className="dpp-view-container">
            <div className="view-dpp-btn-container">
              <Button
                className="dpp-view-btn"
                onClick={() => {
                  handleRedirectToProvider();
                }}
                label={t("viewModelLevelDPP")}
              />
            </div>
            <div className="or-container">
              <p className="or-text">{t("or")}</p>
            </div>
            <div className="enter-dpp-info-btn-container">
              {/* <InputText
                className="dpp-view-inputarea"
                placeholder={t("enterItemIdentifier")}
              />
              <Button
                icon="pi pi-external-link"
                className="dpp-view-submit-btn"
                onClick={() => {}}
              />

              <InputText
                className="dpp-view-inputarea"
                placeholder={t("enterBatchId")}
              />
              <Button
                icon="pi pi-external-link"
                className="dpp-view-submit-btn"
                onClick={() => {}}
              /> */}
              <InputText
                className="dpp-view-inputarea"
                placeholder={t("enterPassportId")}
                type="text"
                onChange={(e) => {
                  setPassportId(e.target.value);
                }}
              />
              <Button
                icon="pi pi-external-link"
                className="dpp-view-submit-btn"
                tooltip={t("sendPassportRequestToProviderAPIEndpoint")}
                onClick={(e) => {
                  handlePassportRequest(e);
                }}
              />
            </div>
          </div>
          <OverlayPanel ref={refObjPassportServiceResult}>
            {passportRequestResult !== undefined &&
              passportRequestResult !== null &&
              passportRequestResult !== "" && (
                <div>
                  <Button
                    icon="pi pi-clone"
                    tooltip={t("copyToClipboard")}
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                      if (
                        GeneralFunctions.copyToClipboard(
                          JSON.stringify(passportRequestResult, undefined, 2)
                        )
                      ) {
                        toast.info(<Trans i18nKey={"infoCopiedToClipboard"} />);
                      } else {
                        toast.error(
                          <Trans i18nKey={"failCopiedToClipboard"} />
                        );
                      }
                    }}
                    id="buttonFontWeight"
                  />
                  <Button
                    className="p-button-rounded p-button-text"
                    // icon="pi pi-file"
                    icon={<BsFiletypeTxt />}
                    tooltip={t("downloadAsTxt")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsTextFile(
                        JSON.stringify(
                          passportRequestResult,
                          undefined,
                          2
                        ),
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />
                  <Button
                    className="p-button-rounded p-button-text"
                    icon={<BsFiletypeJson />}
                    tooltip={t("downloadAsJson")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsJson(
                        passportRequestResult,
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />

                  <Button
                    className="p-button-rounded p-button-text"
                    // icon="pi pi-file-pdf"
                    icon={<BsFiletypePdf />}
                    tooltip={t("downloadAsPdf")}
                    onClick={() => {
                      GeneralFunctions.saveDataAsPdfFile(
                        JSON.stringify(
                          passportRequestResult,
                          undefined,
                          2
                        ),
                        passportId +
                          "-" +
                          GeneralFunctions.getDateTemplateV4(Date.now())
                      );
                    }}
                  />
                  <ScrollPanel style={{ width: "100%", height: "250px" }}>
                    <div className="service-request-result-response-data-container">
                      <pre className="service-request-result-response-data">
                        {JSON.stringify(passportRequestResult, undefined, 2)}
                      </pre>
                    </div>
                  </ScrollPanel>
                </div>
              )}
          </OverlayPanel>
        </DPPViewContainer>
      </Dialog>
      <Dialog
        visible={viewAvailableServices}
        modal
        header={t("availableServices")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewAvailableService(false);
        }}
      >
        <AvailableServicesContainer>
          {/* <ServicesCardsSection>
            <div className='description'>
               
            </div>
              {services.length > 0 ? (
                <div className='service-card-container'>
                    {services?.map((item, index) => (
                      <ServicesCards
                        item={item}
                        key={index}
                      />
                    ))}
                </div>
              ):(
                <div className='no-services'>{t("noServicesFound")}</div>
              )}

          </ServicesCardsSection> */}
          {services.length > 0 ? (
            <div className="service-card-container">
              {services?.map((item, index) => (
                <ServicesCards item={item} key={index} page={"pmView"} />
              ))}
            </div>
          ) : (
            <div className="no-services">{t("noServicesFound")}</div>
          )}
        </AvailableServicesContainer>
      </Dialog>
      <Dialog
        visible={mdcVisible}
        header={t("sendMDCRequest")}
        onHide={() => {
          setMDCVisible(false);
        }}
      >
        {/* <MDCRequestContainer>

          

        </MDCRequestContainer> */}
        <MDCDialog />
      </Dialog>
      <Dialog
        visible={viewSavedLcaResults}
        header={t("savedLcaResults")}
        onHide={() => {
          setViewSavedLcaResults(false);
          setSingularRecord("");
        }}
      >
        <LcaServiceResutlsContainer>
          <DataTable
            showGridlines
            value={savedLcaResults}
            paginator
            dataKey="id"
            rows={10}
            loading={loading}
          >
            <Column
              body={resultServiceTemplate}
              header={t("resultService")}
            ></Column>
            <Column body={methodTemplate} header={t("recordMethod")}></Column>
            <Column
              field="registeredServiceEndpoint"
              header={t("recordEndpoint")}
            ></Column>
            <Column
              body={saveTimeTemplate}
              header={t("recordSaveTime")}
            ></Column>
            <Column body={resultActionsTemplate} header={t("action")}></Column>
          </DataTable>

          <OverlayPanel ref={refObjCode}>
            {singularRecord !== undefined &&
              singularRecord !== null &&
              singularRecord !== "" && (
                <div className="previous-result-table-data">
                  <Button
                    icon="pi pi-clone"
                    tooltip={t("copyToClipboard")}
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                      if (GeneralFunctions.copyToClipboard(singularRecord)) {
                        toast.info(<Trans i18nKey={"infoCopiedToClipboard"} />);
                      } else {
                        toast.error(
                          <Trans i18nKey={"failCopiedToClipboard"} />
                        );
                      }
                    }}
                    id="buttonFontWeight"
                  />
                  <ScrollPanel style={{ width: "100%", height: "250px" }}>
                    <div className="service-request-result-response-data-container">
                      <pre className="service-request-result-response-data">
                        {JSON.stringify(
                          JSON.parse(singularRecord),
                          undefined,
                          2
                        )}
                      </pre>
                    </div>
                  </ScrollPanel>
                </div>
              )}
          </OverlayPanel>
          <OverlayPanel ref={refObjDownload}>
            <div>
              <Button
                className="p-button-rounded p-button-text"
                // icon="pi pi-file"
                icon={<BsFiletypeTxt />}
                tooltip={t("downloadAsTxt")}
                onClick={() => {
                  GeneralFunctions.saveDataAsTextFile(
                    JSON.stringify(JSON.parse(singularRecord), undefined, 2),
                    selectedLcaResult?.registeredService?.name +
                      "_result_" +
                      GeneralFunctions.getDateTemplateV4(Date.now())
                  );
                }}
              />
              <Button
                className="p-button-rounded p-button-text"
                icon={<BsFiletypeJson />}
                tooltip={t("downloadAsJson")}
                onClick={() => {
                  GeneralFunctions.saveDataAsJson(
                    JSON.parse(singularRecord),
                    selectedLcaResult?.registeredService?.name +
                      "_result_" +
                      GeneralFunctions.getDateTemplateV4(Date.now())
                  );
                }}
              />

              <Button
                className="p-button-rounded p-button-text"
                // icon="pi pi-file-pdf"
                icon={<BsFiletypePdf />}
                tooltip={t("downloadAsPdf")}
                onClick={() => {
                  GeneralFunctions.saveDataAsPdfFile(
                    JSON.stringify(JSON.parse(singularRecord), undefined, 2),
                    selectedLcaResult?.registeredService?.name +
                      "_result_" +
                      GeneralFunctions.getDateTemplateV4(Date.now())
                  );
                }}
              />
            </div>
          </OverlayPanel>
        </LcaServiceResutlsContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ProductModelViewContainer>
  );
};

export default withTranslation()(ProductModelView);
