import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
} from "../../functionalities/common/token-check";

export const tutorialData = [
  {
    name: "general",
    visible: true,
    title: "generalTutorialTitle",
    shortDesc: "generalTutorialShortDesc",
    detailedDesc: "generalTutorialDetailedDesc",
    detailedDesc2: "generalTutorialDetailedDesc2",
    detailedDesc3: "generalTutorialDetailedDesc3",

    urlPath: "general-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/leftbar.png",
      "/assets/img/navbar.png",
      "/assets/img/apidocpage.png",
    ],
    videoLink: "",
  }, //navbar, menu, login,logout
  {
    name: "profile",
    visible: true,
    title: "profileTutorialTitle",
    shortDesc: "profileTutorialShortDesc",
    detailedDesc: "profileTutorialDetailedDesc",
    urlPath: "profile-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: ["/assets/img/profilechangepassword.png"],
    videoLink: "",
  },
  {
    name: "productModels",
    // visible: isCTManufacturer(),
    visible: true,
    title: "productModelsTutorialTitle",
    shortDesc: "productModelsShortDesc",
    detailedDesc: "productModelsDetailedDesc",
    urlPath: "product-models-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/productmodelcreation.png",
      "/assets/img/productmodeloptions.png",
      "/assets/img/productmodeldisable.png",
    ],
    videoLink: "",
  },
  {
    name: "services",
    visible: true,
    title: "servicesTutorialTitle",
    shortDesc: "servicesShortDesc",
    detailedDesc: "servicesDetailedDesc",
    urlPath: "services-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/allservicespilot.png",
      "/assets/img/allservicesgretainfo.png",
    ],
    videoLink: "",
  },
  {
    name: "service-page",
    visible: true,
    title: "servicePageTutorialTitle",
    shortDesc: "servicePageShortDesc",
    detailedDesc: "servicePageDetailedDesc",
    detailedDesc2: "servicePageDetailedDesc2",
    detailedDesc3: "servicePageDetailedDesc3",
    urlPath: "service-page-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/servicepagegreta.png",
      "/assets/img/servicepagecreds.png",
      "/assets/img/servicepagetoken.png",
      "/assets/img/servicepageendpointparameters.png",
      "/assets/img/servicepageendpointreqbody.png",
      "/assets/img/servicepageresults.png",
    ],
    videoLink: "",
  },
  {
    name: "product-model-page",
    visible: true,
    title: "productModelPageTutorialTitle",
    shortDesc: "productModelPageShortDesc",
    detailedDesc: "productModelPageDetailedDesc",
    detailedDesc2: "productModelPageDetailedDesc2",

    urlPath: "product-model-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/productmodelpage.png",
      "/assets/img/productmodelpageoptions.png",
    ],
    videoLink: "",
  },
  {
    name: "search",
    visible: true,
    title: "searchTutorialTitle",
    shortDesc: "searchShortDesc",
    detailedDesc: "searchDetailedDesc",
    detailedDesc2: "searchDetailedDesc2",
    detailedDesc3: "searchDetailedDesc3",
    detailedDesc4: "searchDetailedDesc4",

    urlPath: "search-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/searchpage.png",
      "/assets/img/searchpagedetailedsearch.png",
      "/assets/img/searchpagedppsearch.png",
      "/assets/img/searchpageoptions.png",
    ],
    videoLink: "",
  },
  {
    name: "servicesAdmin",
    // visible: isCTAdmin(),
    visible: true,
    title: "servicesAdminTutorialTitle",
    shortDesc: "servicesAdminShortDesc",
    detailedDesc: "servicesAdminDetailedDesc",
    detailedDesc2: "servicesAdminDetailedDesc2",

    urlPath: "services-admin-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/servicesadmincreate.png",
      "/assets/img/servicesadmincreateapi.png",
    ],
    videoLink: "",
  },
  {
    name: "organisationAdmin",
    // visible: isCTAdmin(),
    visible: true,
    title: "organisationAdminTutorialTitle",
    shortDesc: "organisationAdminShortDesc",
    detailedDesc: "organisationAdminDetailedDesc",
    detailedDesc2: "organisationAdminDetailedDesc2",
    urlPath: "organisation-admin-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/organisationroleassign.png",
      "/assets/img/organisationadduser.png",
      "/assets/img/organisationremoveuser.png",
      "/assets/img/organisationkeys.png",
    ],
    videoLink: "",
  },
  {
    name: "providersAdmin",
    // visible: isCTAdmin(),
    visible: true,
    title: "providersAdminTutorialTitle",
    shortDesc: "providersAdminShortDesc",
    detailedDesc: "providersAdminDetailedDesc",
    urlPath: "providers-admin-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: ["/assets/img/providers.png"],
    videoLink: "",
  },
  {
    name: "rolesAdmin",
    // visible: isCTAdmin(),
    visible: true,
    title: "rolesAdminTutorialTitle",
    shortDesc: "rolessAdminShortDesc",
    detailedDesc: "rolesAdminDetailedDesc",
    urlPath: "roles-admin-tutorial",
    thumbnailArray: ["/assets/img/ct-thumbnail.jpg"],
    picturesArray: [
      "/assets/img/rolesadmin.png",
      "/assets/img/rolesadminassignrole.png",
    ],
    videoLink: "",
  },
];
