import { ServiceViewContainer } from "./assets/ServiceViewContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import WaitDialog from "../components/WaitDialog";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { redirect, useParams } from "react-router";
import { getMyRoles, isCTManufacturer } from "../functionalities/common/token-check";
import { Image } from "primereact/image";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import QRCodeGenerator from "../components/QRCodeGenerator";
import ServicesCards from "../components/ServicesCards";
import ProductModelSelect from "../components/ProductModelSelect";
import ServiceEmbed from "../components/ServiceEmbed";

const ServiceView = ({ t }) => {

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [myRoles, setMyRoles] = useState([]);
  const [productModels, setProductModels] = useState([]);
  const [selectedPM, setSelectedPM] = useState({});
  
  const navigate = useNavigate();


  const checkRoles = (data) => {
    let _sRoles = data?.roles.map((role) =>role?.name);
    const isAssigned = _sRoles.some(r => myRoles.includes(r));
    return isAssigned;
  }

  useEffect(()=>{
    setLoading(true);
    setMyRoles(getMyRoles());
    SysService.getRegisteredServiceById(id).then(
      (response) => {
        setLoading(false);
        console.log(response?.data)
        setServiceData(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        toast.error(<Trans i18nKey={errorMessage} />);
        setLoading(false);
      }
    )
  },[]);
  // useEffect(()=>{console.log(selectedPM)},[selectedPM]);

  useEffect(() => {
    if(serviceData !== undefined && serviceData?.roles !== undefined && myRoles !== undefined ){
      if(!checkRoles(serviceData)){ navigate("/allServices") }
    }
  },[serviceData]);
  return (
    <ServiceViewContainer>
      {isCTManufacturer() && (
        <div
          className="pm-data-container"
        >
          <ProductModelSelect productModels={productModels} setProductModels={setProductModels} selectedPM={selectedPM} setSelectedPM={setSelectedPM}/>
        </div>
      )}

      <div
        className="embed-container"
      >
        <ServiceEmbed serviceData={serviceData} selectedPM={selectedPM} rsid={id}/>
      </div>

      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ServiceViewContainer>
  );
};

export default withTranslation()(ServiceView);
