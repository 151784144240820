import styled from "@emotion/styled";
export const ProductModelFieldContainer = styled.div`

    margin-top: 1rem;
// max-height: 100px;
    .selected-pm-data{
        width: 250px;

    }
    .selected-pm-info{
        width: 250px;
        margin-top: 0.25rem;
        .selected-pm-info-data{
        
        }
        .selected-pm-info-data-btn{
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            max-width: 25%;
            background-color: #00a3ff;
            border-radius: 6px;
            border-style: solid;
            border-color: #00a3ff;
            border-width: thin;
            &:hover {
                background-color: #f72685;
                border-radius: 6px;
                border-style: solid;
                border-color: #f72685;
                border-width: thin;
                }
        }
    }
`;