import styled from "@emotion/styled";
export const ErionIconContainer = styled.div`

.erion-logo-container {

    .erion-logo {
        max-width: 15px;
        max-height: 15px;
    }
}

`