import React, { useState, useEffect, lazy, useMemo, useRef } from "react";
import {
  VerifiableCredentialsContainer,
  CreateVerifiableCredentialDialogContainer,
  EditVerifiableCredentialDialogContainer,
  ViewVerifiableCredentialDialogContainer
} from "./assets/VerifiableCredentialsContainer.style"
import { OrganisationAddContainer } from "./assets/OrganisationsContainer.style";

import WaitDialog from "../components/WaitDialog";
// import Swal from "sweetalert2";
import { ToastContainer, toast, Slide } from "react-toastify";
import { withTranslation, Trans } from "react-i18next";
import { InputText } from "primereact/inputtext";
// import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { BlockUI } from "primereact/blockui";
import { Menu } from "primereact/menu";
import { Chip } from "primereact/chip";
// // import countriesWithCode from "../common/countriesWithCode";
import { MultiSelect } from "primereact/multiselect";

// const Table = lazy(() => import("../components/Table"));
import SysService from "../functionalities/services/sys.service";
import { useNavigate } from "react-router-dom";
import { isCTAdmin } from "../functionalities/common/token-check";
import VcService from "../functionalities/services/vc.service";

const VerifiableCredentials = ({ t }) => {
  const emptyVc = {
    name: "",
    didAddress: "",
    email: "",
    vcType: "",
    country: "",
    city: "",
    productTypes: [],
    provider: {}
  };
  const testVcArr = [
    {
      name: "VC1",
      didAddress : "test_address1",
      email: "test@test.com",
      vcType: "",
      country: "UK",
      city: "London",
      productTypes: [],
      provider: {}
    },
    {
      name: "VC2",
      didAddress : "test_address2",
      email: "test@test.com",
      vcType: "",
      country: "Turkey",
      city: "Ankara",
      productTypes: [],
      provider: {}
    },
  ];

  const [user, setUser] = useState("");
  const [vc, setVc] = useState(emptyVc);
  const [selectedVc, setSelectedVc] = useState(emptyVc);
  const [singularVc, setSingularVc] = useState({});
  const [allVc, setAllVc] = useState([]);
  const [addNewVcDialog, setAddNewVcDialog] = useState(false);
  const [editVcDialog, setEditVcDialog] = useState(false);
  const [viewVcDialog, setViewVcDialog] = useState(false);
  const [deleteVcConfirmDialog, setDeleteVcConfirmDialog] = useState(false);

  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);


  const [isProductTypeMSOpen, setIsProductTypeMSOpen] = useState(false);
  /* ---------------------------------------------------------------------------- */
  const menu = useRef();

  const [blockedPanel, setBlockedPanel] = useState(false);

    /* ---------------------------------------------------------------------------- */

  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState({});
  const navigate = useNavigate();


  const vcTypeOptions = [
    { name: t("manufacturer"), code: 1 },
    { name: t("logisticsProvider"), code: 2 },
    { name: t("distributor"), code: 3 },
    { name: t("retailer"), code: 4 },
    { name: t("installer"), code: 5 },
    { name: t("productUser"), code: 6 },
    { name: t("repairer"), code: 7 },
    { name: t("collector"), code: 8 },
    { name: t("collectionPointManager"), code: 9 },
    { name: t("reverseLogisticsProvider"), code: 10 },
    { name: t("recycler"), code: 11 },
    { name: t("reuseProductSeller"), code: 12 },
    { name: t("incinerator"), code: 13 },
    { name: t("landfiller"), code: 14 },
    { name: t("certificationBody"), code: 15 },
  ];

  // const dataTableheader = [
  //   {
  //     name: t("name"),
  //     // sortable: true,
  //     width: 10,
  //   },
  //   {
  //     name: t("email"),
  //     // sortable: true,
  //     width: 10,
  //   },
  //   {
  //     name: t("type"),
  //     width: 18,
  //     // sortable: true,
  //   },
  //   {
  //     name: t("country"),
  //     // sortable: true,
  //     width: 10,
  //   },
  //   {
  //     name: t("city"),
  //     // sortable: true,
  //     width: 10,
  //   },
  //   {
  //     name: t("didAddress"),
  //     // sortable: true,
  //     width: 18,
  //   },
  //   {
  //     name: t("actions"),
  //     width: 6,
  //   },
  //   {
  //     name: "",
  //     width: 6,
  //   },
  // ];

  // const menuItems = [
  //   {
  //     items: [
  //       {
  //         label: t("view"),
  //         icon: (
  //           <img
  //             src="/assets/img/viewIcon.png"
  //             alt=""
  //             height={18}
  //             width={18}
  //             className="delIcon mr-1"
  //             title={t("view")}
  //           />
  //         ),
  //         command: () => {
  //           handleView();
  //         },
  //       },
  //       {
  //         label: t("delete"),
  //         icon: (
  //           <img
  //             src="/assets/img/deleteIcon.png"
  //             alt=""
  //             height={18}
  //             width={18}
  //             className="delIcon mr-1"
  //             title={t("delete")}
  //           />
  //         ),
  //         command: () => {
  //           handleDelete();
  //         },
  //       },
  //     ],
  //   },
  // ];

  // /*-------------------------------------------------------------------------*/

  // const getProviders = () => {
  //   SysService.getProviders().then(
  //     (response) => {
  //       setProviders(response?.data)
  //     },
  //     (error) => {
  //       const errorMessage =
  //       (error.response &&
  //         error.response.data &&
  //         error.response.data.message) ||
  //       error.message ||
  //       error.timeoutErrorMessage ||
  //       error.toString();

  //       setLoading(false);
  //     }
  //   )

  // }

  const submitVcCreation = () => {
    setLoading(true);
    console.log(vc)
    if(
      vc.name === "" ||
      vc.didAddress === "" ||
      vc.email === ""  ||
      // vc.vcType === "" ||
      vc.provider === undefined ||
      vc.productTypes.length === 0
    ){
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(vc.email)) {
      toast.warning(<Trans i18nKey="warnEmailFormat" />);
      setLoading(false);
      return;
    }
    const vCDTO = {
      verifiableCredentialDTO: {
        // id: vc.id,
        name: vc.name,
        didAddress: vc.didAddress,
        email: vc.email,
        // vcType: vc.vcType,
        provider: vc.provider,
        productTypes: vc.productTypes
        //.....
      }
    }
    console.log(vCDTO)
    VcService.addVerifiableCredential(vCDTO).then(
      (response) => {
        if (response.status === 200) {
        
          toast.success(<Trans i18nKey={"successVcCreation"} />);
          VcService.getVerifiableCredentials().then(
            (response) => {
              setAllVc(response?.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response?.data &&
                  error.response?.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();         
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
  
      }
    );
    setLoading(false);
    setAddNewVcDialog(false);
    setVc(emptyVc);
    // setProviderData(emptyProviderData)
    return;
  }

  const submitVcEdit = () => {
    setLoading(true);
    if(
      singularVc.name === "" ||
      singularVc.didAddress === "" ||
      singularVc.email === "" 
      // singularVc.vcType === "" ||
      // singularVc.productTypes.length === 0
    ){
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    const vCDTO = {
      verifiableCredentialDTO: {
        id: singularVc.id,
        name: singularVc.name,
        didAddress: singularVc.didAddress,
        email: singularVc.email,
        vcType: singularVc.vcType,
        status: singularVc.status,
        // productTypes: singularVc.productTypes
        //.....
      }
    }
        // VcService.updateVerifiableCredential(vCDTO).then(
        //   (response) => {
        //     if (response.status === 200) {
            
        //       toast.success(<Trans i18nKey={"successVcUpdate"} />);
        //       VcService.getVerifiableCredentials().then(
        //         (response) => {
        //           console.log(response?.data)
        //           // setAllVc(response?.data);
        //         },
        //         (error) => {
        //           const errorMessage =
        //             (error.response &&
        //               error.response?.data &&
        //               error.response?.data.message) ||
        //             error.message ||
        //             error.timeoutErrorMessage ||
        //             error.toString();
        //         }
        //       );
        //     }
        //   },
        //   (error) => {
        //     const errorMessage =
        //       (error.response &&
        //         error.response?.data &&
        //         error.response?.data.message) ||
        //       error.message ||
        //       error.timeoutErrorMessage ||
        //       error.toString();
        //   }
        // );
        setLoading(false);
        setEditVcDialog(false);
        setSingularVc({})
        // setProviderData(emptyProviderData)
        return;
  }


  const getVcData = (id) => {
    // setLoading(true);

    VcService.getVerifiableCredentialById(id).then(
      (response) => {
        console.log(response?.data)
        setSingularVc(response?.data)
      },
      (error) => {
        const errorMessage =
        (error.response &&
          error.response?.data &&
          error.response?.data.message) ||
        error.message ||
        error.timeoutErrorMessage ||
        error.toString();
      }
    )
  }

  const getAllVc = () => {
    setLoading(true);
    VcService.getVerifiableCredentials().then(
      (response) => {
        console.log(response?.data)
        setAllVc(response.data);
        // setSelectedProductTypes(response?.data?.productTypes?.split(";"));
        // setBlockedPanel(false);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        setLoading(false);
        // setBlockedPanel(false);
        // Swal.fire({
        //   title: t(errorMessage),
        //   icon: "error",
        //   timer: 2000,
        //   showConfirmButton: false,
        // });
      }
    );

    // setAllVc(testVcArr);
    setLoading();
  };

  // const getProductTypes = () => {
  //   setLoading(true);
  //   SysService.getProductTypesByOrganisation().then(
  //     (response) => {
  //       setProductTypeOptions(response.data);
  //       setBlockedPanel(false);
  //       setLoading(false);
  //     },
  //     (error) => {
  //       const errorMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.timeoutErrorMessage ||
  //         error.toString();

  //       setLoading(false);
  //       setBlockedPanel(false);
  //       Swal.fire({
  //         title: t(errorMessage),
  //         icon: "error",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     }
  //   );
  // };

  // const getUserInfo = () => {
  //   setLoading(true);

  //   SysService.getUserInfo().then(
  //     (response) => {
  //       setUser(response.data);
  //       setBlockedPanel(false);
  //       setLoading(false);
  //     },
  //     (error) => {
  //       const errorMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.timeoutErrorMessage ||
  //         error.toString();

  //       setLoading(false);
  //       setBlockedPanel(false);
  //       Swal.fire({
  //         title: t(errorMessage),
  //         icon: "error",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     }
  //   );

  //   window.scrollTo(0, 0);
  // };
  // /*-------------------------------------------------------------------------*/

  // const handleUpdate = () => {
  //   const _vc = {
  //     ...selectedVc,
  //     vcId: selectedVc?.id,
  //   };
  //   setSelectedVc(_vc);
  //   setEditVcDialog(true);
  // };

  // const handleView = () => {
  //   const _vc = {
  //     ...selectedVc,
  //     vcId: selectedVc?.id,
  //   };
  //   setSelectedVc(_vc);
  //   setViewVcDialog(true);
  // };

  // const handleDelete = () => {
  //   setDeleteVcConfirmDialog(true);
  // };

  // const downloadJson = () => {
  //   const blob = new Blob(
  //     [JSON.stringify(JSON.parse(selectedVc?.vcVal), null, 2)],
  //     { type: "application/json" }
  //   );
  //   const url = URL.createObjectURL(blob);

  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "verifiable_credential.json";
  //   document.body.appendChild(a);
  //   a.click();

  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(url);
  // };

  // /*-------------------------------------------------------------------------*/
  // const acceptAddNewVc = () => {
  //   setLoading(true);
  //   if (
  //     vc.name === null ||
  //     vc.name === "" ||
  //     vc.vcType === null ||
  //     vc.vcType === "" ||
  //     vc.country === null ||
  //     vc.country === "" ||
  //     vc.city === null ||
  //     vc.city === "" ||
  //     selectedProductTypes === null ||
  //     selectedProductTypes.length < 1
  //   ) {
  //     setLoading(false);
  //     toast.warning(t("warnMandatory"));
  //     return;
  //   }

  //   const productTypes = selectedProductTypes.join(";");

  //   const genericData = {
  //     ...vc,
  //     organisationId: user?.organisation?.id,
  //     productTypes,
  //   };

  //   SysService.addNewVc(genericData).then(
  //     () => {
  //       setLoading(false);
  //       setAddNewVcDialog(false);
  //       setVc(emptyVc);
  //       Swal.fire({
  //         //   title: t("addFacilityCompleted"),
  //         title: t("addVcCompleted"),
  //         icon: "success",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //       getAllVc();
  //     },
  //     (error) => {
  //       const errorMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.timeoutErrorMessage ||
  //         error.toString();

  //       setLoading(false);
  //       Swal.fire({
  //         title: t(errorMessage),
  //         icon: "error",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     }
  //   );
  // };

  // const acceptUpdateVc = () => {
  //   setLoading(true);
  //   if (
  //     selectedVc.name === null ||
  //     selectedVc.name === "" ||
  //     selectedVc.vcType === null ||
  //     selectedVc.vcType === "" ||
  //     selectedVc.country === null ||
  //     selectedVc.country === "" ||
  //     selectedVc.city === null ||
  //     selectedVc.city === ""
  //   ) {
  //     setLoading(false);
  //     toast.warning(t("warnMandatory"));
  //     return;
  //   }
  //   const genericData = {
  //     ...selectedVc,
  //     organisationId: user?.organisation?.id,
  //   };
  //   SysService.updateVc(genericData).then(
  //     () => {
  //       setLoading(true);
  //       setEditVcDialog(false);
  //       setSelectedVc(emptyVc);
  //       Swal.fire({
  //         //   title: t("updateFacilityCompleted"),
  //         title: t("updateVcCompleted"),
  //         icon: "success",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //       getAllVc();
  //     },
  //     (error) => {
  //       const errorMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.timeoutErrorMessage ||
  //         error.toString();

  //       setLoading(false);
  //       Swal.fire({
  //         title: t(errorMessage),
  //         icon: "error",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     }
  //   );
  // };

  // const acceptDeleteVc = () => {
  //   setLoading(true);

  //   const genericData = {
  //     ...selectedVc,
  //     vcId: selectedVc?.id,
  //     organisationId: user?.organisation?.id,
  //   };

  //   SysService.deleteVc(genericData).then(
  //     () => {
  //       setLoading(false);
  //       Swal.fire({
  //         title: t("deleteVcCompleted"),
  //         icon: "success",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //       getAllVc();
  //     },
  //     (error) => {
  //       const errorMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.timeoutErrorMessage ||
  //         error.toString();

  //       setLoading(false);
  //       Swal.fire({
  //         title: t(errorMessage),
  //         icon: "error",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     }
  //   );
  // };

  /*-------------------------------------------------------------------------*/
  // const hideAddNewVcDialog = () => {
  //   setAddNewVcDialog(false);
  // };
  // const hideUpdateVcDialog = () => {
  //   setEditVcDialog(false);
  // };
  // const hideViewVcDialog = () => {
  //   setViewVcDialog(false);
  // };

  // const addNewVcDialogFooter = (
  //   <React.Fragment>
  //     <Button
  //       label={t("cancel")}
  //       icon="pi pi-times"
  //       className="p-button-text platformColor"
  //       onClick={hideAddNewVcDialog}
  //     />
  //     <Button
  //       label={t("save")}
  //       icon="pi pi-save"
  //       className="p-button-mb"
  //       onClick={acceptAddNewVc}
  //     />
  //   </React.Fragment>
  // );

  // const updateVcDialogFooter = (
  //   <React.Fragment>
  //     <Button
  //       label={t("cancel")}
  //       icon="pi pi-times"
  //       className="p-button-text platformColor"
  //       onClick={hideUpdateVcDialog}
  //     />
  //     <Button
  //       label={t("save")}
  //       icon="pi pi-save"
  //       className="p-button-mb"
  //       onClick={acceptUpdateVc}
  //     />
  //   </React.Fragment>
  // );

  // const viewVcDialogFooter = () => (
  //   <>
  //     <div className="flex align-items-center gap-2">
  //       <Button
  //         label={t("download")}
  //         className="dialog-save-third-button"
  //         onClick={downloadJson}
  //       />
  //       <Button
  //         label={t("close")}
  //         className="dialog-cancel-button"
  //         onClick={hideViewVcDialog}
  //       />
  //     </div>
  //   </>
  // );
  // /*-------------------------------------------------------------------------*/

  // const reject = () => {};

  // const actionsHandler = (vc) => {
  //   return [
  //     <img
  //       src="/assets/img/passport/settings.png"
  //       alt=""
  //       height={20}
  //       width={20}
  //       title={t("settings")}
  //       onClick={(event) => {
  //         event.preventDefault();
  //         menu.current.toggle(event);
  //         setSelectedVc(vc);
  //       }}
  //       aria-controls="popup_menu"
  //       aria-haspopup
  //       className="cursor-pointer"
  //     />,
  //   ];
  // };

  // const handleVcTypeDisplay = (vcType) => {
  //   const match = vcTypeOptions.find((option) => option.code === vcType);
  //   return match ? match.name : undefined;
  // };

  // // const handleVcCountryDisplay = (country) => {
  // //   const match = countriesWithCode.find((option) => option.code === country);
  // //   return match ? match.name : undefined;
  // // };

  // const tableBody = useMemo(() => {
  //   return allVc?.map((vc) => [
  //     vc?.name,
  //     vc?.email,
  //     handleVcTypeDisplay(vc?.vcType),
  //     // handleVcCountryDisplay(vc?.country),
  //     vc?.city,
  //     vc?.didAddress,
  //     actionsHandler(vc),
  //   ]);
  // }, [allVc]);

  // const dialogTitle = (title) => (
  //   <span className="dialog-title">{t(title)}</span>
  // );

  const providerTemplate = (data) => {
    return (<p>{data?.provider?.name}</p>);
  }
    const actionTemplateStatus = (data) => {
      return (
        <Chip
          label={t("STATUS_" + data.status)}
          className="mr-2 mb-2"
          style={{backgroundColor: "#382c7c" ,color:"#fff"}}
        />
      );
    };
    const actionTemplate = (data) => {
     
        return (
          <div>
            <Button
              icon="pi pi-pencil"
              tooltip={t("edit")}
              className="p-button-rounded p-button-text"
              onClick={() => {
                setEditVcDialog(true);
                getVcData(data.id);
              }}
              id="buttonFontWeight"
            />
            <Button
              icon="pi pi-exclamation-circle"
              tooltip={t("inquire")}
              className="p-button-rounded p-button-text"
              onClick={() => {
                setViewVcDialog(true);
                getVcData(data.id);
              }}
              id="buttonFontWeight"
            />
          </div>
        );
    };
    const handleSubmit = (e) => {
      e.preventDefault();
    };
  /*--------------------------------------------------------------------------- */

  // useEffect(() => {
  //   if (user !== "") {
  //     getAllVc();
  //     getProductTypes();
  //   }
  //   window.scrollTo(0, 0);
  // }, [user]);

  // useEffect(() => {
  //   getUserInfo();
  // }, []);

  useEffect(() => {
    if(isCTAdmin()){
      SysService.getProviders().then(
        (response) => {
          setProviders(response?.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response?.data &&
              error.response?.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
        }
      );
      getAllVc();
    }
    else{
      navigate("/landing");
    }
    
  }, []);

  return (
    <VerifiableCredentialsContainer>
      <h2>{t("verifiableCredentialsPage")}</h2>
      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("createAVC")}
          onClick={() => setAddNewVcDialog(true)}
        />
      </OrganisationAddContainer>
      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={allVc}
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noVCsFound")}
              paginator
              rows={10}
              loading={loading}
            >
              <Column field="name" header={t("vCName")}></Column>
              <Column
                body={providerTemplate}
                header={t("vCProviderName")}
              ></Column>
              <Column field="didAddress" header={t("vCDidAddress")}></Column>

              <Column body={actionTemplateStatus} header={t("status")}></Column>
              <Column
                header={t("action")}
                style={{ textAlign: "center", width: "8em" }}
                body={actionTemplate}
              />
            </DataTable>
          </div>
        </div>
      </BlockUI>
      <Dialog
        visible={addNewVcDialog}
        modal
        header={t("createAVC")}
        className="p-fluid dialog-size"
        style={isProductTypeMSOpen ? { height: "700px" } : { height: "550px" }}
        onHide={() => {
          setAddNewVcDialog(false);
          setVc(emptyVc);
        }}
      >
        <CreateVerifiableCredentialDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div>
            <div className="p-float-label input-container">
              <InputText
                id="vCName"
                type="text"
                className="p-inputtext-sm w-full"
                value={vc?.name}
                maxLength={100}
                onChange={(e) =>
                  setVc({
                    ...vc,
                    name: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="vCName">
                {t("pleaseEnterVcName")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="vCDidAddress"
                type="text"
                className="p-inputtext-sm w-full"
                value={vc?.didAddress}
                maxLength={500}
                onChange={(e) =>
                  setVc({
                    ...vc,
                    didAddress: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="vCDidAddress">
                {t("pleaseEnterVcDidAddress")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="vCEmail"
                type="text"
                className="p-inputtext-sm w-full"
                value={vc?.email}
                maxLength={100}
                onChange={(e) =>
                  setVc({
                    ...vc,
                    email: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="vCEmail">
                {t("pleaseEnterVcEmail")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <Dropdown
                id="vCProvider"
                options={providers}
                value={vc?.provider}
                onChange={(e) =>
                  setVc({
                    ...vc,
                    provider: e.target.value,
                  })
                }
                optionLabel="name"
                // optionValue="code"
              />
              <label className="platformColor" htmlFor="vCProvider">
                {t("pleaseEnterVcProvider")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <MultiSelect
                id="vCProductTypes"
                options={vcTypeOptions}
                value={vc?.productTypes}
                onChange={(e) =>
                  setVc({
                    ...vc,
                    productTypes: e.target.value,
                  })
                }
                onShow={() => {
                  setIsProductTypeMSOpen(true);
                }}
                onHide={() => {
                  setIsProductTypeMSOpen(false);
                }}
                display="chip"
                maxSelectedLabels={5}
                appendTo="self"
                optionLabel="name"
                // optionValue="name"
              />
              <label className="platformColor" htmlFor="vCProductTypes">
                {t("pleaseEnterVcProductTypes")} (*)
              </label>
            </div>
          </div>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitVcCreation}
          />
        </CreateVerifiableCredentialDialogContainer>
      </Dialog>
      <Dialog
        visible={editVcDialog}
        modal
        header={t("vCInfo")}
        className="p-fluid dialog-size"
        style={isProductTypeMSOpen ? { height: "550px" } : { height: "480px" }}
        onHide={() => {
          setEditVcDialog(false);
          setSingularVc({});
        }}
      >
        <EditVerifiableCredentialDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div>
            <div className="p-float-label input-container">
              <InputText
                id="vCName"
                type="text"
                className="p-inputtext-sm w-full"
                value={singularVc?.name}
                maxLength={100}
                onChange={(e) =>
                  setSingularVc({
                    ...singularVc,
                    name: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="vCName">
                {t("vCName")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="vCDidAddress"
                type="text"
                className="p-inputtext-sm w-full"
                value={singularVc?.didAddress}
                maxLength={500}
                onChange={(e) =>
                  setSingularVc({
                    ...singularVc,
                    didAddress: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="vCDidAddress">
                {t("vCDidAddress")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="vCEmail"
                type="text"
                className="p-inputtext-sm w-full"
                value={singularVc?.email}
                maxLength={100}
                onChange={(e) =>
                  setSingularVc({
                    ...singularVc,
                    email: e.target.value,
                  })
                }
              />
              <label className="platformColor" htmlFor="vCEmail">
                {t("vCEmail")} (*)
              </label>
            </div>
            <div className="p-float-label input-container">
              <Dropdown
                id="vCProvider"
                options={providers}
                value={singularVc?.provider}
                onChange={(e) =>
                  setSingularVc({
                    ...singularVc,
                    provider: e.target.value,
                  })
                }
                onShow={() => {setIsProductTypeMSOpen(true);}}
                onHide={() => {setIsProductTypeMSOpen(false);}}
                optionLabel="name"
                // optionValue="code"
              />
              <label className="platformColor" htmlFor="vCProvider">
                {t("vCProviderName")} (*)
              </label>
            </div>
            {/* <div className="p-float-label input-container">
              <MultiSelect
                id="vCProductTypes"
                options={vcTypeOptions}
                value={singularVc?.productTypes}
                onChange={(e) =>
                  setVc({
                    ...vc,
                    productTypes: e.target.value,
                  })
                }
                onShow={() => {
                  setIsProductTypeMSOpen(true);
                }}
                onHide={() => {
                  setIsProductTypeMSOpen(false);
                }}
                display="chip"
                maxSelectedLabels={5}
                appendTo="self"
                optionLabel="name"
                // optionValue="name"
              />
              <label className="platformColor" htmlFor="vCProductTypes">
                {t("pleaseEnterVcProductTypes")} (*)
              </label>
            </div> */}
          </div>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitVcEdit}
          />
        </EditVerifiableCredentialDialogContainer>
      </Dialog>
      <Dialog
        visible={viewVcDialog}
        modal
        header={t("vCInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewVcDialog(false);
          setVc({});
        }}
      >
        <ViewVerifiableCredentialDialogContainer>
        <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div>
            <div className="p-float-label input-container">
              <InputText
                id="vCName"
                type="text"
                className="p-inputtext-sm w-full"
                value={singularVc?.name}
                disabled
              />
              <label className="platformColor" htmlFor="vCName">
                {t("vCName")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="vCDidAddress"
                type="text"
                className="p-inputtext-sm w-full"
                value={singularVc?.didAddress}
                disabled
              />
              <label className="platformColor" htmlFor="vCDidAddress">
                {t("vCDidAddress")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="vCEmail"
                type="text"
                className="p-inputtext-sm w-full"
                value={singularVc?.email}
                disabled
              />
              <label className="platformColor" htmlFor="vCEmail">
                {t("vCEmail")}
              </label>
            </div>
            <div className="p-float-label input-container">
              <InputText
                id="vCProviderName"
                type="text"
                className="p-inputtext-sm w-full"
                value={singularVc?.provider?.name}
                disabled
              />
              <label className="platformColor" htmlFor="vCProviderName">
                {t("vCProviderName")}
              </label>
            </div>
            {/* <div className="p-float-label input-container">
              <MultiSelect
                id="vCProductTypes"
                options={vcTypeOptions}
                value={singularVc?.productTypes}
                onChange={(e) =>
                  setVc({
                    ...vc,
                    productTypes: e.target.value,
                  })
                }
                onShow={() => {
                  setIsProductTypeMSOpen(true);
                }}
                onHide={() => {
                  setIsProductTypeMSOpen(false);
                }}
                display="chip"
                maxSelectedLabels={5}
                appendTo="self"
                optionLabel="name"
                // optionValue="name"
              />
              <label className="platformColor" htmlFor="vCProductTypes">
                {t("pleaseEnterVcProductTypes")} (*)
              </label>
            </div> */}
          </div>
        </ViewVerifiableCredentialDialogContainer>
      </Dialog>
      {/* <>
        <BlockUI blocked={blockedPanel}>
          <ProductModelsContainer>
            <ProductModelHeaderSection>
              <div className="left-section">
                <span className="title">{t("VerifiableCredentials")}</span>
                <div className="flex align-items-center justify-content-start gap-2">
                  <Button
                    className="add-new-dpp"
                    // label={t("addNewFacility")}
                    label={t("addNewVc")}
                    onClick={() => {
                      setVc(emptyVc);
                      setSelectedProductTypes([]);
                      setAddNewVcDialog(true);
                    }}
                  />
                </div>
              </div>
            </ProductModelHeaderSection>
            <div className="mt-5">
              <Table
                head={dataTableheader}
                headerBackgroundColor={"2d416e"}
                pageButtonColor={"2d416e"}
                pageArrowColor={"2d416e"}
                hoverColor={"f7fafc"}
                // searchable={true}
                // emptyDataTableText="noFacilitiesFound"
                emptyDataTableText="noVcFound"
                body={tableBody}
                paginator={true}
              />
            </div>

            <Menu model={menuItems} popup ref={menu} id="popup_menu" />
          </ProductModelsContainer>
        </BlockUI>

        <Dialog
          visible={addNewVcDialog}
          style={{ width: "850px" }}
          header={dialogTitle("addNewVc")}
          modal
          className="p-fluid"
          footer={addNewVcDialogFooter}
          onHide={hideAddNewVcDialog}
        >
          <ProductModelDialog>
            <div className="flex flex-column gap-3 mt-3">
              <div className="p-float-label">
                <InputText
                  id="name"
                  value={vc.name}
                  onChange={(e) => setVc({ ...vc, name: e.target.value })}
                  maxLength={80}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="name"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("name")} (*)
                </label>
              </div>
              <div className="p-float-label">
                <InputText
                  id="didAddress"
                  value={vc.didAddress}
                  onChange={(e) => setVc({ ...vc, didAddress: e.target.value })}
                  //   maxLength={13}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="didAddress"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("didAddress")} (*)
                </label>
              </div>
              <div className="p-float-label">
                <InputText
                  id="email"
                  value={vc.email}
                  onChange={(e) => setVc({ ...vc, email: e.target.value })}
                  //   maxLength={13}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="email"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("email")} (*)
                </label>
              </div>

              <div className="p-float-label">
                <MultiSelect
                  value={selectedProductTypes}
                  options={productTypeOptions}
                  onChange={(e) => setSelectedProductTypes(e.value)}
                  filter
                  appendTo="self"
                />

                <label
                  htmlFor="productTypes"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("productTypes")} (*)
                </label>
              </div>

              <div className="p-float-label mb-2">
                <Dropdown
                  value={vc.vcType}
                  options={vcTypeOptions}
                  onChange={(e) => setVc({ ...vc, vcType: e.target.value })}
                  optionLabel="name"
                  optionValue="code"
                  placeholder={t("selectType")}
                />
                <label
                  htmlFor="vcType"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("vcType")} (*)
                </label>
              </div>
              <div className="p-float-label mb-2">
                <Dropdown
                  value={vc.country}
                  options={countriesWithCode}
                  onChange={(e) => setVc({ ...vc, country: e.target.value })}
                  optionLabel="name"
                  optionValue="code"
                  placeholder={t("selectCountry")}
                />
                <label
                  htmlFor="country"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("country")} (*)
                </label>
              </div>
              <div className="p-float-label">
                <InputText
                  id="city"
                  value={vc.city}
                  onChange={(e) => setVc({ ...vc, city: e.target.value })}
                  maxLength={80}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="city"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("city")} (*)
                </label>
              </div>
            </div>
          </ProductModelDialog>
        </Dialog>

        <Dialog
          visible={editVcDialog}
          style={{ width: "650px" }}
          header={dialogTitle("updateVc")}
          modal
          className="p-fluid"
          footer={updateVcDialogFooter}
          onHide={hideUpdateVcDialog}
        >
          <ProductModelDialog>
            <div className="flex flex-column gap-3">
              <div className="p-float-label">
                <InputText
                  id="name"
                  value={selectedVc.name}
                  onChange={(e) =>
                    setSelectedVc({ ...selectedVc, name: e.target.value })
                  }
                  maxLength={80}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="name"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("name")} (*)
                </label>
              </div>
              <div className="p-float-label">
                <InputText
                  id="didAddress"
                  value={selectedVc.didAddress}
                  onChange={(e) =>
                    setSelectedVc({ ...selectedVc, didAddress: e.target.value })
                  }
                  //   maxLength={13}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="didAddress"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("didAddress")} (*)
                </label>
              </div>
              <div className="p-float-label">
                <InputText
                  id="email"
                  value={selectedVc.email}
                  onChange={(e) =>
                    setSelectedVc({ ...selectedVc, email: e.target.value })
                  }
                  //   maxLength={13}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="email"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("email")} (*)
                </label>
              </div>

              <div className="p-float-label">
                <MultiSelect
                  value={selectedProductTypes}
                  options={productTypeOptions}
                  onChange={(e) => setSelectedProductTypes(e.value)}
                  optionLabel="name"
                  optionValue="id"
                  filter
                  appendTo="self"
                />
                <label
                  htmlFor="productTypes"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("productTypes")} (*)
                </label>
              </div>

              <div className="p-float-label mb-2">
                <Dropdown
                  value={selectedVc.vcType}
                  options={vcTypeOptions}
                  onChange={(e) =>
                    setSelectedVc({ ...selectedVc, vcType: e.target.value })
                  }
                  optionLabel="name"
                  optionValue="code"
                  placeholder={t("selectType")}
                />
                <label
                  htmlFor="vcType"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("vcType")} (*)
                </label>
              </div>
              <div className="p-float-label mb-2">
                <Dropdown
                  value={selectedVc.country}
                  options={countriesWithCode}
                  onChange={(e) =>
                    setSelectedVc({ ...selectedVc, country: e.target.value })
                  }
                  optionLabel="name"
                  optionValue="code"
                  placeholder={t("selectCountry")}
                />
                <label
                  htmlFor="country"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("country")} (*)
                </label>
              </div>
              <div className="p-float-label">
                <InputText
                  id="city"
                  value={selectedVc.city}
                  onChange={(e) =>
                    setSelectedVc({ ...selectedVc, city: e.target.value })
                  }
                  maxLength={80}
                  className="p-inputtext block mb-2"
                />
                <label
                  htmlFor="city"
                  className="platformColor"
                  style={{ fontSize: "14px" }}
                >
                  {t("city")} (*)
                </label>
              </div>
            </div>
          </ProductModelDialog>
        </Dialog>

        <Dialog
          visible={viewVcDialog}
          header={dialogTitle("VerifiableCredentials")}
          modal
          className="p-fluid dialog-size-second"
          footer={viewVcDialogFooter}
          onHide={hideViewVcDialog}
        >
          <ProductModelDialog>
            {selectedVc !== undefined && selectedVc !== null && (
              <pre
                style={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {(() => {
                  let vcVal = selectedVc?.vcVal;

                  try {
                    vcVal = JSON.parse(vcVal);
                  } catch (e) {}

                  return JSON.stringify(vcVal, null, 2);
                })()}
              </pre>
            )}
          </ProductModelDialog>
        </Dialog>

        <WaitDialog loading={loading} lang={t} />
        <ConfirmDialog
          visible={deleteVcConfirmDialog}
          onHide={() => setDeleteVcConfirmDialog(false)}
          message={t("confirmationDeleteVc")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle icon-red"
          accept={acceptDeleteVc}
          reject={reject}
          className="delete"
          acceptLabel={t("delete")}
          acceptClassName="p-button-danger"
          rejectLabel={t("no")}
        />
        <ToastContainer
          autoClose={2200}
          position={toast.POSITION.TOP_CENTER}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          theme="colored"
          pauseOnFocusLoss={true}
          draggable={false}
          pauseOnHover={true}
        />
      </> */}
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </VerifiableCredentialsContainer>
  );
};

export default withTranslation()(VerifiableCredentials);
