import styled from "@emotion/styled";
export const ApiEndpointInputContainer = styled.div`
    // width: fit-content;
    display:flex; 
    flex-direction:row;

    .endpoint-item {

        display: flex;
        flex-flow: row;
        justify-content: center;
    }
`