import { TOKEN_USER } from "../common/token-check";


export default function headerERION(token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      'accept-encoding': 'gzip, deflate'

    };
    if(token){
      return { Authorization: "Bearer " + token };
    } else{ return headers}
  }