import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { TutorialPageContainer } from "./assets/TutorialPageContainer.style";
import { useNavigate } from "react-router-dom";
import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
} from "../functionalities/common/token-check";
import { redirect, useParams } from "react-router";
import { tutorialData } from "../data/tutorial/tutorial-data";
import ReactPlayer from "react-player";
import ImageGallery from "react-image-gallery";

const TutorialPage = ({ t }) => {
  const { name } = useParams();
  const navigate = useNavigate();
  const [tData, setTData] = useState({});
  const [tPictures, setTPictures] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleScreenChange = (isFullscreen) => {
    setIsFullscreen(isFullscreen);
  };

  useEffect(() => {
    //blocked for demo
    // if (name.includes("admin")) {
    //   if (!isCTAdmin()) {
    //     navigate("/tutorials");
    //   }
    // }
    // if (name.includes("productModels")||name.includes("product-models")||name.includes("product-models-tutorial")) {
    //   if (!isCTManufacturer()) {
    //     navigate("/tutorials");
    //   }
    // }
    for (let i = 0; i < tutorialData.length; i++) {
      if (tutorialData[i]?.urlPath === name) {
        let galleryImgs = [];
        setTData(tutorialData[i]);
        for(let y=0;y<tutorialData[i]?.picturesArray.length; y++){
          galleryImgs.push({ original: tutorialData[i]?.picturesArray[y] });
        }
        setTPictures(galleryImgs);
        return;
      }
    }
  }, []);

  return (
    <TutorialPageContainer>
      <h3 className="tutorial-data-title">{t(tData?.title)}</h3>
      <div className="tutorial-data-shortD">{t(tData?.shortDesc)}</div>


      <div className="image-description">

      </div>
      {tPictures?.length > 0 && (
        //   <div className={isFullscreen ? "gallery-w-fullscreen" : "gallery"}>
        <div>
            <ImageGallery
              additionalClass="image-gallery"
              items={tPictures}
              showThumbnails={false}
              showPlayButton={false}
              showBullets={false}
              useBrowserFullscreen={true}
              showFullscreenButton={true}
              onScreenChange={handleScreenChange}
            />
          </div>
        )}

      <div className="tutorial-data-detailedD">{t(tData?.detailedDesc)}</div>
      <div className="tutorial-data-detailedD">{t(tData?.detailedDesc2)}</div>
      <div className="tutorial-data-detailedD">{t(tData?.detailedDesc3)}</div>    

      {tData?.videoLink !== undefined &&
        tData?.videoLink !== null &&
        tData?.videoLink !== "" && (
          <div className="tutorial-data-video-player-container">
            {/* <ReactPlayer url={}>
                    </ReactPlayer> */}
            <video className="tutorial-data-video-player" controls>
              <source src={tData.videoLink} type="video/mp4" />
            </video>
          </div>
        )}
    </TutorialPageContainer>
  );
};

export default withTranslation()(TutorialPage);
