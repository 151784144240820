import React, { useEffect } from 'react';
import Prism from 'prismjs';
import "prismjs/themes/prism-tomorrow.css";
// import "prismjs/themes/prism-dark.css"; // güzel
// import "prismjs/themes/prism-okaidia.css" // güzel
// import "prismjs/themes/prism-twilight.css"; // güzel ama fazla karanlık


import "prismjs/components/prism-python"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-rust"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-java"
import "prismjs/components/prism-go"


const PrismCodeBlock = ({ code, language }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [code, language]);

  return (
    <pre  style={{resize:"none",maxWidth:"365px", maxHeight:"400px"}}>
      <code className={`language-${language}`}>
        {code}
      </code>
    </pre>
  );
};

export default PrismCodeBlock;