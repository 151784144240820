/* 
if endpoint is /api_documentation change (could be developer.ct or api.ct)
the leftbar and adjust contents accordingly

https://developer.revolut.com/docs/business/business-api
*/

import React, { useEffect, useState } from "react";
import { ApiDocContainer } from "./assets/ApiDocContainer.style";
import { withTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { Chip } from "primereact/chip";
import config from "../config";
import { apiTextData } from "../data/ct-data";
import APIService from "../functionalities/services/api.service";
import ApiPageContent from "../components/ApiPageContent";
import ApiPageRequests from "../components/ApiPageRequests";

const CodeDocumentation = ({ t }) => {
  const [apiJson, setApiJson] = useState({});
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [dtos, setDtos] = useState({});
  const [parameters, setParameters] = useState({});
  const [paths, setPaths] = useState({});
  const [apiMenuItems, setApiMenuItems] = useState({});

  const [authOpen, setAuthOpen] = useState(false);
  const [pmOpen, setPmOpen] = useState(false);
  const [rsOpen, setRsOpen] = useState(false);
  const [contentPick, setContentPick] = useState("");
  const [requestPick, setRequestPick] = useState(null);

  const handlePath = (path) => {
    if (path.includes("/auth/")) {
    } else if (path.includes("/ProductModel/")) {
    } else if (path.includes("/RegisteredService/")) {
    }
  };

  const handleParameter = (pathPick) => {
    if (paths != null || paths !== undefined) {
      const _paths = Object.keys(paths);
      for (let i = 0; i < _paths.length; i++) {
        if(_paths[i].includes(pathPick)){
          const _mk = Object.keys(apiJson?.paths[_paths[i]]);
          if(apiJson?.paths[_paths[i]][_mk]?.parameters !== undefined){
            return apiJson?.paths[_paths[i]][_mk]?.parameters;
          }else{
            return null;
          }
        }
      }
    }
  }
  const handleRequestBody =  (pathPick) => {
    if (dtos != null || dtos !== undefined) {
      if(pathPick.includes("login")){
        return dtos?.LoginDTO;
      }else if(pathPick.includes("Password")){
        return dtos?.PasswordDTO;
      }else if(pathPick.includes("addProductModel") || pathPick.includes("updateProductModel")){
        return dtos?.ProductModelDTO;
      }else{
        return null;
      }
    }
  }

  const handlePathMethod = (path, type) => {
    // console.log(path)
    if ("get" in path) {
      return (
        <div style={{ fontSize: "11px" }}>
          <Chip
            label={"GET"}
            className="method-chip"
            style={{
              backgroundColor: "#61affe",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
              fontSize:"11px"
            }}
          />
          {type +"/"+ path["get"]?.operationId}
        </div>
      );
    } else if ("post" in path) {
      return (
        <div style={{ fontSize: "11px" }}>
          <Chip
            label={"POST"}
            className="method-chip"
            style={{
              backgroundColor: "#49cc90",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
              fontSize:"11px"
            }}
          />
          {type +"/"+ path["post"]?.operationId}
        </div>
      );
    } else if ("put" in path) {
      return (
        <div style={{ fontSize: "11px" }}>
          <Chip
            label={"PUT"}
            className="method-chip"
            style={{
              backgroundColor: "#fca130",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
              fontSize:"11px"
            }}
          />
          {type +"/"+ path["put"]?.operationId}
        </div>
      );
    } else if ("delete" in path) {
      return (
        <div style={{ fontSize: "11px" }}>
          <Chip
            label={"DELETE"}
            className="method-chip"
            style={{
              backgroundColor: "#f93e3e",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
              fontSize:"11px"
            }}
          />
          {type +"/"+ path["delete"]?.operationId}
        </div>
      );
    }
  };

  useEffect(() => {
    // process.env kullanmak yerine
    // setApiJson(getAPIEndPoints(window.location.origin))
    //setApiJson(APIService.getAPIEndPoints(config.backendApiUrl))
    APIService.getAPIEndPoints(config.backendApiUrl).then(
      (response) => {
        // console.log(response.data)
        setApiJson(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
      }
    );
  }, []);
  useEffect(() => {
    setDtos(apiJson?.components?.schemas);
    // console.log(apiJson?.components?.schemas)
    setPaths(apiJson?.paths);
  }, [apiJson]);
  useEffect(() => {
    if (paths != null || paths !== undefined) {
      const _paths = Object.keys(paths);
      // console.log(paths)
      // console.log(_paths);
      let _menu = {};
      let _menuAuth = [];
      let _menuProductModel = [];
      let _menuRegisteredServices = [];
      // _menuAuth.push(<div>{t("Information")}</div>);
      // _menuProductModel.push(<div>{t("Information")}</div>);
      // _menuRegisteredServices.push(<div>{t("Information")}</div>);
      for (let i = 0; i < _paths.length; i++) {
        if (_paths[i].includes("api/auth")) {
          // _menuAuth.push(_paths[i]);
          _menuAuth.push(handlePathMethod(paths[_paths[i]],"auth"));
        } else if (_paths[i].includes("ProductModel")) {
          // _menuProductModel.push(_paths[i]);
          // console.log(handlePathMethod(paths[_paths[i]]))
          if(_paths[i].includes("/pub/")){ _menuProductModel.push(handlePathMethod(paths[_paths[i]],"pub")); }
          else if(_paths[i].includes("/sys/")){ _menuProductModel.push(handlePathMethod(paths[_paths[i]],"sys")); }
        } else if (_paths[i].includes("RegisteredService")) {
          // _menuRegisteredServices.push(_paths[i]);
          if(_paths[i].includes("/pub/")){ _menuRegisteredServices.push(handlePathMethod(paths[_paths[i]],"pub")); }
          else if(_paths[i].includes("/sys/")){ _menuRegisteredServices.push(handlePathMethod(paths[_paths[i]],"sys")); }
        }
      }
      _menu["auth"] = _menuAuth;
      _menu["productModel"] = _menuProductModel;
      _menu["registeredService"] = _menuRegisteredServices;

      setApiMenuItems(_menu);
    }
  }, [paths]);
  // console.log(apiMenuItems);
  return (
    <ApiDocContainer>
      <h2>{t("APIDocumentationPage")}</h2>

      <div className="api-doc-main-container">
        <div className="api-doc-left-menu-container">
          {/* {apiMenuItems.length>0 ? (
              apiMenuItems.map((i)=> (
                <div className='api-doc-left-menu-item'>{i}</div>
              ))

            ) : (
              <div>{t("noPathsAvailable")}</div>
            )} */}
          {/* {Object.keys(apiMenuItems).length > 0 ? (
            // <div>{apiMenuItems.auth}</div>
            Object.keys(apiMenuItems).map((menuItem)=>{
              <div>{apiMenuItems.auth}</div>
            })
          ) : (
            <div>{t("noPathsAvailable")}</div>
          )} */}
          {apiMenuItems.auth !== undefined &&
            <Button
              className="api-doc-left-menu-opener-btn"
              id="auth-btn"
              label={t("authorization")}
              icon = {authOpen ? "pi pi-chevron-down" : "pi pi-chevron-up"}
              onClick={() => {setAuthOpen(!authOpen);}}
            />
          }
          {authOpen ? (
            <div id="auth-div" className='api-doc-left-menu-item'>
            {/* {apiMenuItems.auth} */}

            {apiMenuItems.auth !== undefined && apiMenuItems.auth.map((i)=>(
              <Button
                className="api-doc-left-menu-item-btn"
                label={i}
                name={i}
                onClick={ () => {
                  // console.log(i.props.children[1])
                  setContentPick(i.props.children[1]);
                  setRequestPick(i.props.children[0]);
                }}
              />  
            ))}

          </div>
          ) : (<br></br>)}
          
          {apiMenuItems.productModel !== undefined &&
            <Button
              className="api-doc-left-menu-opener-btn"
              label={t("productModels")}
              icon = {pmOpen ? "pi pi-chevron-down" : "pi pi-chevron-up"}
              onClick={() => {setPmOpen(!pmOpen);}}
            />
          }
          {pmOpen ? (
          <div className='api-doc-left-menu-item'>
            {/* {apiMenuItems.productModel} */}
            {apiMenuItems.productModel !== undefined && apiMenuItems.productModel.map((i)=>(
              <Button
                className="api-doc-left-menu-item-btn"
                label={i}
                name={i}
                onClick={() => {
                  // console.log(i.props.children[1]);
                  setContentPick(i.props.children[1]);
                  setRequestPick(i.props.children[0]);
                }}
              />  
            ))}
          </div>
          ): (<br></br>)}

          {apiMenuItems.registeredService !== undefined &&
            <Button
              className="api-doc-left-menu-opener-btn"
              label={t("registeredService")}
              icon = {rsOpen ? "pi pi-chevron-down" : "pi pi-chevron-up"}
              onClick={() => {setRsOpen(!rsOpen);}}
            />
          }
          {rsOpen && (
          <div className='api-doc-left-menu-item'>
            {/* {apiMenuItems.registeredService} */}
            {apiMenuItems.registeredService !== undefined && apiMenuItems.registeredService.map((i)=>(
              <Button
                className="api-doc-left-menu-item-btn"
                label={i}
                name={i}
                onClick={() => {
                  // console.log(i.props.children[1]);
                  setContentPick(i.props.children[1]);
                  setRequestPick(i.props.children[0]);
                }}
              /> 
            ))}
          </div>
          )}
         
        </div>
        <div className="api-doc-content-container">
          <ApiPageContent menuData={contentPick} parameterData={handleParameter(contentPick)} requestBodyData={handleRequestBody(contentPick)}></ApiPageContent>
        </div>
        <div className='api-doc-requests-container'>
          <ApiPageRequests requestData={contentPick} requestPick={requestPick}></ApiPageRequests>

        </div>
      </div>

      {/* <div className='header-container'>
        </div>

        <div className='description'>
        </div> */}
      <div className="swagger-info-text-container">{t("swaggerInfoText")}</div>
      <NavLink
        to={config.backendApiUrl + "/api/swagger-ui/index.html#/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button label={t("goToSwaggerPage")} className="toSwaggerBtn" />
      </NavLink>
    </ApiDocContainer>
  );
};

export default withTranslation()(CodeDocumentation);
