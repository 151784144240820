import styled from "@emotion/styled";
export const ServiceViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    padding: 0 1rem 0 1rem;
    max-width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #fff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    flex-shrink: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    // color: #00a3ff;
    align-items: center;
    text-align:center;

    .pm-data-container {
        position: left;
        max-width: 350px;
        max-heigth: 350px;
        ::-webkit-scrollbar {
        width: 5px;
        }

        ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 5px;
        }

        ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        }
        // .dialog-size {
        //   width: 50%;
        // }
            }
   
`;

