import React from "react";
import { ErionIconContainer } from "./assets/ErionIconContainer.style";

const ErionIcon = () => {
    return (
        <ErionIconContainer>

            <div className="erion-logo-container">
                <img  src="/assets/img/erion1.png" className="erion-logo" alt="erionlogo"/>
            </div>
        </ErionIconContainer>


    )
}

export default ErionIcon