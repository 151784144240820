import React, { useEffect, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { TabView, TabPanel } from "primereact/tabview";
import { ApiRequestsDetailsContainer } from "./assets/ApiRequestDetailsContainer.style";
import { InputText } from "primereact/inputtext";
import { Panel } from 'primereact/panel';
import { Dropdown } from "primereact/dropdown";
import { methodTypes } from "../data/ct-data";
import { ToastContainer, toast, Slide } from "react-toastify";
import WaitDialog from "./WaitDialog";
import APIService from "../functionalities/services/api.service";

const ApiRequestDetails = ({
  t,
  type,
  parameters,
  setActiveRequestParameters,
  setActiveRequestBody,
  endpoint,
  loading,
  setLoading,
}) => {
  const [activeIndex, setActiveIndex] = useState(parameters.length > 0 ? 0 : 1);
  const [reqBody, setReqBody] = useState("");
  const [reqParams, setReqParams] = useState({})
  const [customReqBody, setCustomReqBody] = useState("")
  const [reqBodyCheck, setReqBodyCheck] = useState(false);
  const [customMethod, setCustomMethod] = useState({});
  const [customReqEndpoint, setCustomReqEndpoint] = useState("");
  const [customIsDropdownOpen, setCustomIsDropdownOpen] = useState(false);

  const handleCustomApiRequestDetails = () => {
    console.log(customReqBody);
    console.log(endpoint+customReqEndpoint)
    
    if (
      Object.keys(customReqBody).length > 0 &&
      customReqBody !== undefined &&
      customReqBody !== null &&
      customReqBody !== ""
    ) {
      try {
        console.log(JSON.parse(customReqBody));
        // setActiveRequestBody(JSON.parse(activeRequestBody))

        return;
      } catch (error) {
        toast.error(<Trans i18nKey={"errorRequestBodyFaulty"} />);
        return;
      }
    }
    let customEndpoint = endpoint+customReqEndpoint; 
    if(!customReqEndpoint.startsWith('/')){
      customEndpoint = endpoint +"/"+customReqEndpoint; 
    } 

    // APIService.serviceApiRequest
  };

  return (
    <ApiRequestsDetailsContainer
      customApiRequestStyleDropdownMinHeight={customIsDropdownOpen && "200px"}
    >
      {type === "registered" ? (
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          {parameters.length > 0 && (
            <TabPanel
              header={t("setParameters")}
              leftIcon="pi pi-list"
              className="w-2rem h-2rem p-0 api-request-details-panel"
            >
              {parameters.length > 0 &&
                parameters.map((p, i) => (
                  <div>
                    <InputText disabled value={p} />
                    <InputText
                      onChange={(e) => {
                        reqParams[parameters[i]] = e.target.value;
                        setActiveRequestParameters(reqParams);
                      }}
                    />
                  </div>
                ))}
            </TabPanel>
          )}

          <TabPanel
            header={t("setRequestBody")}
            leftIcon="pi pi-external-link"
            className="w-2rem h-2rem p-0 api-request-details-panel"
          >
            <InputTextarea
              value={reqBody}
              placeholder={t("reqBodyPlaceholder")}
              style={{ resize: "none", minWidth: "600px", minHeight: "400px" }}
              // onChange={(e) => setReqBody(e.target.value)}
              onChange={(e) => {
                setReqBody(e.target.value);
                setActiveRequestBody(e.target.value);
              }}
            ></InputTextarea>
          </TabPanel>
        </TabView>
      ) : (
        <div>
          <div className="api-endpoint-item-endpoint-container">
          <Dropdown
            className="api-request-custom-request-method-select"
            options={methodTypes}
            style={{width:"125px",color: "#000", backgroundColor: customMethod?.code === 1 ? "#61affe" : customMethod?.code === 2 ? "#49cc90" : customMethod?.code === 3 ? "#fca434" : customMethod?.code === 4 ? "#f93e3e": "#fff"}}
            optionLabel="name"
            // optionValue="code"
            value={customMethod}
            appendTo="self"
            onChange={(e)=>{
              console.log(e.target.value)
              setCustomMethod(e.target.value)
            }}
            onShow={()=>{setCustomIsDropdownOpen(true);}}
            onHide={()=>{setCustomIsDropdownOpen(false);}}
          /> 
           <InputText
            style={{ width: "270px" }}
            className="api-endpoint-item-endpoint"
            value={endpoint}
            disabled
          />
          <InputText
            className="api-endpoint-item-endpoint-rest"
            value={customReqEndpoint}
            onChange={(e)=>{setCustomReqEndpoint(e.target.value)}}
          />
          <Button
            className="api-endpoint-item-endpoint-btn"
            tooltip={t("sendRequest")}
            icon="pi pi-external-link"
            onClick={handleCustomApiRequestDetails}
          />
          </div>

          <Panel
            className="api-request-custom-request-body-container"
            toggleable
            collapsed
            header={t("customRequestBody")}
          >
            <InputTextarea
              className="api-request-custom-request-body-inputtextarea"
              value={customReqBody}
              placeholder={t("reqBodyPlaceholder")}
              style={{ resize: "none", minWidth: "600px", minHeight: "400px" }}
              onChange={(e)=>{
                setCustomReqBody(e.target.value)
              }}
            ></InputTextarea>

          </Panel>
        </div>
      )}
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ApiRequestsDetailsContainer>
  );
};

export default withTranslation()(ApiRequestDetails);
