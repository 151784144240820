import { apiosErionAuth, apiosErionTransports } from "../common/apiosErion";
import headerERION from "./auth-header-erion";
import config from "../../config";

class ErionService {
  getErionAPIToken() {
    return apiosErionAuth.post("", {
      grant_type: "client_credentials",
      client_id: config.erionApiClientId,
      client_secret: config.erionApiClientSecret,
      scope: config.erionApiScope,
      resource: config.erionApiResource,
      headers: headerERION(),
    });
  }

  getErionTransportData(token) {
    return apiosErionTransports.get("", {
      headers: headerERION(token),
    });
  }
}
export default new ErionService();
