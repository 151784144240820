import styled from "@emotion/styled";
export const ServiceEmbedContainer = styled.div`
  .service-title {
    font-size: 18px;
  }

  .endpoints-container {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;


    .api-endpoints-container{
    

      .custom-endpoint-container{
        padding-top:1rem;

        .custom-endpoint-add-btn{
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            max-width: 25%;
            background-color: #00a3ff;
            border-radius: 6px;
            border-style: solid;
            border-color: #00a3ff;
            border-width: thin;
            &:hover {
              background-color: #f72685;
              border-radius: 6px;
              border-style: solid;
              border-color: #f72685;
              border-width: thin;
            }
        }
        .custom-endpoint-info-btn{
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            max-width: 25%;
            background-color: #00a3ff;
            border-radius: 6px;
            border-style: solid;
            border-color: #00a3ff;
            border-width: thin;
            &:hover {
              background-color: rgb(249, 163, 101);
              border-radius: 6px;
              border-style: solid;
              border-color: rgb(249, 163, 101);
              border-width: thin;
            }
        }
      }
    }
  }

  .endpoint-btn {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    max-width: 25%;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }

  .parameter-submit-btn{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    max-width: 25%;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
    .api-endpoint-item{
      margin-bottom: 0.25rem;
    }

    .service-iframe{
      width: 800px;
      // width: 100%;
      height: 600px;


      @media (max-width: 768px) {
        width: 600px;
        height: 450px;
      }

      @media (max-width: 576px) {
        width: 360px;
        height: 270px;
      }
      @media (max-width: 384px) {
        width: 280px;
        height: 210px;
      }
    }
  
`;
export const ServiceEmbedDialogContainer = styled.div`
  .service-request-result-err-detail {
    // color: #00a3ff;
  }
  .service-request-result-err-detail-title {
    font-weight: bold;
    color: #00a3ff;
  }
  .service-request-result-response-data-container {
    background-color: rgba(214, 214, 214, 0.3);
    font-family: monospace;
    .service-request-result-response-data {
      font-size: 14px;
      font-family: Courier New;
    }
  }
  .previous-result-container {
    .service-request-result-response-data-container {
      background-color: rgba(214, 214, 214, 0.3);
      font-family: monospace;
      .service-request-result-response-data {
        font-size: 14px;
        font-family: Courier New;
      }
    }
  }

  .method-lca-check {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .endpoint-btn {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    max-width: 25%;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;
export const CustomEndpointDialogContainer = styled.div`
  .custom-endpoint-info-btn {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    max-width: 25%;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: rgb(249, 163, 101);
      border-radius: 6px;
      border-style: solid;
      border-color: rgb(249, 163, 101);
      border-width: thin;
    }
  }
`;

