import styled from "@emotion/styled";
export const ProductModelSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 0 1rem;

  .pm-selection-container {

    .pm-selection-text{
      width: 250px;

    }
    .pm-selection-dropdown{
      width: 250px;
    }
  }
  // .pm-data-container {
  // }

`;