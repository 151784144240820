import React, { useState, useEffect } from "react";
import { ErionServiceContainer } from "./assets/ErionServiceContainer.style";
import { Button } from "primereact/button";
import { withTranslation, Trans } from "react-i18next";
import ErionAPIService from "../functionalities/services/erion.service";
import { isCTAdmin } from "../functionalities/common/token-check";
import { Slide, ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import WaitDialog from "../components/WaitDialog";
const ErionService = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [rawTransportData, setRawTransportData] = useState({});
  const [transportData, setTransportData] = useState([]);
  const navigate = useNavigate();

  const useErionService = () => {
    setLoading(true);
    ErionAPIService.getErionAPIToken().then(
      (response) => {
        console.log(response);
        setLoading(false);
        if (
          (response.status =
            200 &&
            response?.data["access_token"] != undefined &&
            response?.data["access_token"] != null &&
            response?.data["access_token"] != "")
        ) {
          setLoading(true);
          ErionAPIService.getErionTransportData(
            response?.data["access_token"]
          ).then(
            (response) => {
              setLoading(false);
              console.log(response);
              setRawTransportData(response?.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response?.data &&
                  error.response?.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
              toast.error(error.response.status + " " + error.response?.data);
              setLoading(false);
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response?.data &&
            error.response?.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        toast.error(error.response.status + " " + error.response?.data);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (isCTAdmin()) {
      navigate("/landing");
    }
  }, []);
  useEffect(() => {
    setTransportData(rawTransportData["value"])
  },[rawTransportData])
  return (
    <ErionServiceContainer>
      <Button
        className="service-button"
        label={t("useErionService")}
        onClick={useErionService}
      />

      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ErionServiceContainer>
  );
};
export default withTranslation()(ErionService);
