import React, { useEffect, useState } from "react";
import { withTranslation, Trans } from "react-i18next";

const CodeTextArea = ({ t , value}) => {
  const [defValue,setDefValue] =  useState("")


  useEffect(()=>{
    setDefValue(value)

  },[value]);
  return (
    <textarea
      className="code-block-area"
      defaultValue={defValue}
      name=""
      id=""
      disabled
      rows={50}
      cols={65}
      style={{resize:"none",maxWidth:"365px", maxHeight:"400px"}}
    ></textarea>
    /** div -> pre -> code? */
  );
};

export default withTranslation()(CodeTextArea);
