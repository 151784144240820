import { TOKEN_USER } from "../common/token-check";


// değişebilir
export default function authHeader() {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (user && user.token) {
      return { ...headers, Authorization: "Bearer " + user.token };
    } else {
      return {};
    }
  }
