import axios from "axios";
import config from "../../config";
//const API_URL = process.env.REACT_APP_SERVER_ADDRESS+"api/";


const apiosMDC = axios.create({
  baseURL: config.cartifApiUrl+"/",
  timeout: 30000,
  timeoutErrorMessage: "Connection timeout. Please try again",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});
export default apiosMDC;
