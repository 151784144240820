

import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { tutorialData } from "../data/tutorial/tutorial-data";
import { TutorialContainer } from "./assets/TutorialContainer.style";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { div } from "three/webgpu";

const Tutorials = ({ t }) => {
  const [tData, setTData] = useState([]);
  const navigate = useNavigate();


    useEffect(()=>{
        setTData(tutorialData);
    },[]);
    // console.log(tData)
    return (
      <TutorialContainer>
        { tData.length > 0 ? (
          <div className="tutorial-cards-container">
            {tData.map((e) => e?.visible ? 
             <div className="tutorial-card">
             <img className="tutorial-cover-img" src={e?.thumbnailArray[0]} alt={e?.name +"-cover-img"}/>
             <div className="tutorial-card-title">{t(e?.title)}</div>
             {/* <div className="tutorial-card-shortD">{t(e?.shortDesc)}</div> */}

             <Button
               className="tutorial-cardbtn"
               id=""
               label={t("goto")}
               // icon = {authOpen ? "pi pi-chevron-down" : "pi pi-chevron-up"}
               onClick={() => {
                 navigate("/t/" + e?.urlPath);
               }}
             />
           </div>
             : <></>
            
            )}
          </div>
        ) : (
          <div>{t("noTutorialsFound")}</div>
        )}
      </TutorialContainer>
    );

}

export default withTranslation()(Tutorials);
