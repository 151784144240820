import apios from "../common/apios";

class PubService {
  /* Services methods */
  getRegisteredServices(){
    return apios.get( "pub/getRegisteredServices",{});
  }
  getRegisteredServiceById(sid){
    return apios.get("pub/getRegisteredServiceById", { 
      params: {
        id : sid
      },
      },
    );
  }
  searchProductModel(keyword,searchState){
    return apios.get("pub/searchProductModel", {
      params: {
        keyword : keyword,
        itemLevelCheck: searchState,
      },
      }
    );
  }
  getProductModelById(pid){
    return apios.get("pub/getProductModelById", { 
      params: {
        id : pid
      },
      }
    );
  }
  getProductModelByCode(code){
    return apios.get("pub/getProductModelByCode", { 
      params: {
        code : code
      },
      }
    );
  }
  getRegisteredServiceResultRecordsByPM(pmid) {
    return apios.get("pub/getRegisteredServiceResultRecordsByPM", {
      params: {
        pmid: pmid,
      },
    })
  }
}
export default new PubService();
